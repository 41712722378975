import { makeAutoObservable } from "mobx";
import _ from "lodash";

import {
  ASSIST_MARKER,
  CHECK_MARKER,
  DRAG_MARKER,
  EMPTY_POSITION,
  HINT_MARKER,
  KING,
  LAST_MOVE_FROM_MARKER,
  LAST_MOVE_TO_MARKER,
  SELECTED_MARKER,
  STARTING_POSITION,
  USER_MARKER,
  MISTAKE_MARKER
} from "../constants";
import { Chessboard } from "../utilities/chessboard";

// HACK: This class uses a _updateCounter property to recompute computed expressions. This allows
// the Chessboard object to be used without having to manually manage properties.
/* eslint-disable no-unused-expressions */

export class ChessboardStore {

  // Define an empty chessboard
  static EMPTY = new ChessboardStore({ statingPosition: EMPTY_POSITION })

  // Markers and selections
  arrows = []
  markers = []
  selectedSquare = null

  // Assistance properties
  hint = null
  assist = null
  mistake = null
  autoMove = false

  // Editing properties
  dragPlayer = null
  dragPiece = null
  dragSquare = null
  editingStartingPosition = false

  // Used to automatically update computed expressions.
  _updateCounter = 0;

  constructor({ startingPosition } = {}) {
    makeAutoObservable(this);
    this._chessboard = new Chessboard({ startingPosition });
  }

  /**
   * Returns the chessboard's history.
   */
  get history() {
    this._updateCounter;
    return this._chessboard.history;
  }

  /**
   * Returns the chessboard's FEN.
   */
  get fen() {
    this._updateCounter;
    return this._chessboard.fen;
  }

  /**
   * Returns the starting position.
   */
  get startingPosition() {
    this._updateCounter;
    return this._chessboard.startingPosition;
  }

  /**
   * Sets the starting position.
   */
  set startingPosition(startingPosition) {
    this._updateCounter++;
    this._chessboard.startingPosition = startingPosition;
  }

  /**
   * Returns the number of half moves that have been played from the starting position. Note that
   * the 0 index represents the starting state of the board, and all other indices represent the
   * moves after that.
   */
  get numberOfMoves() {
    this._updateCounter;
    return this.history.length;
  }

  /**
   * Returns the player that starts from the starting position.
   */
  get startingPlayer() {
    this._updateCounter;
    return this._chessboard.startingPlayer;
  }

  /**
   * Sets the starting player.
   */
  set startingPlayer(startingPlayer) {
    this._updateCounter++;
    this._chessboard.startingPlayer = startingPlayer;
  }

  /**
   * Returns true if the starting player is white.
   */
  get isStartingPlayerWhite() {
    this._updateCounter;
    return this._chessboard.isStartingPlayerWhite;
  }

  /**
   * Returns true if the starting player is black.
   */
  get isStartingPlayerBlack() {
    this._updateCounter;
    return this._chessboard.isStartingPlayerBlack;
  }

  /**
   * Returns the current player.
   */
  get currentPlayer() {
    this._updateCounter;
    return this._chessboard.currentPlayer;
  }

  /**
   * Returns true if it's the first player's turn.
   */
  get isFirstPlayerTurn() {
    this._updateCounter;
    return this.history.length % 2 === 0;
  }

  /**
   * Returns true if it's the second player's turn.
   */
  get isSecondPlayerTurn() {
    this._updateCounter;
    return !this.isFirstPlayerTurn;
  }

  /**
   * Returns true if white can castle kingside.
   */
  get whiteCanCastleKingside() {
    this._updateCounter;
    return this._chessboard.whiteCanCastleKingside;
  }

  /**
   * Sets whether white can castle kingside.
   */
  set whiteCanCastleKingside(canCastle) {
    this._updateCounter++;
    this._chessboard.whiteCanCastleKingside = canCastle;
  }

  /**
   * Returns true if white can castle queenside.
   */
  get whiteCanCastleQueenside() {
    this._updateCounter;
    return this._chessboard.whiteCanCastleQueenside;
  }

  /**
   * Sets whether white can castle queenside.
   */
  set whiteCanCastleQueenside(canCastle) {
    this._updateCounter++;
    this._chessboard.whiteCanCastleQueenside = canCastle;
  }

  /**
   * Returns true if white can castle kingside.
   */
  get blackCanCastleKingside() {
    this._updateCounter;
    return this._chessboard.blackCanCastleKingside;
  }

  /**
   * Sets whether black can castle kingside.
   */
  set blackCanCastleKingside(canCastle) {
    this._updateCounter++;
    this._chessboard.blackCanCastleKingside = canCastle;
  }

  /**
   * Returns true if black can castle queenside.
   */
  get blackCanCastleQueenside() {
    this._updateCounter;
    return this._chessboard.blackCanCastleQueenside;
  }

  /**
   * Sets whether black can castle queenside.
   */
  set blackCanCastleQueenside(canCastle) {
    this._updateCounter++;
    this._chessboard.blackCanCastleQueenside = canCastle;
  }

  /**
   * Returns the last move that was played, or undefined if there was no last move.
   */
  get lastMove() {
    this._updateCounter;
    return _.last(this.history);
  }

  /**
   * Returns all of the pieces on the board.
   */
  get pieces() {
    this._updateCounter;
    return this._chessboard.pieces;
  }

  /**
   * Returns an array of the legal moves for the currently selected square.
   */
  get selectedSquareLegalMoves() {
    this._updateCounter;
    return this._chessboard.legalMoves(this.selectedSquare);
  }

  /**
   * Returns true if the last move was a capture.
   */
  get isCapture() {
    this._updateCounter;
    return this._chessboard.isCapture;
  }

  /**
   * Returns true if the position is a check.
   */
  get isCheck() {
    this._updateCounter;
    return this._chessboard.isCheck;
  }

  /**
   * Returns true if the position is a checkmate.
   */
  get isCheckmate() {
    this._updateCounter;
    return this._chessboard.isCheckmate;
  }

  /**
   * Returns the square for the king currently in check.
   */
  get checkedKingSquare() {
    if (!this._chessboard.isCheck) {
      return null;
    }

    return _.find(this.pieces, ({ piece, color }) => {
      return piece === KING && color === this.currentPlayer;
    })?.square;
  }

  /**
   * Returns verbose markers for both user and non-user markers.
   */
  get verboseMarkers() {

    // TODO: Replace this with the functional pipeline operator.
    let filterMarkers = _.flow(

      // Filter out any markers without a square
      markers => markers.filter(marker => !_.isNil(marker.square)),

      // Only allow one marker per square.
      markers => _.uniqBy(markers, "square")
    );

    let verboseMarkers = filterMarkers([
      { square: this.selectedSquare, type: SELECTED_MARKER },
      { square: this.mistake?.from, type: MISTAKE_MARKER },
      { square: this.mistake?.to, type: MISTAKE_MARKER },
      { square: this.assist?.from, type: ASSIST_MARKER },
      { square: this.assist?.to, type: ASSIST_MARKER },
      { square: this.hint?.from, type: HINT_MARKER },
      ...this.markers.map(square => ({ square, type: USER_MARKER })),
      { square: this.lastMove?.from, type: LAST_MOVE_FROM_MARKER },
      { square: this.lastMove?.to, type: LAST_MOVE_TO_MARKER }
    ]);

    // If the king is in check, always included the check marker over the others, regardless of
    // whether there's another marker below it.
    if (this._chessboard.isCheck) {
      verboseMarkers.push({ square: this.checkedKingSquare, type: CHECK_MARKER });
    }

    // If we're dragging over a square, always included the drag over, regardless of
    // whether there's another marker below it.
    if (this.dragSquare) {
      verboseMarkers.push({ square: this.dragSquare, type: DRAG_MARKER });
    }

    return verboseMarkers;
  }

  /**
   * Makes a move.
   * @param move The move to make. This can either a string in algebraic notation, or it can be an
   * object with `from` and `to` properties.
   * @param autoMove Determines if the move was an auto move or not.
   * @returns Returns true if the move was successfully made.
   */
  move(move, autoMove = false) {

    // Ensure the chessboard properties update.
    this._updateCounter++;

    // Make the move. We have to attempt to make the move, because that's how we determine if it is
    // valid. If the move was not legal, ignore it.
    if (!this._chessboard.move(move)) {
      return false;
    }

    // Update the properties, markers and indicators.
    this.clear();
    this.autoMove = autoMove;

    // Indicate the move was successful
    return true;
  }

  /**
   * Clears out all indicators and markers.
   */
  clear() {
    this.hint = null;
    this.assist = null;
    this.mistake = null;
    this.autoMove = false;

    this.clearMarkers();
    this.clearArrows();
  }

  /**
   * Rolls back the last move.
   */
  undo() {
    this._updateCounter++;
    this._chessboard.undo();
    this.clear();
    this.autoMove = true;
  }

  /**
   * Resets the board to its starting position.
   */
  reset() {
    this._updateCounter++;
    this._chessboard.reset();
    this.clear();
  }

  /**
   * Returns true if the chessboard has the given arrow.
   */
  hasArrow(from, to) {
    return !_.isNil(_.find(this.arrows, { from, to }));
  }

  /**
   * Adds an arrow to the chessboard.
   */
  addArrow(from, to) {
    if (this.hasArrow(from, to)) {
      return;
    }

    this.arrows.push({ from, to });
  }

  /**
   * Removes an arrow from the chessboard.
   */
  removeArrow(from, to) {
    this.arrows = _.reject(this.arrows, { from, to });
  }

  /**
   * Adds an arrow to the chessboard if one doesn't already exist, and removes an arrow if one does.
   */
  toggleArrow(...parameters) {
    this.hasArrow(...parameters)
      ? this.removeArrow(...parameters)
      : this.addArrow(...parameters);
  }

  /**
   * Clears all of the arrows.
   */
  clearArrows() {
    this.arrows = [];
  }

  /**
   * Returns true if the chessboard has a marker on the given square.
   */
  hasMarker(square) {
    return !_.isNil(_.find(this.markers, marker => marker === square));
  }

  /**
   * Adds a marker to the chessboard.
   */
  addMarker(square) {
    if (this.hasMarker(square)) {
      return;
    }

    this.markers.push(square);
  }

  /**
   * Removes a marker from the chessboard.
   */
  removeMarker(square) {
    this.markers = _.reject(this.markers, marker => marker === square);
  }

  /**
   * Adds a marker to the chessboard if one doesn't already exist, and removes a marker if one does.
   */
  toggleMarker(...parameters) {
    this.hasMarker(...parameters)
      ? this.removeMarker(...parameters)
      : this.addMarker(...parameters);
  }

  /**
   * Clears all of the markers.
   */
  clearMarkers() {
    this.markers = [];
  }

  /**
   * Copies the current fen of the chessboard to the system clipboard.
   */
  copyFen() {
    navigator.clipboard.writeText(this.fen);
  }

  /**
   * Resets the chessboard to the empty position.
   */
  resetToEmptyPosition() {
    this.reset();
    this.startingPosition = EMPTY_POSITION;
  }

  /**
   * Resets the chessboard to the starting position.
   */
  resetToStartingPosition() {
    this.reset();
    this.startingPosition = STARTING_POSITION;
  }

  /**
   * Adds a piece to the board's starting position.
   */
  addPieceToStartingPosition(square, player, piece) {
    this._updateCounter++;
    this._chessboard.addPieceToStartingPosition(square, player, piece);
  }

  /**
   * Removes a piece from the board's starting position.
   */
  removePieceFromStartingPosition(square) {
    this._updateCounter++;
    this._chessboard.removePieceFromStartingPosition(square);
  }

  /**
   * Moves a piece in the starting position.
   */
  movePieceInStartingPosition(from, to) {
    this._updateCounter++;
    this._chessboard.movePieceInStartingPosition(from, to);
  }

  /**
   * Returns true if the chessboard has the given piece.
   */
  hasPiece(player, piece) {
    return this._chessboard.hasPiece(player, piece);
  }
}
