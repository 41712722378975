import "react-universal-hooks";

import { observer } from "mobx-react";
import React from "react";
import _ from "lodash";
import classNames from "classnames";

import { CONTROLS_VIEW_MAP, ControlsLayout } from "./controls-layout";
import { Dialog } from "../shared/dialog";
import { LEGAL_ROUTES, LOG_IN_ROUTE } from "../../stores/routes";
import { LegalPage } from "../pages/legal-page";
import { Main } from "./main";
import { PrimaryNavigation } from "./primary-navigation";
import { SECONDARY_NAVIGATION_VIEW_MAP, SecondaryNavigation } from "./secondary-navigation";
import { SIDEBAR_CONTENT_VIEW_MAP } from "./sidebar-content";
import { Sidebar } from "./sidebar";
import { StoreContext } from "../../stores/store-provider";
import {
  isMobileLessonCreationPageDisplayed,
  useLessThanDesktopMediaQuery
} from "../../hooks/media-queries";
import { UnauthorizedError } from "../../utilities/errors";

// Normally, you can't use hooks in React class components. However, this project uses
// react-universal-hooks, so it's not an issue.
/* eslint-disable react-hooks/rules-of-hooks */

function hasPrimaryNavigation(errorStore, routeName) {
  return errorStore.hasError || !LEGAL_ROUTES.includes(errorStore, routeName);
}

function hasSidebar(errorStore, routeName) {

  // HACK: This is a quick way of determining if the mobile editing page is being displayed so the
  // other components can be shown or hidden.
  return !errorStore.hasError
    && !isMobileLessonCreationPageDisplayed(routeName)
    && _.has(SIDEBAR_CONTENT_VIEW_MAP, routeName);
}

function hasSecondaryNavigation(errorStore, routeName) {
  return !errorStore.hasError
    && !isMobileLessonCreationPageDisplayed(routeName)
    && _.has(SECONDARY_NAVIGATION_VIEW_MAP, routeName);
}

function hasControls(errorStore, routeName) {
  return !errorStore.hasError
    && !isMobileLessonCreationPageDisplayed(routeName)
    && _.has(CONTROLS_VIEW_MAP, routeName);
}

/**
 * This component houses the main application content. Each of the components inside of it are
 * reactive, managing their state via the routerStore. This component acts as an error boundary, and
 * forces a re-render on errors.
 *
 * NOTE: This component is odd in that its root element is contained in the index.html file. This is
 * to prevent unnecessary DOM elements in the application.
 */
export const Application = observer(class Application extends React.Component {
  static contextType = StoreContext
  state = { errorCount: 0 }

  componentDidCatch(error, { componentStack }) {
    this.triggerError(error, componentStack);
  }

  componentDidMount() {
    window.addEventListener("unhandledrejection", this.handlePromiseRejection);
  }

  componentWillUnmount() {
    window.removeEventListener("unhandledrejection", this.handlePromiseRejection);
  }

  handlePromiseRejection = (event) => {
    this.triggerError(event.reason);
  }

  triggerError(error, componentStack) {
    let { error: errorStore, router: router } = this.context;

    // If the error is an UnauthorizedError, redirect to the log in page.
    if (error instanceof UnauthorizedError) {
      router.goTo(LOG_IN_ROUTE, { queryParams: { redirectPath: window.location.pathname } });
      return;
    }

    // Store the error in the error store
    errorStore.triggerError(error, componentStack);

    // Set the state to force a re-render.
    this.setState(({ errorCount }) => ({ errorCount: errorCount + 1 }));
  }

  render() {
    let { router, error: errorStore } = this.context;

    // The router is used here instead of the router params so the application properly handles the
    // not found route.
    let routeName = router?.routerState?.routeName;

    // HACK: Use the desktop media query hook to force the component to rerender when the media
    // query changes.
    useLessThanDesktopMediaQuery();

    // HACK: Right now, this is a quick and easy way to display the legal text. This text should
    // really be included in the main application context, but that would require rewriting the
    // application styles to handle the primary navigation being hidden. This is an easier solution
    // for now.
    if (LEGAL_ROUTES.includes(routeName)) {
      return <LegalPage />;
    }

    return <main
      className={
        classNames(
          "application",
          {
            "application--has-primary-navigation": hasPrimaryNavigation(errorStore, routeName),
            "application--has-secondary-navigation": hasSecondaryNavigation(errorStore, routeName),
            "application--has-sidebar": hasSidebar(errorStore, routeName),
            "application--has-controls": hasControls(errorStore, routeName)
          }
        )
      }
    >
      <PrimaryNavigation className="application__primary-navigation" />
      <SecondaryNavigation className="application__secondary-navigation" />
      <Main className="application__main" />
      <ControlsLayout className="application__controls" />
      <Sidebar className="application__sidebar" />
      <Dialog className="application__dialog" />
    </main>;
  }
});
