import { observer } from "mobx-react";
import InfiniteScroll from "react-infinite-scroll-component";
import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";

import { LessonListItem } from "./lesson-list-item";
import { LessonStore } from "../../stores/models/lesson-store";
import { useStore } from "../../stores/store-provider";

// TODO: Add a loading state? For now, the lessons load fast enough it doesn't seem necessary.
function Loading() {
  return null;
}

export const LessonList = observer(({ lessons, className }) => {
  let routerParams = useStore("routerParams");

  return <ul className={ classNames(className, "lesson-list") }>
    <InfiniteScroll
      dataLength={ lessons.length }
      next={ () => routerParams.fetchNextLessonsPage() }
      hasMore={ !routerParams.lastPageEmpty }
      loader={ <Loading /> }
      scrollableTarget="main"
    >
      { lessons.map(lesson => <LessonListItem key={ lesson.id } lesson={ lesson } />) }
    </InfiniteScroll>
  </ul>;
});

LessonList.displayName = "Lesson";

LessonList.propTypes = {
  lessons: PropTypes.arrayOf(PropTypes.instanceOf(LessonStore)).isRequired,
  className: PropTypes.string
};
