import { action } from "mobx";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";

import { Button } from "../shared/button";
import { Controls } from "./controls";
import { useStore } from "../../stores/store-provider";

export const EditExerciseControls = observer(({ className }) => {
  let { exercise, lesson } = useStore("routerParams");

  if (_.isNil(exercise)) {
    return null;
  }

  async function cloneExercise() {

    // Open an empty window here. This must be done directly as a result of a user action, rather
    // than later after the clone result is returned from the server.
    let cloneWindow = window.open("");

    // Clone that exercise
    let clonedExercise = await lesson.cloneExercise(exercise.id);

    // Display the exercise
    let baseUrl = window.location.origin;
    let { lessonId, id: exerciseId } = clonedExercise;
    cloneWindow.location = `${ baseUrl }/lessons/${ lessonId }/exercises/${ exerciseId }/edit`;
  }

  // TODO: For some reason the default props aren't working. It shouldn't be necessary to explicitly
  // pass these values.
  return <Controls
    onLeftArrowClicked={ () => exercise.goToPreviousMove() }
    leftArrowDisabled={ !exercise.hasPreviousMove || exercise.chessboard.editingStartingPosition }
    onRightArrowClicked={ () => exercise.goToNextMove() }
    rightArrowDisabled={ !exercise.hasNextMove || exercise.chessboard.editingStartingPosition }
    className={ className }
  >
    <Button
      icon="copy"
      text="Copy FEN"
      onClick={ () => exercise.chessboard.copyFen() }
      treatment="clear"
    />
    { /* TODO: Reenable the clone button and fix the production bug surrounding it. */ }
  </Controls>;
});

EditExerciseControls.displayName = "EditExerciseControls";

EditExerciseControls.propTypes = {
  className: PropTypes.string
};
