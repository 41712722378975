import _ from "lodash";

/**
 * This method is just like Object.assign, except it only assigns values if they've changed. This
 * can prevent side effects for MobX getters and setters.
 */
export function assignIfChanged(target, source) {
  if (!_.isObject(target) || !_.isObject(source)) {
    throw new Error("The target and source must be objects!");
  }

  Object.assign(target, _.omitBy(source, (value, key) => _.isEqual(target[key], value)));
}
