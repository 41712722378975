import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";
import classNames from "classnames";
import { action } from "mobx";
import { observer } from "mobx-react";

import { SimpleDroppable } from "../shared/simple-droppable";
import { ExerciseStore } from "../../stores/models/exercise-store";
import { ExerciseMoveRow } from "./exercise-move-row";
import { AutoMoveBar } from "./auto-move-bar";

function rowProps(exercise) {

  // Extend the moves array to always include an extra empty move. If the starting player is black,
  // shift the chunks by one element. If the last move is a checkmate, don't display an additional
  // empty input.
  let moves = [
    ...exercise.chessboard.isStartingPlayerWhite ? [] : [ null ],
    ...exercise.moves,
    ..._.last(exercise.verboseMoves)?.checkmate ? [] : [ "" ]
  ];

  // Chunk all of the moves, retaining their original indices.
  let indexOffset = exercise.chessboard.isStartingPlayerWhite ? 0 : -1;

  // Create an array of props for each move row.
  // TODO: Replace this with the smart pipeline operator.
  let moveRowProps = _.flow(
    moveRow => moveRow.map((move, index) => ({ move, index: index + indexOffset })),
    moveRow => _.chunk(moveRow, 2),
    moveRow => moveRow.map(([ whiteMove, blackMove ], rowIndex) => ({
      whiteMove: whiteMove?.move,
      whiteMoveIndex: whiteMove?.index ?? -1,
      blackMove: blackMove?.move,
      blackMoveIndex: blackMove?.index ?? -1,
      exercise,
      id: `exerciseMoveRow-${ rowIndex }`,
      ComponentFunction: ExerciseMoveRow
    }))
  )(moves);

  // Add the AutoMoveBar to the move row props.
  moveRowProps.splice(
    exercise.autoMoveRowIndex,
    0,
    {
      id: `autoMoveBar`,
      ComponentFunction: AutoMoveBar
    }
  );

  // Return the computed props.
  return moveRowProps;
}

export const ExerciseMoves = observer(({ exercise, className }) => {

  const handleDragEnd = action(({ destination }) => {

    // Ignore cancellations
    if (_.isNil(destination)) {
      return;
    }

    // TODO: Create a more nuanced calculation of the autoMoveIndex
    exercise.autoMoveRowIndex = destination.index;
  });

  // Create the child components
  let rows = rowProps(exercise).map(({ ComponentFunction, id, ...props }, index) => {
    return <ComponentFunction index={ index } key={ id } id={ id } { ...props } />;
  });

  return <SimpleDroppable
    className={ classNames("exercise-moves", className) }
    onDragEnd={ handleDragEnd }
  >
    { rows }
  </SimpleDroppable>;
});

ExerciseMoves.displayName = "ExerciseMoves";

ExerciseMoves.propTypes = {
  exercise: PropTypes.instanceOf(ExerciseStore),
  className: PropTypes.string
};
