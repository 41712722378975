import { Animate } from "react-move";
import { arc } from "d3-shape";
import { easeExpOut } from "d3-ease";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";
import classNames from "classnames";

import { LessonStore } from "../../stores/models/lesson-store";
import { STATES, TRANSITION_TIME_MEDIUM, UNSOLVED_STATE, statePropType } from "../../constants";
import { useLessThanDesktopMediaQuery } from "../../hooks/media-queries";

const VIEW_BOX_SIZE = 64;
const STROKE_WIDTH = 8;
const THICK_STROKE_WIDTH = 10;

const generateArcOuterPath = arc().outerRadius(VIEW_BOX_SIZE / 2);

function generateArcPath({ isLessThanDesktop, ...parameters }) {
  let innerRadius = VIEW_BOX_SIZE / 2 - (isLessThanDesktop ? THICK_STROKE_WIDTH : STROKE_WIDTH);
  return generateArcOuterPath({ innerRadius, ...parameters });
}

// I followed this demo to generate the Arc path:
// https://react-move.js.org/#/demos/charts
function Arc({ state, startAngle, endAngle }) {
  let isLessThanDesktop = useLessThanDesktopMediaQuery();

  let update = () => {
    return {
      startAngle: [ startAngle ],
      endAngle: [ endAngle ],
      timing: { duration: TRANSITION_TIME_MEDIUM, ease: easeExpOut }
    };
  };

  return <Animate
    start={ () => ({ startAngle, endAngle }) }
    enter={ update }
    update={ update }
  >
    { ({ startAngle: interpolatedStartAngle, endAngle: interpolatedEndAngle }) => {
      return <path
        d={
          generateArcPath({
            startAngle: interpolatedStartAngle,
            endAngle: interpolatedEndAngle,
            isLessThanDesktop
          })
        }
        transform={ `translate(${ VIEW_BOX_SIZE / 2 }, ${ VIEW_BOX_SIZE / 2 })` }
        data-state={ state }
        className="donut__circle"
      />;
    } }
  </Animate>;
}

Arc.propTypes = {
  startAngle: PropTypes.number.isRequired,
  endAngle: PropTypes.number.isRequired,
  state: statePropType.isRequired
};

const Arcs = observer(({ lesson }) => {

  if (!lesson.hasExercises) {
    return <Arc state={ UNSOLVED_STATE } startAngle={ 0 } endAngle={ Math.PI * 2 } />;
  }

  let scoreCounts = lesson.scoreCounts;

  let arcValues = STATES.reduce((accumulator, state) => {
    let startAngle = _.get(_.last(accumulator), 2, 0);
    let endAngle = startAngle + scoreCounts[state] / lesson.exercises.length * Math.PI * 2;

    accumulator.push([ state, startAngle, endAngle ]);

    return accumulator;
  }, []).reverse();

  return arcValues.map(([ state, startAngle, endAngle ]) => {
    return <Arc
      key={ state }
      state={ state }
      startAngle={ startAngle }
      endAngle={ endAngle }
    />;
  });
});

Arcs.displayName = "Arcs";

Arcs.propTypes = {
  lesson: PropTypes.instanceOf(LessonStore).isRequired
};

// The technique for drawing the donut arcs was taken from here:
// https://css-tricks.com/svg-line-animation-works/
export const Donut = observer(({ className, lesson }) => {

  return <svg
    className={ classNames("donut", className) }
    viewBox={ `0 0 ${ VIEW_BOX_SIZE } ${ VIEW_BOX_SIZE }` }
  >
    <Arcs lesson={ lesson } />
  </svg>;
});

Donut.displayName = "Donut";

Donut.propTypes = {
  className: PropTypes.string,
  lesson: PropTypes.instanceOf(LessonStore).isRequired
};
