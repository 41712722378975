import { makeAutoObservable, runInAction } from "mobx";
import _ from "lodash";
import { localStorage } from "../utilities/local-storage";

import { UserStore } from "./models/user-store";

// TODO: I don't know how MobX will handle the properties fetching state from LocalStore. Will it
// still cache the MobX properties? Is this code problematic?
export class SessionStore {
  user = null;

  constructor() {
    makeAutoObservable(this, {
      accessToken: false,
      renewalToken: false
    });
  }

  /**
   * Returns the current access token.
   */
  get accessToken() {
    return localStorage.getItem("accessToken");
  }

  /**
   * Returns the current renewalToken.
   */
  get renewalToken() {
    return localStorage.getItem("renewalToken");
  }

  /**
   * Returns whether a user is logged into the current session.
   */
  get isLoggedIn() {
    return !_.isNil(this.accessToken);
  }

  /**
   * Loads the current user, if necessary.
   */
  async load() {
    if (!this.isLoggedIn || !_.isNil(this.user)) {
      return;
    }

    let user = await UserStore.fetch();
    runInAction(() => this.user = user);
  }

  /**
   * Logs out the user.
   */
  logOut() {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("renewalToken");
    this.user = null;
  }
}
