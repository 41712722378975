import { Helmet } from "react-helmet-async";
import { RouterLink } from "mobx-state-router";
import { isBlank } from "voca";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import React, { useState } from "react";
import _ from "lodash";
import classNames from "classnames";
import { Button } from "../shared/button";

import {
  CREATED_LESSONS_ROUTE,
  EDIT_EXERCISE_ROUTE,
  EDIT_LESSON_ROUTE,
  LIKED_LESSONS_ROUTE,
  LESSONS_HISTORY_ROUTE,
  POPULAR_LESSONS_ROUTE,
  NEW_EXERCISE_ROUTE,
  NEW_LESSON_ROUTE,
  PRACTICE_LESSON_ROUTE,
  ROOT_ROUTE,
  WELCOME_ROUTE
} from "../../stores/routes";
import { MenuOpenButton } from "../shared/menu-open-button";
import { RouterView } from "../shared/router-view";
import { Spoiler } from "../shared/spoiler";
import { useStore } from "../../stores/store-provider";
import { ExerciseStore } from "../../stores/models/exercise-store";

function LandingPageContent() {
  return <h1 className="secondary-navigation__title">
    Welcome to Chessercise!
  </h1>;
}

function TitleWithButtonContent({ title }) {
  return <>
    <h1 className="secondary-navigation__title">
      { title }
    </h1>
    <Button
      className="secondary-navigation__button"
      treatment="primary"
      icon="plus"
      routeName={ NEW_LESSON_ROUTE }
      text="Create Lesson"
    />
  </>;
}

TitleWithButtonContent.propTypes = {
  title: PropTypes.string.isRequired
};

const WelcomePageContent = observer(() => {
  let { user } = useStore("session");

  if (_.isNil(user)) {
    return null;
  }

  return <TitleWithButtonContent title={ `Welcome Back ${ user.username }!` } />;
});

WelcomePageContent.displayName = "WelcomePageContent";

function PopularLessonsContent() {
  return <h1 className="secondary-navigation__title">
    Popular Lessons
  </h1>;
}

function LessonsHistoryContent() {
  return <h1 className="secondary-navigation__title">
    Lessons History
  </h1>;
}

function LikedLessonsContext() {
  return <h1 className="secondary-navigation__title">
    Liked Lessons
  </h1>;
}

function CreatedLessonsContent() {
  return <h1 className="secondary-navigation__title">
    Created Lessons
  </h1>;
}

function intersperseNavigationItems(items) {
  items = _.compact(items);

  return items.reduce((accumulator, item, index) => {

    if (index !== 0) {
      accumulator.push(
        <span className="secondary-navigation__separator" key={ `${ item.key }-separator` }>/</span>
      );
    }

    accumulator.push(item);

    return accumulator;
  }, []);
}

const LessonContent = observer(() => {
  let { lesson } = useStore("routerParams");

  if (_.isNil(lesson)) {
    return null;
  }

  let title = lesson.currentExercise?.title || lesson.title || "New Lesson";

  return <>
    <Helmet>
      <title>Chessercise – { title }</title>
      <meta name="description" content={ lesson.description } />
    </Helmet>

    <h1 className="secondary-navigation__title">
      {
        lesson.isSaved
          ? <RouterLink
            className="secondary-navigation__title-link"
            routeName={ PRACTICE_LESSON_ROUTE }
            params={ { lessonId: lesson.id } }
          >
            { title }
          </RouterLink>
          : title
      }

    </h1>

    {
      intersperseNavigationItems([
        isBlank(lesson.sourceName) ? null : <a
          key="lesson-source"
          className="secondary-navigation__link"
          href={ lesson.sourceUrl }
          target="_blank"
          rel="noreferrer noopener"
        >
          { lesson.sourceName }
        </a>
      ])
    }
  </>;
});

LessonContent.displayName = "LessonContent";

const ExerciseContent = observer(({ exercise, edit }) => {
  let { lesson } = useStore("routerParams");

  if (_.isNil(lesson)) {
    return null;
  }

  let description = exercise?.description ?? lesson.description;
  let title = exercise?.title ?? lesson.title ?? "New Exercise";

  return <>
    <Helmet>
      <title>Chessercise – { title }</title>
      <meta name="description" content={ description } />
    </Helmet>
    <h1 className="secondary-navigation__title">
      <Spoiler enabled={ lesson.infoHidden }>
        { title }
      </Spoiler>
    </h1>

    {
      intersperseNavigationItems([
        isBlank(lesson.title) ? null : <RouterLink
          key="show-lesson"
          className="secondary-navigation__link"
          routeName={ edit ? EDIT_LESSON_ROUTE : PRACTICE_LESSON_ROUTE }
          params={ { lessonId: lesson.id } }
        >
          { lesson.title }
        </RouterLink>,
        isBlank(lesson.sourceName) ? null : <a
          key="lesson-source"
          className="secondary-navigation__link"
          href={ lesson.sourceUrl }
          target="_blank"
          rel="noreferrer noopener"
        >
          { lesson.sourceName }
        </a>
      ])
    }
  </>;
});

ExerciseContent.propTypes = {
  exercise: PropTypes.instanceOf(ExerciseStore),
  edit: PropTypes.bool
};

ExerciseContent.defaultProps = {
  edit: false
};

ExerciseContent.displayName = "EditExerciseContent";

const EditExerciseContent = observer(() => {
  let { exercise } = useStore("routerParams");

  if (_.isNil(exercise)) {
    return null;
  }

  return <ExerciseContent exercise={ exercise } edit />;
});

EditExerciseContent.displayName = "EditExerciseContent";

const PracticeLessonContent = observer(() => {
  let { lesson } = useStore("routerParams");

  if (_.isNil(lesson)) {
    return null;
  }

  return <ExerciseContent exercise={ lesson.currentExercise } />;
});

PracticeLessonContent.displayName = "PracticeLessonContent";

export const SECONDARY_NAVIGATION_VIEW_MAP = {
  [POPULAR_LESSONS_ROUTE]: PopularLessonsContent,
  [LESSONS_HISTORY_ROUTE]: LessonsHistoryContent,
  [CREATED_LESSONS_ROUTE]: CreatedLessonsContent,
  [LIKED_LESSONS_ROUTE]: LikedLessonsContext,
  [PRACTICE_LESSON_ROUTE]: PracticeLessonContent,
  [NEW_LESSON_ROUTE]: LessonContent,
  [EDIT_LESSON_ROUTE]: LessonContent,
  [NEW_EXERCISE_ROUTE]: EditExerciseContent,
  [EDIT_EXERCISE_ROUTE]: EditExerciseContent,
  [ROOT_ROUTE]: LandingPageContent,
  [WELCOME_ROUTE]: WelcomePageContent
};

// HACK: It would be nice if there were a way of doing this without having to manually specify the
// number of items.
export const NUMBER_OF_ITEMS = {
  [PRACTICE_LESSON_ROUTE]: 3,
  [NEW_LESSON_ROUTE]: 2,
  [EDIT_LESSON_ROUTE]: 2,
  [NEW_EXERCISE_ROUTE]: 3,
  [EDIT_EXERCISE_ROUTE]: 3
};

export const SecondaryNavigation = observer(({ className }) => {
  let { routeName } = useStore("routerParams");
  let { hasError } = useStore("error");
  let [ open, setOpen ] = useState(false);

  function toggleOpen() {
    setOpen((oldOpen) => !oldOpen);
  }

  if (hasError) {
    return null;
  }

  return <div
    className={
      classNames(
        className,
        "secondary-navigation",
        { "secondary-navigation--open": open }
      )
    }
    data-number-of-items={ NUMBER_OF_ITEMS[routeName] || 1 }
  >
    <MenuOpenButton
      className="secondary-navigation__open-button"
      onClick={ toggleOpen }
      type="info"
    />
    <RouterView viewMap={ SECONDARY_NAVIGATION_VIEW_MAP } />
  </div>;
});

SecondaryNavigation.displayName = "SecondaryNavigation";

SecondaryNavigation.propTypes = {
  className: PropTypes.string
};
