import { observer } from "mobx-react";
import PropTypes from "prop-types";
import React, { useState } from "react";
import classNames from "classnames";

import { DesktopMediaQuery } from "../shared/media-queries";
import { MenuOpenButton } from "../shared/menu-open-button";
import { SidebarContent } from "./sidebar-content";
import { SidebarControls } from "./sidebar-controls";
import { SidebarTitle } from "./sidebar-title";
import { useStore } from "../../stores/store-provider";

export const Sidebar = observer(({ className }) => {
  let { hasError } = useStore("error");
  let [ open, setOpen ] = useState(false);

  function toggleOpen() {
    setOpen((oldOpen) => !oldOpen);
  }

  if (hasError) {
    return null;
  }

  return <div
    className={ classNames("sidebar", className, { "application__sidebar--open": open }) }
  >
    <MenuOpenButton
      className="sidebar__open-button"
      onClick={ toggleOpen }
      direction={ open ? "down" : "up" }
      type="caret"
    />

    <DesktopMediaQuery>
      <SidebarTitle className="sidebar__title" />
    </DesktopMediaQuery>

    <div className="sidebar__content-container">
      <SidebarContent className="sidebar__content" />
    </div>

    <SidebarControls className="sidebar__controls" />
  </div>;
});

Sidebar.displayName = "Sidebar";

Sidebar.propTypes = {
  className: PropTypes.string
};
