import { Area, AreaChart, ResponsiveContainer, Tooltip } from "recharts";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";
import classNames from "classnames";

import {
  ASSIST_STATE,
  HINT_STATE,
  PERFECT_STATE,
  MISTAKE_STATE,
  UNSOLVED_STATE
} from "../../constants";
import { ScoreSummaryStore } from "../../stores/models/score-summary-store";

const SUMMARY_STATES = [
  MISTAKE_STATE,
  ASSIST_STATE,
  HINT_STATE,
  PERFECT_STATE,
  UNSOLVED_STATE
];

const PLACEHOLDER_DATA = [ 3, 1, 4, 2, 5, 3, 6 ];

const ANIMATION_DURATION = 500;

export const ScoreSummaryChart = observer(({ className, scoreSummaries, onHover, onUnhover }) => {

  // HACK: Here I'm using the content to render an active dot to instead trigger the active dot
  // callback.
  function handleContent({ payload }) {
    payload = _.first(_.map(payload, "payload"));

    if (!_.isNil(payload)) {

      // BUG FIX: This action needs to be asynchronous to allow the render cycle to complete before
      // firing the callback.
      setTimeout(() => onHover(payload));
    }

    return null;
  }

  // Determines if there is any data.
  let hasData = !_.every(scoreSummaries, "isEmpty");

  // Add an index to the score summaries.
  scoreSummaries = hasData
    ? _.map(scoreSummaries, (scoreSummary, index) => ({
      [PERFECT_STATE]: scoreSummary.numberOfPerfects,
      [HINT_STATE]: scoreSummary.numberOfHints,
      [ASSIST_STATE]: scoreSummary.numberOfAssists,
      [MISTAKE_STATE]: scoreSummary.numberOfMistakes,
      index
    }))
    : PLACEHOLDER_DATA.map((value, index) => ({ [UNSOLVED_STATE]: value, index }));

  return <div className={ classNames("score-summary-chart", className) }>
    <ResponsiveContainer>
      <AreaChart
        data={ scoreSummaries }
        margin={ { top: 0, right: 0, bottom: 0, left: 0 } }
        onMouseLeave={ onUnhover }
      >
        {
          SUMMARY_STATES.map(state => {
            return <Area
              key={ state }
              className={ `score-summary-chart__area score-summary-chart__area--${ state }` }
              type="monotone"
              dataKey={ state }
              stackId="scoreSummaries"
              stroke="none"
              activeDot={ () => false }
              animationDuration={ ANIMATION_DURATION }
            />;
          })
        }
        <Tooltip content={ handleContent } />
      </AreaChart>
    </ResponsiveContainer>

    {
      hasData
        ? null
        : <p className="score-summary-chart__no-data">Practice exercises to build your stats!</p>
    }
  </div>;
});

ScoreSummaryChart.displayName = "ScoreSummaryChart";

ScoreSummaryChart.propTypes = {
  className: PropTypes.string,
  scoreSummaries: PropTypes.arrayOf(PropTypes.instanceOf(ScoreSummaryStore)),
  onHover: PropTypes.func.isRequired,
  onUnhover: PropTypes.func.isRequired
};
