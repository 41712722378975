import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";

export function SwitchWithLabel({ className, value, onChange, label }) {
  return <label
    className={ classNames(className, "switch-with-label") }
    data-value={ value ? "true" : "false" }
  >
    <svg className="switch-with-label__svg" viewBox="0 0 50 30">
      <rect
        className="switch-with-label__svg-rect"
        x="0"
        y="0"
        width="50"
        height="30"
        rx="15"
        ry="15"
      />
      <circle
        className="switch-with-label__svg-circle"
        cx="15"
        cy="15"
        r="13"
        transform={ value ? "translate(20 0)" : null }
      />
    </svg>

    <input
      className="switch-with-label__input"
      name="isGoing"
      type="checkbox"
      checked={ value }
      onChange={ onChange }
    />
    { label }
  </label>;
}

SwitchWithLabel.propTypes = {
  className: PropTypes.string,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired
};
