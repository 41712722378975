import { observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";

import {
  CREATED_LESSONS_ROUTE,
  EDIT_EXERCISE_ROUTE,
  EDIT_LESSON_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  LESSONS_HISTORY_ROUTE,
  LIKED_LESSONS_ROUTE,
  LOG_IN_ROUTE,
  NEW_EXERCISE_ROUTE,
  NEW_LESSON_ROUTE,
  NOT_FOUND_ROUTE,
  POPULAR_LESSONS_ROUTE,
  PRACTICE_LESSON_ROUTE,
  RESET_PASSWORD_ROUTE,
  RESET_PASSWORD_SENT_ROUTE,
  RESET_PASSWORD_SUCCESS_ROUTE,
  ROOT_ROUTE,
  SIGN_UP_ROUTE,
  CONTACT_ROUTE,
  WELCOME_ROUTE
} from "../../stores/routes";
import { ContactPage } from "../pages/contact-page";
import { EditExercisePage } from "../pages/edit-exercise-page";
import { EditLessonPage } from "../pages/edit-lesson-page";
import { ErrorPage } from "../pages/error-page";
import { ForgotPasswordPage } from "../pages/forgot-password-page";
import { LessonsIndexPage } from "../pages/lessons-index-page";
import { LogInPage } from "../pages/log-in-page";
import { MobileLessonCreationPage } from "../pages/mobile-lesson-creation-page";
import { PracticeLessonPage } from "../pages/practice-lesson-page";
import { ResetPasswordPage } from "../pages/reset-password-page";
import { ResetPasswordSentPage } from "../pages/reset-password-sent-page";
import { ResetPasswordSuccessPage } from "../pages/reset-password-success-page";
import { RootPage } from "../pages/root-page";
import { RouterView } from "../shared/router-view";
import { ShowLessonPage } from "../pages/show-lesson-page";
import { SignUpPage } from "../pages/sign-up-page";
import { WelcomePage } from "../pages/welcome-page";
import {
  isMobileLessonCreationPageDisplayed,
  useLessThanDesktopMediaQuery
} from "../../hooks/media-queries";
import { useStore } from "../../stores/store-provider";

const MainContent = observer(() => {
  let { routeName } = useStore("routerParams");
  let { hasError } = useStore("error");

  // HACK: Use the desktop media query hook to force the component to rerender when the media query
  // changes.
  useLessThanDesktopMediaQuery();

  if (isMobileLessonCreationPageDisplayed(routeName)) {
    return <MobileLessonCreationPage />;
  }

  if (hasError) {
    return <ErrorPage />;
  }

  return <RouterView
    strict
    viewMap={
      {
        [POPULAR_LESSONS_ROUTE]: LessonsIndexPage,
        [LESSONS_HISTORY_ROUTE]: LessonsIndexPage,
        [CREATED_LESSONS_ROUTE]: LessonsIndexPage,
        [LIKED_LESSONS_ROUTE]: LessonsIndexPage,
        [EDIT_LESSON_ROUTE]: EditLessonPage,
        [EDIT_EXERCISE_ROUTE]: EditExercisePage,
        [NEW_LESSON_ROUTE]: EditLessonPage,
        [NEW_EXERCISE_ROUTE]: EditExercisePage,
        [PRACTICE_LESSON_ROUTE]: PracticeLessonPage,
        [NOT_FOUND_ROUTE]: ErrorPage,
        [LOG_IN_ROUTE]: LogInPage,
        [SIGN_UP_ROUTE]: SignUpPage,
        [FORGOT_PASSWORD_ROUTE]: ForgotPasswordPage,
        [RESET_PASSWORD_ROUTE]: ResetPasswordPage,
        [RESET_PASSWORD_SENT_ROUTE]: ResetPasswordSentPage,
        [RESET_PASSWORD_SUCCESS_ROUTE]: ResetPasswordSuccessPage,
        [WELCOME_ROUTE]: WelcomePage,
        [CONTACT_ROUTE]: ContactPage,
        [ROOT_ROUTE]: RootPage
      }
    }
  />;
});

MainContent.displayName = "MainContent";

export const Main = observer(({ className }) => {

  // NOTE: This component needs an ID so it can be referenced by InfiniteScroll.
  return <div className={ classNames("main", className) } id="main">
    <MainContent />
  </div>;
});

Main.displayName = "Main";

Main.propTypes = {
  className: PropTypes.string
};
