import PropTypes from "prop-types";
import React from "react";

import { Icon } from "../shared/icon";
import { SimpleDraggable } from "../shared/simple-draggable";

export function AutoMoveBar({ id, index }) {
  return <SimpleDraggable
    id={ id }
    index={ index }
    className="auto-move-bar"
  >
    <span className="auto-move-bar__content">
      <Icon name="robot" alt="Auto Move" className="auto-move-bar__icon" />
      Auto move to here
    </span>
  </SimpleDraggable>;
}

AutoMoveBar.propTypes = {
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired
};
