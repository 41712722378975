import { observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";
import classNames from "classnames";

import { AttributeInputWithLabel } from "../shared/attribute-input-with-label";
import { Button } from "../shared/button";
import { CREATED_LESSONS_ROUTE } from "../../stores/routes";
import { TimeInputWithLabel } from "../shared/time-input-with-label";
import { useStore } from "../../stores/store-provider";

export const EditLessonSidebar = observer(({ className }) => {
  let router = useStore("router");
  let dialog = useStore("dialog");
  let { lesson } = useStore("routerParams");

  if (_.isNil(lesson)) {
    return null;
  }

  async function handleDeleteClick() {
    if (!await dialog.confirmDelete(lesson)) {
      return;
    }

    await lesson.destroy();
    router.goTo(CREATED_LESSONS_ROUTE);
  }

  async function handlePublishClick() {
    let title = "Are You Sure?";
    let message = "If you unpublish your lesson, it will no longer be accessable by others.";

    if (lesson.published && !await dialog.confirmDangerous(title, message)) {
      return;
    }

    await lesson.togglePublished();
  }

  return <div className={ classNames(className, "edit-lesson-sidebar") }>
    <AttributeInputWithLabel
      label="Title"
      model={ lesson }
      attribute="title"
    />

    <AttributeInputWithLabel
      label="Description"
      model={ lesson }
      attribute="description"
    />

    <AttributeInputWithLabel
      label="Channel Name / Source Name"
      model={ lesson }
      attribute="sourceName"
    />

    <AttributeInputWithLabel
      label="Video Link / Source Link"
      model={ lesson }
      attribute="sourceUrl"
    />

    <div className="edit-lesson-sidebar__row">
      <TimeInputWithLabel
        label="Start Time"
        model={ lesson }
        attribute="sourceStartTime"
        disabled={ !lesson.isSourceUrlYouTube && !lesson.isSourceUrlTwitch }
      />

      <TimeInputWithLabel
        label="End Time"
        model={ lesson }
        attribute="sourceEndTime"
        disabled={ !lesson.isSourceUrlYouTube }
      />
    </div>

    <div className="edit-lesson-sidebar__publish">
      <p className="edit-lesson-sidebar__publish-text">
        {
          lesson.published
            ? `Published on ${ lesson.formattedPublishedAt }`
            : "If you publish your lesson, it will be visible to everyone."
        }
      </p>
      <Button
        className={
          classNames(
            "edit-lesson-sidebar__publish-button",
            { "edit-lesson-sidebar__publish-button--published": lesson.published }
          )
        }
        text={ lesson.published ? "Unpublish Lesson" : "Publish Lesson" }
        icon="bullhorn"
        treatment="primary"
        onClick={ handlePublishClick }
      />
    </div>

    <div className="edit-lesson-sidebar__actions">
      <Button
        icon="trash"
        text="Delete Lesson"
        className="edit-lesson-sidebar__delete"
        onClick={ handleDeleteClick }
        disabled={ !lesson.isSaved }
        treatment={ lesson.isSaved ? "danger" : "secondary" }
      />
    </div>
  </div>;
});

EditLessonSidebar.displayName = "EditLessonSidebar";

EditLessonSidebar.propTypes = {
  className: PropTypes.string
};
