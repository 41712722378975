import { action } from "mobx";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";
import classNames from "classnames";

import { BLACK, WHITE } from "../../constants";
import { ChessboardPieces } from "../chessboard/chessboard-pieces";
import { FENInputWithLabel } from "../shared/fen-input-with-label";
import { Button } from "../shared/button";
import { SwitchWithLabel } from "../shared/switch-with-label";
import { ToggleButton } from "../shared/toggle-button";
import { useStore } from "../../stores/store-provider";

export const EditStartingPositionSidebar = observer(({ className }) => {
  let { exercise } = useStore("routerParams");

  if (_.isNil(exercise)) {
    return null;
  }

  return <div className={ classNames(className, "edit-starting-position-sidebar") }>
    <FENInputWithLabel exercise={ exercise } />

    <ChessboardPieces
      className="edit-starting-position-sidebar__chessboard-pieces"
      chessboard={ exercise.chessboard }
    />

    <div className="edit-starting-position-sidebar__position-buttons">
      <Button
        className="edit-starting-position-sidebar__position-button"
        icon="trash"
        text="Reset Board"
        onClick={ () => exercise.chessboard.resetToStartingPosition() }
        treatment="clear"
      />
      <Button
        className="edit-starting-position-sidebar__position-button"
        icon="clear"
        text="Clear Board"
        onClick={ () => exercise.chessboard.resetToEmptyPosition() }
        treatment="clear"
      />
    </div>

    <div className="edit-starting-position-sidebar__player-controls">
      <label className="edit-starting-position-sidebar__label">Player</label>
      <ToggleButton
        options={ [ WHITE, BLACK ] }
        labels={ [ "White", "Black" ] }
        value={ exercise.player }
        onChange={ action(({ target }) => exercise.player = target.value) }
      />
      <label className="edit-starting-position-sidebar__label">First Move</label>
      <ToggleButton
        options={ [ WHITE, BLACK ] }
        labels={ [ "White", "Black" ] }
        value={ exercise.chessboard.startingPlayer }
        onChange={ action(({ target }) => exercise.chessboard.startingPlayer = target.value) }
      />
    </div>

    <div className="edit-starting-position-sidebar__castling">
      <SwitchWithLabel
        label="White is allowed to castle kingside"
        value={ exercise.chessboard.whiteCanCastleKingside }
        onChange={
          action(() => {
            return exercise.chessboard.whiteCanCastleKingside =
              !exercise.chessboard.whiteCanCastleKingside;
          })
        }
      />
      <SwitchWithLabel
        label="White is allowed to castle queenside"
        value={ exercise.chessboard.whiteCanCastleQueenside }
        onChange={
          action(() => {
            return exercise.chessboard.whiteCanCastleQueenside =
              !exercise.chessboard.whiteCanCastleQueenside;
          })
        }
      />
      <SwitchWithLabel
        label="Black is allowed to castle kingside"
        value={ exercise.chessboard.blackCanCastleKingside }
        onChange={
          action(() => {
            return exercise.chessboard.blackCanCastleKingside =
              !exercise.chessboard.blackCanCastleKingside;
          })
        }
      />
      <SwitchWithLabel
        label="Black is allowed to castle queenside"
        value={ exercise.chessboard.blackCanCastleQueenside }
        onChange={
          action(() => {
            return exercise.chessboard.blackCanCastleQueenside =
              !exercise.chessboard.blackCanCastleQueenside;
          })
        }
      />
    </div>
  </div>;
});

EditStartingPositionSidebar.displayName = "EditStartingPositionSidebar";

EditStartingPositionSidebar.propTypes = {
  className: PropTypes.string
};
