import _ from "lodash";

/**
 * If the provided date is null, this method returns null. If it's a date, it returns the date. If
 * it's a string, it parses the date.
 */
export function maybeParseDate(date) {

  if (_.isNil(date)) {
    return null;
  }

  if (_.isDate(date)) {
    return date;
  }

  if (_.isString(date)) {
    return new Date(date);
  }

  throw new Error("The date must be null, a string or a date.");
}
