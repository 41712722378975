import { HelmetProvider } from "react-helmet-async";
import { RouterContext } from "mobx-state-router";
import PropTypes from "prop-types";
import React, { createContext, useContext } from "react";

import { routerParams, router, dialog, error, session } from "./stores";
import { routerStore } from "./router-store";

const STORES = { routerParams, router, dialog, error, session };

export const StoreContext = createContext();

export const StoreProvider = ({ children }) => {
  return <StoreContext.Provider value={ STORES }>
    <HelmetProvider>
      <RouterContext.Provider value={ routerStore }>
        { children }
      </RouterContext.Provider>
    </HelmetProvider>
  </StoreContext.Provider>;
};

StoreProvider.propTypes = {
  children: PropTypes.element.isRequired
};

export const useStore = (store) => {
  const stores = useContext(StoreContext);

  if (!stores) {
    throw new Error("useStore must be used within a StoreProvider.");
  }

  if (!stores[store]) {
    throw new Error(`Couldn't find a store named ${ store }.`);
  }

  return stores[store];
};
