import { Flipper, Flipped } from "react-flip-toolkit";
import { action } from "mobx";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import React, { useRef } from "react";
import _ from "lodash";
import classNames from "classnames";

import { ExerciseIcon } from "../exercise/exercise-icon";
import { ExerciseStore } from "../../stores/models/exercise-store";
import { Button } from "../shared/button";
import { LessonStore } from "../../stores/models/lesson-store";
import { PracticeScore } from "../practice/practice-score";
import { Spoiler } from "../shared/spoiler";
import { useDesktopMediaQuery } from "../../hooks/media-queries";
import { useReaction } from "../../hooks/use-reaction";
import { useStore } from "../../stores/store-provider";
import { CONTENT_STATE } from "../../constants";

const Page = observer(({ exercise, lesson, index }) => {
  let isDesktop = useDesktopMediaQuery();
  let elementRef = useRef();

  useReaction(() => lesson.currentExerciseIndex, () => {
    let isCurrentPage = lesson.currentExerciseIndex === -1 && _.isNil(exercise)
      || lesson.currentExercise.id === exercise?.id;

    if (isDesktop && isCurrentPage) {
      elementRef.current.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  });

  return <Flipped flipId={ exercise?.id ?? "content" } spring="noWobble">
    <li ref={ elementRef } className="practice-lesson-sidebar__exercise">
      <Button
        className="practice-lesson-sidebar__button"
        onClick={ action(() => lesson.currentExerciseIndex = index) }
        treatment={ lesson.currentExerciseIndex === index ? "selected" : "clear" }
      >
        <ExerciseIcon
          className="practice-lesson-sidebar__icon"
          state={ exercise?.score?.state ?? CONTENT_STATE }
        />

        <Spoiler enabled={ lesson.infoHidden }>
          <span className="practice-lesson-sidebar__title">{ exercise?.title ?? lesson.title }</span>
        </Spoiler>
      </Button>
    </li>
  </Flipped>;
});

Page.displayName = "Practice";

Page.propTypes = {
  exercise: PropTypes.instanceOf(ExerciseStore).isRequired,
  lesson: PropTypes.instanceOf(LessonStore).isRequired,
  index: PropTypes.number.isRequired
};

export const PracticeLessonSidebar = observer(({ className }) => {
  let { lesson } = useStore("routerParams");

  if (_.isNil(lesson)) {
    return null;
  }

  return <div className={ classNames(className, "practice-lesson-sidebar") }>
    <PracticeScore className="practice-lesson-sidebar__score" />

    <div className="practice-lesson-sidebar__controls">
      <Button
        className="practice-lesson-sidebar__control"
        icon="redo"
        text="Restart All"
        onClick={ () => lesson.restartAll() }
        treatment="secondary"
      />

      <Button
        className="practice-lesson-sidebar__control"
        icon="times"
        text="Retry Missed"
        onClick={ () => lesson.restartMissed() }
        treatment="secondary"
      />

      <Button
        className={
          classNames(
            "practice-lesson-sidebar__control",
            { "button--highlight": lesson.isShuffled }
          )
        }
        icon="random"
        text="Shuffle"
        onClick={ () => lesson.toggleShuffle() }
        treatment="secondary"
      />

      <Button
        className={
          classNames(
            "practice-lesson-sidebar__control",
            { "button--highlight": lesson.infoHidden }
          )
        }
        icon="eye"
        text="Hide Info"
        onClick={ () => lesson.toggleHideInfo() }
        treatment="secondary"
      />
    </div>

    <ol className="practice-lesson-sidebar__exercises">
      <Flipper flipKey={ lesson.isShuffled }>
        <Page
          exercise={ null }
          index={ -1 }
          lesson={ lesson }
        />
        {
          lesson.exercises.map((exercise, index) => {
            return <Page
              key={ exercise.id }
              exercise={ exercise }
              index={ index }
              lesson={ lesson }
            />;
          })
        }
      </Flipper>
    </ol>
  </div>;
});

PracticeLessonSidebar.displayName = "PracticeLessonSidebar";

PracticeLessonSidebar.propTypes = {
  className: PropTypes.string
};
