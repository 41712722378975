import { observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";

import { Button } from "../shared/button";
import { EDIT_LESSON_ROUTE, PRACTICE_LESSON_ROUTE } from "../../stores/routes";
import { ExerciseIcon } from "../exercise/exercise-icon";
import { ExerciseStore } from "../../stores/models/exercise-store";
import { PreviewLesson } from "../lesson/preview-lesson";
import { useStore } from "../../stores/store-provider";

const ExerciseButton = observer(({ exercise }) => {
  let router = useStore("router");
  let { lesson } = useStore("routerParams");

  function handleClick() {
    router.goTo(PRACTICE_LESSON_ROUTE, {
      params: {
        lessonId: exercise.lessonId
      },
      queryParams: {
        exerciseId: exercise.id
      }
    });
    lesson.goToExercise(exercise.id);
  }

  return <li className="show-lesson-page__exercise">
    <Button
      className="show-lesson-page__exercise-button"
      onClick={ handleClick }
      treatment="secondary"
    >
      <ExerciseIcon className="show-lesson-page__exercise-icon" state={ exercise.score.state } />
      <h4 className="show-lesson-page__exercise-title">
        { exercise.title }
      </h4>
    </Button>
  </li>;
});

ExerciseButton.displayName = "ExerciseButton";

ExerciseButton.propTypes = {
  exercise: PropTypes.instanceOf(ExerciseStore)
};

export const ShowLessonPage = observer(() => {
  let { lesson } = useStore("routerParams");
  let { user } = useStore("session");

  if (_.isNil(lesson)) {
    return null;
  }

  return <div className="show-lesson-page">
    <PreviewLesson lesson={ lesson } />

    <div className="show-lesson-page__controls">
      <p className="show-lesson-page__description">{ lesson.description }</p>
      {
        lesson.creatorId === user?.id
          ? <Button
            icon="pencil"
            text="Edit"
            routeName={ EDIT_LESSON_ROUTE }
            params={ { lessonId: lesson.id } }
            treatment="clear"
          />
          : null
      }

      <Button
        icon="chessKnight"
        text="Practice"
        routeName={ PRACTICE_LESSON_ROUTE }
        params={ { lessonId: lesson.id } }
        treatment="primary"
      />
      </div>

    <hr className="show-lesson-page__horizontal-rule" />

    <ul className="show-lesson-page__exercises">
      {
        lesson.exercises.map(exercise => {
          return <ExerciseButton key={ exercise.id } exercise={ exercise } />;
        })
      }
    </ul>
  </div>;
});

ShowLessonPage.displayName = "ShowLessonPage";
