import { reaction } from "mobx";
import { useEffect } from "react";

/**
 * This hook is just like the standard MobX reaction function, but it hooks into the React component
 * lifecycle. This means the set up for the reaction only happens once, and it's automatically
 * disposed of when the component unmounts.
 *
 * @param dataFunction This function determines what data will trigger the effect function to rerun.
 * Only the *accessed* data is important in this function—not the return value.
 * @param effectFunction The function that's called when the store's properties are changed. This
 * function is called initially when the autorun is initialized.
 * @param dependencies The non-MobX dependencies that should retrigger the callback.
 */
export function useReaction(dataFunction, effectFunction, dependencies = []) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => reaction(dataFunction, effectFunction), dependencies);
}
