import _ from "lodash";

/**
 * Validates that the given value matches the provided regular expression.
 * @return Returns null if the value is valid or an error string if it isn't.
 */
export const validateMatches = _.curry((regex, value) => {
  if (_.isNil(value)) {
    return null;
  }

  return !_.isString(value) || !regex.test(value) ? "Is not valid" : null;
});
