import classNames from "classnames";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";

import { Button } from "../shared/button";
import { Controls } from "../controls/controls";
import { useStore } from "../../stores/store-provider";
import { EDIT_LESSON_ROUTE } from "../../stores/routes";

export const EditLessonSidebarControls = observer(({ className }) => {
  let router = useStore("router");
  let { lesson } = useStore("routerParams");

  if (_.isNil(lesson)) {
    return null;
  }

  async function handleSave() {

    // Save the lesson
    await lesson.save();

    // If the lesson isn't valid, we know the save wasn't successful.
    if (!lesson.isValid) {
      return;
    }

    router.goTo(EDIT_LESSON_ROUTE, {
      replaceHistory: true,
      params: { lessonId: lesson.id }
    });
  }

  return <Controls className={ className }>
    <Button
      className={
        classNames({
          "controls__button--error": lesson.lastSaveFailed && !lesson.isValid
        })
      }
      icon="save"
      text="Save"
      onClick={ handleSave }
      disabled={ !lesson.isDirty }
      treatment="clear"
    />
  </Controls>;
});

EditLessonSidebarControls.displayName = "EditLessonSidebarControls";

EditLessonSidebarControls.propTypes = {
  className: PropTypes.string
};
