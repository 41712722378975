import PropTypes from "prop-types";
import React from "react";
import { Shake } from "reshake";

import { Button } from "../shared/button";
import { SIGN_UP_ROUTE } from "../../stores/routes";
import { VideoContainer } from "../shared/video-container";
import { YouTube } from "../shared/youtube";
import editorVideo from "../../../videos/editor.mp4";
import improveVideo from "../../../videos/improve.mp4";
import libraryVideo from "../../../videos/library.mp4";
import practiceVideo from "../../../videos/practice.mp4";

function Header() {
  return <header className="root-page__header">
    <VideoContainer className="root-page__video">
      <YouTube id="FJMcNnsPRME" />
    </VideoContainer>

    <h1 className="root-page__large-header">
      <Shake fixed q={ 5 }>CRUSH</Shake>
      Your Opponents
    </h1>
    <p className="root-page__subhead">
      <strong>Quickly improve your rating</strong> by practicing openings, endgames, tactics and
      strategies from YouTube videos.
    </p>
  </header>;
}

function Feature({ header, video, children }) {
  return <section className="root-page__feature">
    <VideoContainer className="root-page__feature-screenshot">
      <video width="1280" height="720" autoPlay loop muted playsInline>
        <source src={ video } type="video/mp4" />
      </video>
    </VideoContainer>
    <div className="root-page__feature-content">
      <h2 className="root-page__small-header root-page__feature-header">
        { header }
        <hr className="root-page__hr" />
      </h2>
      { children }
    </div>
  </section>;
}

Feature.propTypes = {
  header: PropTypes.node.isRequired,
  video: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

function CallToAction() {
  return <section className="root-page__call-to-action">
    <h2 className="root-page__large-header">Try It!</h2>
    <p className="root-page__subhead">
      Getting started is free and quick. Give it a try!
    </p>
    <div className="root-page__actions">
      <Button
        className="root-page__action"
        treatment="primary"
        routeName={ SIGN_UP_ROUTE }
        icon="user"
        text={ <>Sign Up for <em>Free</em></> }
      />
    </div>
  </section>;
}

export function RootPage() {
  return <div className="root-page">
    <Header />

    <Feature
      header={ <><em>Quit Losing</em> Because You Forget the Moves</> }
      video={ practiceVideo }
    >
      <p className="root-page__paragraph">
        Have you ever watched a chess video with a
        { " " }
        <strong className="root-page__strong">brilliant</strong>
        { " " }
        series of moves, and then
        { " " }
        <strong className="root-page__strong">immediately</strong>
        { " " }
        forgotten it?
      </p>

      <p className="root-page__paragraph">
        Using Chessercise, you can practice lines until you&apos;ve mastered them.
      </p>
    </Feature>

    <Feature
      header="Improve Your Game"
      video={ improveVideo }
    >
      <p className="root-page__paragraph">
        Keep track of how you&apos;re doing, and <strong>see where you need to improve</strong>.
      </p>

      <p className="root-page__paragraph">
        You can repeat the exercises you&apos;re having trouble with until you&apos;ve got
        them down.
      </p>
    </Feature>

    <Feature
      header="Add Your Favorite Videos"
      video={ editorVideo }
    >
      <p className="root-page__paragraph">
        Chessercise makes it incredibly easy to <strong>create your own lessons</strong> to practice
        with.
      </p>

      <p className="root-page__paragraph">
        Simply drag the pieces on the board for your position, and then make your moves.
      </p>
    </Feature>

    <Feature
      header="Awesome Library"
      video={ libraryVideo }
    >
      <p className="root-page__paragraph">
        Chessercise has an extensive collection of <strong>free, high-quality exercises</strong>
        { " " }
        built by the community.
      </p>

      <p className="root-page__paragraph">
        If you don&apos;t see what you&apos;re looking for, you can build it yourself in a few
        minutes.
      </p>
    </Feature>

    <CallToAction />
  </div>;
}
