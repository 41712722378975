import { observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";
import { browserHistory as history } from "mobx-state-router";

import { Button } from "../shared/button";
import { IconModal } from "../shared/icon-modal";
import { useStore } from "../../stores/store-provider";

export const PracticeLessonModal = observer(({ className }) => {
  let { lesson } = useStore("routerParams");
  let { isLoggedIn } = useStore("session");

  if (_.isNil(lesson)) {
    return null;
  }

  let goBackButton = <Button
    key="goBack"
    icon="arrowLeft"
    text="Back to Lesson"
    onClick={ () => history.goBack() }
    treatment="secondary"
  />;

  if (!lesson.hasExercises) {
    return <IconModal
      className={ className }
      icon="emptySetFilledCircle"
      title="No Exercises"
      text="There’s nothing here! This lesson needs exercises before it can be played."
      closable={ false }
      open
    >
      { goBackButton }
    </IconModal>;
  }

  if (!isLoggedIn) {
  return <IconModal
    className={ className }
    icon="checkFilledCircle"
    title="Nice Job!"
    text={
      <>
        You completed all of the exercises! Sign up for Chessercise for <em>free</em> to save your
        progress.
      </>
    }
    open={ lesson.isCompleted }
    stacked
  >
    <Button
      icon="user"
      text="Sign Up"
      onClick={ () => lesson.restartAll() }
      treatment="primary"
    />
  </IconModal>;
  }

  return <IconModal
    className={ className }
    icon="checkFilledCircle"
    title="Nice Job!"
    text="You completed all of the exercises!"
    open={ lesson.isCompleted }
    stacked
  >
    <Button
      icon="play"
      text="Restart All"
      onClick={ () => lesson.restartAll() }
      treatment="primary"
    />
    <Button
      icon="redo"
      text="Replay Missed"
      onClick={ () => lesson.restartMissed() }
      treatment="primary"
    />
    { goBackButton }
  </IconModal>;
});

PracticeLessonModal.displayName = "PracticeLessonModal";

PracticeLessonModal.propTypes = {
  className: PropTypes.string
};
