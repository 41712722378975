import { observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import TwitchEmbedVideo from "react-twitch-embed-video";
import _ from "lodash";
import classNames from "classnames";

import { LessonStore } from "../../stores/models/lesson-store";
import { PreviewLink } from "./preview-link";
import { YouTube } from "../shared/youtube";
import { AspectRatioContainer } from "../shared/aspect-ratio-container";
import { VIDEO_ASPECT_RATIO } from "../../constants";

export const PreviewLesson = observer(({ lesson, className }) => {
  className = classNames("preview-lesson", className);

  if (lesson.isSourceUrlYouTube) {
    return <AspectRatioContainer className={ className } aspectRatio={ VIDEO_ASPECT_RATIO }>
      <YouTube
        id={ lesson.youTubeVideoId }
        className={ classNames("preview-lesson__youtube", className) }
        start={ lesson.sourceStartTime }
        end={ lesson.sourceEndTime }
      />
    </AspectRatioContainer>;
  }

  if (lesson.isSourceUrlTwitch) {
    let options = _.isNil(lesson.sourceStartTime) ? {} : { time: lesson.sourceStartTime };

    return <AspectRatioContainer className={ className } aspectRatio={ VIDEO_ASPECT_RATIO }>
      <TwitchEmbedVideo
        autoplay={ false }
        width="100%"
        height="100%"
        video={ lesson.twitchVideoId }
        { ...options }
      />
    </AspectRatioContainer>;
  }

  return <div className={ classNames(className, "preview-lesson--link") }>
    <PreviewLink
      title={ lesson.title }
      url={ lesson.sourceUrl }
      sourceName={ lesson.sourceName }
      controls
    />
  </div>;
});

PreviewLesson.displayName = "PreviewLesson";

PreviewLesson.propTypes = {
  lesson: PropTypes.instanceOf(LessonStore).isRequired,
  className: PropTypes.string
};
