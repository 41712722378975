import { RouterLink } from "mobx-state-router";
import { observer } from "mobx-react";
import React, { useState } from "react";

import { AttributeInputWithLabel } from "../shared/attribute-input-with-label";
import { AuthenticationPage } from "../authentication/authentication-page";
import { LOG_IN_ROUTE, ROOT_ROUTE } from "../../stores/routes";
import { PasswordStrength } from "../authentication/password-strength";
import { UserStore } from "../../stores/models/user-store";
import { useStore } from "../../stores/store-provider";

export const SignUpPage = observer(() => {
  let [ user ] = useState(() => new UserStore());
  let router = useStore("router");

  async function handleSignUp(event) {
    event.stopPropagation();
    event.preventDefault();

    await user.save();

    if (user.isSaved) {
      router.goTo(ROOT_ROUTE);
    }
  }

  return <AuthenticationPage
    className="sign-up-page"
    title="Create Account"
    buttonText="Create Account"
    onSubmit={ handleSignUp }
    message={
      <>
        Already have an account?
        { " " }
        <RouterLink routeName={ LOG_IN_ROUTE }>Log in</RouterLink>.
      </>
    }
  >

    <AttributeInputWithLabel
      label="Email"
      model={ user }
      attribute="email"
      type="email"
    />

    <AttributeInputWithLabel
      label="Username"
      model={ user }
      attribute="username"
      type="text"
    />

    <AttributeInputWithLabel
      className="sign-up-page__password-input"
      label="Password"
      model={ user }
      attribute="password"
      type="password"
    />

    <PasswordStrength password={ user.password } />

    <p className="sign-up-page__agreement">
      By creating an account, you agree to our
      { " " }
      <a href="/terms-of-service" target="_blank">terms of service</a>
      { " " }
      and
      { " " }
      <a href="/privacy-policy" target="_blank">privacy policy</a>.
    </p>
  </AuthenticationPage>;
});

SignUpPage.displayName = "SignUpPage";
