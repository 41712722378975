import _ from "lodash";

/**
 * This function is like _.padStart, except it works on arrays.
 */
export function padArrayStart(array, length, value) {
  let paddingLength = length - array.length < 0 ? 0 : length - array.length;
  let padding = _.fill(new Array(paddingLength), value);
  return [ ...padding, ...array ];
}

/**
 * This function is like _.findIndex, except it starts at a certain index and loops through the
   beginning elements after it reaches the end.
 */
export function findIndexStartingAt(array, startingIndex, predicate) {
  let result = _.findIndex(array.slice(startingIndex), predicate);

  if (result !== -1) {
    return result + startingIndex;
  }

  return _.findIndex(array.slice(0, startingIndex), predicate);
}

/**
 * This function is like _.findLastIndex, except it starts at a certain index and loops through the
 * ending elements after it reaches the beginning.
 */
export function findLastIndexStartingAt(array, startingIndex, predicate) {
  let result = _.findLastIndex(array.slice(0, startingIndex + 1), predicate);

  if (result !== -1) {
    return result;
  }

  result = _.findLastIndex(array.slice(startingIndex + 1), predicate);
  return result === -1 ? -1 : result + startingIndex + 1;
}
