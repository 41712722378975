import _ from "lodash";

const ERROR_MESSAGE = "Must be URL";

export function validateUrl(value) {
  if (_.isNil(value)) {
    return null;
  }

  let url;

  try {
    url = new URL(value);
  }
  catch {
    return ERROR_MESSAGE;
  }

  if (![ "http:", "https:" ].includes(url.protocol)) {
    return ERROR_MESSAGE;
  }

  return null;
}
