import { capitalize } from "voca";
import { toJS, runInAction } from "mobx";
import _ from "lodash";

import { BaseStore } from "../base-store";
import { apiClient } from "../../api/api-client";

function callApiClientMethod(methodName, resourceName, plural, ...parameters) {
  let fullMethodName = `${ methodName }${ capitalize(resourceName) }${ plural ? "s" : "" }`;

  if (!_.has(apiClient, fullMethodName)) {
    throw new Error(`The API client does not contain a method named ${ fullMethodName }.`);
  }

  return apiClient[fullMethodName](...parameters);
}

function cloneAttributes(attributes) {
  return _.cloneDeep(toJS(attributes));
}

/**
 * Defines the base ModelStore that can be extended by other models.
 */
export class ModelStore extends BaseStore {
  id = null
  lastSaveFailed = false
  lastSavedAttributes = {}

  /**
   * @inheritdoc
   */
  initialize(attributes = {}) {
    super.initialize(attributes);

    // Ensure the resource name is defined.
    if (_.isNil(this.constructor.RESOURCE_NAME)) {
      throw new Error("You must define the RESOURCE_NAME static property on your model class!");
    }

    // Set the last saved attributes if the model is saved.
    this.lastSavedAttributes = this.isSaved ? cloneAttributes(this.attributes) : {};
  }

  /**
   * Returns a simple object of the attributes in this model.
   */
  get isSaved() {
    return !_.isNil(this.id);
  }

  /**
   * Returns a simple object of the attributes in this model.
   */
  get isDirty() {
    return !this.isSaved || !_.isEqual(toJS(this.attributes), toJS(this.lastSavedAttributes));
  }

  /**
   * Fetches an instance of the model from the API using the provided ID.
   */
  static async fetch(id) {
    let data = await callApiClientMethod(
      "get",
      this.RESOURCE_NAME,
      false,
      { id }
    );

    return new this(data);
  }

  static async fetchAll(...parameters) {
    let result = await callApiClientMethod(
      "get",
      this.RESOURCE_NAME,
      true,
      ...parameters
    );

    return result.map(modelAttributes => new this(modelAttributes));
  }

  /**
   * Calls the API with the model's data and saves the result. This should return the data that will
   * be assigned back to the model. This method is not meant to be called directly. Rather, it's
   * meant to be overridden in a subclass.
   */
  async apiSave() {
    return callApiClientMethod(
      this.isSaved ? "update" : "create",
      this.constructor.RESOURCE_NAME,
      false,
      this.attributes
    );
  }

  /**
   * Saves the model to the API. Not every model will use the default saving mechanism, so this
   * method will call `apiSave` in lieu of the default API create, update or upsert methods if
   * it's present.
   */
  async save() {
    if (!this.isValid) {
      this.lastSaveFailed = true;
      return;
    }

    if (!this.isDirty) {
      return;
    }

    try {
      this.attributes = await this.apiSave();
    }
    catch (error) {
      this.lastSaveFailed = true;
      throw error;
    }

    runInAction(() => {
      this.lastSavedAttributes = cloneAttributes(this.attributes);
      this.lastSaveFailed = false;
    });

    return this;
  }

  /**
   * Initializes a new model with all of the same attributes as the current model excluding the ID.
   */
  clone() {
    return new this.constructor(_.omit(this.attributes, [ "id" ]));
  }

  /**
   * Destroys the model.
   */
  async destroy() {
    if (!this.isSaved) {
      throw new Error("You can't destroy a model that hasn't been saved yet!");
    }

    await callApiClientMethod("delete", this.constructor.RESOURCE_NAME, false, { id: this.id });
  }
}
