import { action } from "mobx";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";
import classNames from "classnames";

import { BLACK, WHITE, playerPropType } from "../../constants";
import { ExerciseStore } from "../../stores/models/exercise-store";
import { SimpleDraggable } from "../shared/simple-draggable";

const ExerciseMoveInput = observer(({ move, index, exercise, player }) => {
  if (_.isNil(move) && player === BLACK) {
    return null;
  }

  let isSelected = exercise.moveIndex === index && !_.isNil(move);
  let isInvalid = !isSelected && exercise.invalidMoveIndex === index;

  return <input
    key={ index }
    disabled={ _.isNil(move) }
    className={
      classNames(
        "exercise-move-row__input",
        {
          "input--selected": isSelected,
          "input--error": isInvalid,
          "exercise-move-row__input--black": player === BLACK,
          "exercise-move-row__input--white": player === WHITE
        }
      )
    }
    value={ move || "" }
    onChange={ action(({ target }) => exercise.updateMove(index, target.value)) }
    onFocus={ () => exercise.goToMove(index) }
    onBlur={ () => exercise.sanitizeMoves() }
  />;
});

ExerciseMoveInput.displayName = "MoveInput";

ExerciseMoveInput.propTypes = {
  move: PropTypes.string,
  index: PropTypes.number.isRequired,
  exercise: PropTypes.instanceOf(ExerciseStore).isRequired,
  player: playerPropType
};

export const ExerciseMoveRow = observer(({
  whiteMove,
  whiteMoveIndex,
  blackMove,
  blackMoveIndex,
  exercise,
  index,
  id
}) => {

  let whiteMoveComponent = <ExerciseMoveInput
    player={ WHITE }
    move={ whiteMove }
    index={ whiteMoveIndex }
    exercise={ exercise }
  />;

  let blackMoveComponent = <ExerciseMoveInput
    player={ BLACK }
    move={ blackMove }
    index={ blackMoveIndex }
    exercise={ exercise }
  />;

  return <SimpleDraggable
    id={ id }
    index={ index }
    className="exercise-move-row"
    locked
  >
    { whiteMoveComponent }
    { blackMoveComponent }
  </SimpleDraggable>;
});

ExerciseMoveRow.displayName = "ExerciseMoveRow";

ExerciseMoveRow.propTypes = {
  blackMove: PropTypes.string,
  whiteMove: PropTypes.string,
  blackMoveIndex: PropTypes.number,
  whiteMoveIndex: PropTypes.number,
  exercise: PropTypes.instanceOf(ExerciseStore).isRequired,
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired
};
