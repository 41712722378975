import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";
import classNames from "classnames";

export function YouTube({ className, id, start, end }) {
  if (_.isNil(id)) {
    return null;
  }

  let url = new URL(`https://www.youtube.com/embed/${ id }`);

  if (!_.isNil(start)) {
    url.searchParams.set("start", start);
  }

  if (!_.isNil(end)) {
    url.searchParams.set("end", end);
  }

  return <iframe
    className={ classNames("youtube", className) }
    src={ url.toString() }
    title="YouTube"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  />;
}

YouTube.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  start: PropTypes.number,
  end: PropTypes.number
};
