import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";
import classNames from "classnames";
import { titleCase } from "voca";

import { importHash } from "../../utilities/import";

const ICONS = importHash(require.context(
  "../../../images/icons",
  false,
  /\.svg$/
));

export function Icon({ name, alt = titleCase(name.replace("-", " ")), className, filled }) {

  if (!_.has(ICONS, name)) {
    throw new Error(`Couldn't find an icon with the name '${ name }'.`);
  }

  let IconComponent = ICONS[name];

  return <IconComponent
    className={ classNames("icon", className, { "icon--filled": filled }) }
    alt={ alt }
  />;
}

Icon.defaultProps = {
  filled: true
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  alt: PropTypes.string,
  className: PropTypes.string,
  filled: PropTypes.bool
};
