import { observer, useLocalObservable } from "mobx-react";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef } from "react";
import _ from "lodash";
import classNames from "classnames";

import { ChessboardArrows } from "./chessboard-arrows";
import { ChessboardCoordinates } from "./chessboard-coordinates";
import { ChessboardDroppable } from "./chessboard-droppable";
import { ChessboardPromotion } from "./chessboard-promotion";
import { ChessboardStore } from "../../stores/chessboard-store";
import { PLAYERS, SQUARE_ASPECT_RATIO } from "../../constants";
import { AspectRatioContainer } from "../shared/aspect-ratio-container";
import {
  createCMChessboard,
  updateCMChessboardMarkers,
  updateCMChessboardMovement,
  updateCMChessboardOrientation,
  updateCMChessboardPosition
} from "./cm-chessboard";
import { useAutorun } from "../../hooks/use-autorun";
import { useLessThanTabletMediaQuery } from "../../hooks/media-queries";
import { useReaction } from "../../hooks/use-reaction";
import { isUncompletedPromotion, uncompletedPromotionFen } from "../../utilities/fen";
import { addPromotionToMove } from "../../utilities/moves";

/* eslint-disable react/no-this-in-sfc */

/**
 * This is a fully-featured chessboard component. It's designed to take a ChessboardStore and
 * interactively display the pieces. It allows the player to move, and it plays sounds. It
 * completely encapsulates the CMChessboard component, not exposing any of the functionality.
 *
 * @param chessboard The ChessboardStore played. If the store updates, this component automatically
 * updates to reflect the changes.
 * @param className An optional class to append to the top-level HTML element.
 * @param orientation The orientation of the chessboard.
 * @param allowMovement Determines if the current player is allowed to move.
 * @param onMove An optional callback that's called when the move finishes. This callback is
 * passed the cm-chessboard event, and should return a boolean to indicate whether the interaction
 * should be aborted or not.
 * @param onMoveOutOfBoard An optional callback that's called when a piece is moved out of the
 * board.
 * @param onPositionUpdate An optional callback that's called when the chessboard's position
 * is finished updating. Unlike the onMove callback, this is fired for *any* position update, not
 * just user-initiated moves.
 */
export const Chessboard = observer(({
  chessboard,
  orientation,
  className,
  onPositionUpdated,
  onMove,
  onMoveOutOfBoard,
  allowMovement
}) => {

  // A media query that determines if the screen's width is less than the tablet size.
  let isLessThanTablet = useLessThanTabletMediaQuery();

  // The ref used to house the cm-chessboard instance.
  let cmChessboardRef = useRef(null);

  // The ref used to house the chessboard DOM node.
  /* eslint-disable react-hooks/exhaustive-deps */
  let chessboardRef = useCallback(element => {

    // HACK: Only create the chessboard ref if it hasn't already been initialized for the current
    // element. I don't know why this is necessary.
    if (element?.children?.length !== 0) {
      return;
    }

    cmChessboardRef.current = createCMChessboard({
      element,
      chessboard,
      isLessThanTablet
    });
  }, [ isLessThanTablet ]);
  /* eslint-enable react-hooks/exhaustive-deps */

  // If the orientation updates, update the position in the cm-chessboard.
  useEffect(() => {
    updateCMChessboardOrientation({
      cmChessboard: cmChessboardRef.current,
      orientation
    });
  }, [ orientation ]);

  // This observable keeps track of the promotion state of the chessboard. It determines if a
  // promotion is currently in progress and what the FEN for that promotion should be.
  const promotion = useLocalObservable(() => ({
    move: null,

    get isPromoting() {
      return !_.isNil(this.move);
    },

    get fen() {
      return uncompletedPromotionFen(chessboard.fen, this.move);
    },

    promotionMove(piece) {
      return addPromotionToMove(this.move, piece);
    },

    clear() {
      this.move = null;
    }
  }));

  // If the chessboard's FEN updates, clear out the current promotion.
  useReaction(() => [ chessboard.fen ], () => promotion.clear());

  // If the chessboard's FEN updates, update the position in the cm-chessboard.
  useAutorun(async () => {

    // Update the position.
    await updateCMChessboardPosition({
      cmChessboard: cmChessboardRef.current,
      chessboard,
      onPositionUpdated,
      position: promotion.isPromoting ? promotion.fen : chessboard.fen
    });
  }, [ onPositionUpdated, promotion.isPromoting ]);

  // Only pass through completed moves. No uncompleted promotions should trigger the move callback.
  function handleMove(move) {

    // If the move is an uncompleted promotion, cancel it and trigger the promotion.
    if (!chessboard.editingStartingPosition && isUncompletedPromotion(chessboard.fen, move)) {
      promotion.move = move;
      return false;
    }

    // Trigger the move callback.
    return onMove(move);
  }

  // When a piece is selected for promotion, add it to the promotion move!
  function handlePromotion(piece) {

    // Determine the move for the promotion.
    let move = promotion.promotionMove(piece);
    let position = promotion.fen;

    // HACK: Preemptively update the position with an animation to avoid a transition. If the move
    // is rejected, this will automatically animate back.
    cmChessboardRef.current.setPosition(position, false);

    // Trigger the normal move callback.
    if (!onMove(move)) {

      // HACK: If the move was not successful, then clear the promotion. Otherwise, let the
      // promotion stay until the next move is played so the position doesn't update.
      promotion.clear();
    }
  }

  // If the chessboard's FEN movement properties update, update the cm-chessboard's movement. This
  // is separate from the position updates to disentangle the onMove, onMoveOutOfBoard and
  // allowMovement dependencies.
  useAutorun(() => {
    updateCMChessboardMovement({
      cmChessboard: cmChessboardRef.current,
      chessboard,
      onMove: handleMove,
      onMoveOutOfBoard,
      allowMovement
    });
  }, [ onMove, onMoveOutOfBoard, allowMovement ]);

  // If the chessboard's markers update, update the cm-chessboard's movement.
  useAutorun(() => {
    updateCMChessboardMarkers({
      cmChessboard: cmChessboardRef.current,
      chessboard
    });
  });

    return <AspectRatioContainer
      className={ classNames("chessboard", className) }
      aspectRatio={ SQUARE_ASPECT_RATIO }
      >
    <ChessboardCoordinates
      chessboard={ chessboard }
      className="chessboard__coordinates"
      orientation={ orientation }
    >
      <ChessboardDroppable
        chessboard={ chessboard }
        orientation={ orientation }
      >
        <ChessboardArrows
          chessboard={ chessboard }
          chessboardRef={ chessboardRef }
          orientation={ orientation }
        >
          <div
            className="cm-chessboard"
            ref={ chessboardRef }
          />
          <ChessboardPromotion
            active={ promotion.isPromoting }
            onPromotion={ handlePromotion }
            player={ chessboard.currentPlayer }
            orientation={ orientation }
            square={ promotion.move?.to }
          />
        </ChessboardArrows>
      </ChessboardDroppable>
    </ChessboardCoordinates>
  </AspectRatioContainer>;
});

Chessboard.displayName = "Chessboard";

Chessboard.defaultProps = {
  allowMovement: false,
  onMove: () => true,
  onPositionUpdated: _.noop,
  onMoveOutOfBoard: _.noop
};

Chessboard.propTypes = {
  chessboard: PropTypes.instanceOf(ChessboardStore),
  orientation: PropTypes.oneOf(PLAYERS).isRequired,
  className: PropTypes.string,
  allowMovement: PropTypes.bool,
  onMove: PropTypes.func,
  onPositionUpdated: PropTypes.func,
  onMoveOutOfBoard: PropTypes.func
};
