import { observer } from "mobx-react";
import React, { useEffect } from "react";
import _ from "lodash";

import { PracticeAudio } from "../chessboard/practice-audio";
import { PracticeExercise } from "../practice/practice-exercise";
import { PracticeLessonModal } from "../practice/practice-lesson-modal";
import { StaticChessboard } from "../chessboard/static-chessboard";
import { WHITE } from "../../constants";
import { mod } from "../../utilities/math";
import { useStore } from "../../stores/store-provider";
import { ChessboardStore } from "../../stores/chessboard-store";
import { LessonContent } from "../practice/lesson-content";

/* eslint-disable react-hooks/exhaustive-deps */

export const PracticeLessonPage = observer(() => {
  let { lesson } = useStore("routerParams");

  // If the current exercise changes, automatically trigger its starting auto moves.
  //
  // BUG FIX: This now depends on the exercise's completedMoveIndex. This ensures the auto move will
  // retrigger when the exercise is restarted.
  useEffect(() => {
    if (lesson?.currentExercise?.completedMoveIndex === -1) {
      lesson?.currentExercise?.autoMoveAfterDelay();
    }
  }, [ lesson?.currentExerciseIndex, lesson?.currentExercise?.completedMoveIndex ]);

  // If the current exercise completes, automatically go to the next one.
  useEffect(() => {

    // Don't do anything unless there's a current exercise.
    if (_.isNil(lesson?.currentExercise)) {
      return;
    }

    // If the current exercise is complete, save its score.
    if (lesson?.currentExercise?.isCompleted) {
      lesson.currentExercise.score.save();
    }

    // Only navigate if the current exercise is complete, it wasn't previously navigated after
    // completion, and it's not the last exercise.
    let shouldNavigate = lesson.currentExercise.isCompleted
      && !lesson.currentExercise.hasNavigatedSinceCompletion
      && lesson.hasUncompletedExercise;

    // Navigate the exercise if necessary
    if (shouldNavigate) {
      lesson.nextUncompletedExerciseAfterDelay();
    }
  }, [ lesson?.currentExercise?.isCompleted ]);

  // Don't render anything unless a lesson has been loaded.
  if (_.isNil(lesson)) {
    return null;
  }

  // In order to improve performance, only render the exercises directly next to the
  // current exercise.
  let visibleIndices = [
    mod(lesson.currentExerciseIndex - 1, lesson.exercises.length),
    lesson.currentExerciseIndex,
    mod(lesson.currentExerciseIndex + 1, lesson.exercises.length)
  ];

  return <div
    className="practice-lesson-page"
    style={ { "--current-practice-index": lesson.currentExerciseIndex } }
  >
    {
      lesson.currentExercise
        ? <PracticeAudio exercise={ lesson.currentExercise } />
        : null
    }
    {
      lesson.hasExercises
        ? <>
          <LessonContent className="practice-lesson-page__page" lesson={ lesson } />
          {
            lesson.exercises.map((exercise, index) => {

              return <PracticeExercise
                key={ exercise.id }
                className="practice-lesson-page__page"
                exercise={ exercise }
                visible={ visibleIndices.includes(index) }
              />;
            })
          }
        </>
        : <StaticChessboard
          chessboard={ ChessboardStore.EMPTY }
          orientation={ WHITE }
          className="practice-lesson-page__static-chessboard"
        />
    }
    <PracticeLessonModal />
  </div>;
});

PracticeLessonPage.displayName = "PracticeLessonPage";
