import { observer } from "mobx-react";
import React from "react";

import {
  COOKIE_POLICY_ROUTE,
  COPYRIGHT_ROUTE,
  PRIVACY_POLICY_ROUTE,
  TERMS_OF_SERVICE_ROUTE
} from "../../stores/routes";
import { RouterView } from "../shared/router-view";
import CookiePolicy from "../../../legal/cookie-policy.md";
import Copyright from "../../../legal/copyright.md";
import PrivacyPolicy from "../../../legal/privacy-policy.md";
import TermsOfService from "../../../legal/terms-of-service.md";

const VIEW_MAP = {
  [TERMS_OF_SERVICE_ROUTE]: TermsOfService,
  [PRIVACY_POLICY_ROUTE]: PrivacyPolicy,
  [COOKIE_POLICY_ROUTE]: CookiePolicy,
  [COPYRIGHT_ROUTE]: Copyright
};

/* eslint-disable react/no-children-prop */

export const LegalPage = observer(() => {
  return <section className="legal-page">
    <RouterView viewMap={ VIEW_MAP } />
  </section>;
});

LegalPage.displayName = "LegalPage";
