import _ from "lodash";

export class ValidationError extends Error {

  constructor(errors) {
    super(_.join(_.map(errors, (error, property) => `${ property } ${ error }`), ", "));

    this.errors = errors;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }
  }

  get name() {
    return this.constructor.name;
  }
}

export class NotFoundError extends Error {

  constructor(message = "Not Found") {
    super(message);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }
  }

  get name() {
    return this.constructor.name;
  }
}

export class UnauthorizedError extends Error {

  constructor(message = "Unauthorized") {
    super(message);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }
  }

  get name() {
    return this.constructor.name;
  }
}

export class BadRequestError extends Error {

  constructor(message) {
    super(message);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }
  }

  get name() {
    return this.constructor.name;
  }
}

export class ForbiddenError extends Error {

  constructor(message = "Forbidden") {
    super(message);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }
  }

  get name() {
    return this.constructor.name;
  }
}

export class InternalServerError extends Error {

  constructor(message) {
    super(message);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }
  }

  get name() {
    return this.constructor.name;
  }
}
