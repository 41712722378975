import _ from "lodash";
import {
  BLACK,
  BOARD_SIZE,
  EMPTY_POSITION,
  KINGSIDE,
  PLAYERS,
  QUEENSIDE,
  SIDES,
  STARTING_POSITION,
  WHITE,
  QUEEN
} from "../constants";
import { Chess, pieceToChessJSPiece } from "./chess-js";

/**
 * Splits the fen into an array of parts.
 */
function splitFEN(fen) {
  if (!_.isString(fen)) {
    return [ "" ];
  }

  return fen.split(/\s+/);
}

/**
 * Determines how many spaces have been occupied in a line of a FEN placement.
 */
const placementLineLength = _.flow(
  line => _.split(line, ""),
  line => _.sumBy(line, character => /\d/.test(character) ? parseInt(character, 10) : 1)
);

/**
 * Ensures the FEN line adds up to 8.
 */
function sanitizeLine(line) {

  // Remove any numbers at the end of the line
  line = line.replace(/\d+$/, "");

  // Add the line length to the end of the line if necessary.
  let lineLength = placementLineLength(line);

  return lineLength >= BOARD_SIZE
    ? line
    : `${ line }${ BOARD_SIZE - lineLength }`;
}

/**
 * Sanitizes the placement portion of the FEN.
 * TODO: Replace this with the functional pipeline operator.
 */
const sanitizePlacement = _.flow(

  // Split out the lines
  placement => placement.split("/"),

  // Make sure there are at least 8 lines.
  lines => [ ...lines, ..._.times(BOARD_SIZE - lines.length, () => "") ],

  // Remove extra lines
  lines => lines.slice(0, BOARD_SIZE),

  // Sanitize each line
  lines => lines.map(sanitizeLine),

  // Put the lines back together
  lines => lines.join("/")
);

/**
 * Given a FEN, this function "completes" the fen with a set of sane defaults.
 */
export function sanitizeFEN(fen = STARTING_POSITION) {
  let fenParts = splitFEN(fen);
  let placement = sanitizePlacement(fenParts[0]);

  let sanitizedFen = [
    placement,
    ...fenParts.slice(1, fenParts.length),
    ...EMPTY_POSITION.split(/\s+/).slice(fenParts.length, EMPTY_POSITION.length)
  ].join(" ");

  return new Chess(sanitizedFen).fen();
}

/**
 * Returns true if two fen strings are equivalent.
 */
export function fenPositionsEqual(fen1, fen2) {
  return sanitizeFEN(fen1).split(" ")[0] === sanitizeFEN(fen2).split(" ")[0];
}

/**
 * Returns true if the FEN is valid.
 */
export function isFenValid(fen) {
  return new Chess().validate_fen(fen).valid;
}

/**
 * Throws an error if the FEN is not valid.
 */
function validateFen(fen) {
  if (!isFenValid(fen)) {
    throw new Error(`The FEN '${ fen }' is not valid.`);
  }
}

/**
 * Throws an error if the FEN is not valid.
 */
function validatePlayer(player) {
  if (!PLAYERS.includes(player)) {
    throw new Error(`The player '${ player }' is not valid.`);
  }
}

function validateSide(side) {
  if (!SIDES.includes(side)) {
    throw new Error(`The side '${ side }' is not valid.`);
  }
}

/**
 * Returns the starting player from the FEN.
 */
export function startingPlayer(fen) {
  let player = splitFEN(fen)[1];

  if (_.isNil(player)) {
    throw new Error(`The fen '${ fen }' does not contain a starting player.`);
  }

  return player === "b" ? BLACK : WHITE;
}

/**
 * Returns a new FEN string with an altered starting player.
 */
export function setFenStartingPlayer(fen, player) {
  validateFen(fen);
  validatePlayer(player);

  let fenParts = splitFEN(fen);
  fenParts[1] = player === WHITE ? "w" : "b";
  return fenParts.join(" ");
}

const FEN_CASTLE_SYMBOLS = {
  [WHITE]: {
    [KINGSIDE]: "K",
    [QUEENSIDE]: "Q"
  },
  [BLACK]: {
    [KINGSIDE]: "k",
    [QUEENSIDE]: "q"
  }
};

/**
 * Returns true or false depending on whether the given player can castle on the given side.
 */
export function fenCanCastle(fen, player, side) {
  validateFen(fen);
  validatePlayer(player);
  validateSide(side);

  return splitFEN(fen)[2].includes(FEN_CASTLE_SYMBOLS[player][side]);
}

/**
 * Returns a new FEN string with altered castling rights.
 */
export function setFenCanCastle(fen, player, side, canCastle) {
  validateFen(fen);
  validatePlayer(player);
  validateSide(side);

  let castlingRights = PLAYERS.map(playerValue => {
    return SIDES.map(sideValue => {
      let value = playerValue === player && sideValue === side
        ? canCastle
        : fenCanCastle(fen, playerValue, sideValue);

      return value ? FEN_CASTLE_SYMBOLS[playerValue][sideValue] : "";
    }).join("");
  }).join("");

  if (castlingRights === "") {
    castlingRights = "-";
  }

  let fenParts = splitFEN(fen);
  fenParts[2] = castlingRights;
  return fenParts.join(" ");
}

/**
 * Returns true if the move is an uncompleted promotion (a pawn promotion without a specified
 * piece).
 */
export function isUncompletedPromotion(fen, move) {
  let chess = new Chess(fen);

  // If the move is a string, attempt to add a promotion to the end of it and see if it's valid.
  if (_.isString(move)) {
    return !_.isNil(chess.move(`${ move }=Q`, { sloppy: true }));
  }

  // If the move already has a promotion, it can't be a partial promotion.
  if (_.has(move, "promotion")) {
    return false;
  }

  // If the move doesn't have a promotion, attempt to add one to it and see if it's valid.
  let result = chess.move({ ...move, promotion: pieceToChessJSPiece(QUEEN) }, { sloppy: true });
  return _.has(result, "promotion");
}

/**
 * Returns the FEN for a promotion with the promotion piece removed. This method does *not* check to
 * see if the move is a partial promotion.
 */
export function uncompletedPromotionFen(fen, move) {
  let chess = new Chess(fen);

  move = _.isString(move)
    ? `${ move }=Q`
    : { ...move, promotion: pieceToChessJSPiece(QUEEN) };

  // Make the move to determine the target square.
  let { to } = chess.move(move);

  // Replace the target square with a pawn.
  chess.remove(to);

  // Return the resulting FEN.
  return chess.fen();
}
