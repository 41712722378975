import { observer } from "mobx-react";
import PropTypes from "prop-types";
import React, { useState } from "react";
import classNames from "classnames";

import { Button } from "./button";

// TODO: Should scrolling be prevented when background interaction is disabled?
export const Modal = observer(({
  className,
  children,
  open,
  title,
  contentClassName,
  closable,
  requiresInteraction,
  onClose
}) => {
  let [ closed, setClosed ] = useState(false);

  // If the modal is set to not open and it's already been closed by the user, reset the closed
  // property so it can be opened again later.
  if (!open && closed) {
    setClosed(false);
  }

  function close() {
    setClosed(true);

    if (onClose) {
      onClose();
    }
  }

  function handleBackgroundClick(event) {
    if (!requiresInteraction) {
      return;
    }

    event.stopPropagation();
    event.preventDefault();

    close();
  }

  function handleContainerClick(event) {
    event.stopPropagation();
    event.preventDefault();
  }

  return <div
    className={
      classNames(
        "modal",
        className,
        {
          "modal--open": open && !closed,
          "modal--requires-interaction": requiresInteraction
        }
      )
    }
    onClick={ handleBackgroundClick }
  >
    <div className="modal__container" onClick={ handleContainerClick }>
      {
        closable
          ? <Button
            className="modal__close"
            iconClassName="modal__close-icon"
            icon="times"
            title="Close"
            onClick={ () => close() }
            treatment="clear"
          />
          : null
      }
      <h3 className="modal__title">{ title }</h3>
      <div className={ classNames("modal__content", contentClassName) }>
        { children }
      </div>
    </div>
  </div>;
});

Modal.displayName = "Modal";

Modal.defaultProps = {
  closable: true,
  requiresInteraction: false
};

Modal.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  contentClassName: PropTypes.string,
  closable: PropTypes.bool,
  requiresInteraction: PropTypes.bool,
  onClose: PropTypes.func
};
