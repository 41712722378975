import _ from "lodash";

import { makeSubclassAutoObservable } from "../utilities/make-subclass-auto-observable";
import { assignIfChanged } from "../utilities/assign-if-changed";

/**
 * This is the base class for stores. It mainly handles validation.
 */
export class BaseStore {

  /**
   * Initializes the store. Ideally, this would be done in a constructor. However,
   * JavaScript initializes the properties of a subclass *after* super is called, so any properties
   * assigned in the subclass are automatically wiped out. To get around this limitation, this
   * method should be called in subclass's constructor.
   */
  initialize(attributes = {}) {

    // Ensure the attributes array is defined.
    if (!_.isArray(this.constructor.ATTRIBUTES)) {
      throw new Error("You must define the ATTRIBUTES static property on your store class!");
    }

    // Ensure the VALIDATORS object is defined.
    if (!_.isObject(this.constructor.VALIDATORS)) {
      throw new Error("You must define the VALIDATORS static property on your store class!");
    }

    // Automatically annotate all of the object's properties
    makeSubclassAutoObservable(this);

    // Assign the initial values for the attributes.
    this.attributes = attributes;
  }

  /**
   * Returns a simple object of the attributes in this store.
   */
  get attributes() {
    return _.pick(this, this.constructor.ATTRIBUTES);
  }

  /**
   * Assigns the attributes in the provided object to this store.
   */
  set attributes(attributes) {
    if (!_.isObject(attributes)) {
      throw new Error("The attributes setter must be called with an object.");
    }

    let invalidAttributes = _.difference(
      _.keys(attributes),
      this.constructor.ATTRIBUTES
    );

    if (!_.isEmpty(invalidAttributes)) {
      throw new Error(
        `The attributes object contains invalid attributes: ${ invalidAttributes.join(", ") }.`
      );
    }

    assignIfChanged(this, attributes);
  }

  /**
   * Returns an object whose keys are the attributes on the store and values are arrays containing
   * the store's errors.
   */
  get errors() {
    return _.fromPairs(_.map(this._errorAttributes, attribute => {
      let validators = _.castArray(_.get(this.constructor.VALIDATORS, attribute, []));

      return [
        attribute,
        _.compact(_.map(validators, validator => validator(this[attribute], attribute, this)))
      ];
    }));
  }

  /**
   * Returns an object whose keys are the store's attributes and whose values are true or false
   * depending on whether their attribute is valid.
   */
  get validations() {
    return _.mapValues(this.errors, errors => _.isEmpty(errors));
  }

  /**
   * Returns true if the store is valid.
   */
  get isValid() {
    return _.isEmpty(_.flatten(_.values(this.errors)));
  }

  /**
   * Returns a memoized copy of the attributes in the model, including the validators.
   */
  get _errorAttributes() {
    return _.uniq([ ...this.constructor.ATTRIBUTES, ..._.keys(this.constructor.VALIDATORS) ]);
  }
}
