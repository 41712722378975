import { RouterLink } from "mobx-state-router";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import React, { useState } from "react";
import classNames from "classnames";

import { Button } from "../shared/button";
import {
  CONTACT_ROUTE,
  ROOT_ROUTE,
  CREATED_LESSONS_ROUTE,
  LESSONS_HISTORY_ROUTE,
  LIKED_LESSONS_ROUTE,
  LOG_IN_ROUTE,
  POPULAR_LESSONS_ROUTE,
  SIGN_UP_ROUTE
} from "../../stores/routes";
import { Icon } from "../shared/icon";
import { MenuOpenButton } from "../shared/menu-open-button";
import { useStore } from "../../stores/store-provider";
import { useDesktopPrimaryNavigationMediaQuery } from "../../hooks/media-queries";
import { Portal } from "react-portal";

const PrimaryNavigationItems = observer(({ open, setOpen }) => {
  let isDesktopPrimaryNavigation = useDesktopPrimaryNavigationMediaQuery();
  let session = useStore("session");
  let router = useStore("router");

  function handleClose() {
    setOpen(false);
  }

  function handleLogOut(event) {
    event.stopPropagation();
    event.preventDefault();
    setOpen(false);
    session.logOut();
    router.goTo(LOG_IN_ROUTE);
  }

  return <div
    className={
      classNames(
        "primary-navigation__items",
        { "primary-navigation__items--open": open }
      )
    }
  >
    {
      isDesktopPrimaryNavigation
        ? null
        : <RouterLink
          className="primary-navigation__link"
          routeName={ ROOT_ROUTE }
          onClick={ handleClose }
        >
          <Icon className="primary-navigation__icon" name="home" />
          Home
        </RouterLink>
    }

    <RouterLink
      className="primary-navigation__link"
      routeName={ POPULAR_LESSONS_ROUTE }
      onClick={ handleClose }
    >
      <Icon
        className="primary-navigation__icon primary-navigation__icon--popular"
        name="star"
      />
      Popular
    </RouterLink>

    {
      session.isLoggedIn
        ? <RouterLink
          className="primary-navigation__link"
          routeName={ CREATED_LESSONS_ROUTE }
          onClick={ handleClose }
        >
          <Icon className="primary-navigation__icon" name="plus" />
          Created
        </RouterLink>
        : null
    }

    {
      session.isLoggedIn
        ? <RouterLink
          className="primary-navigation__link"
          routeName={ LESSONS_HISTORY_ROUTE }
          onClick={ handleClose }
        >
          <Icon className="primary-navigation__icon" name="history" />
          History
        </RouterLink>
        : null
    }

    {
      session.isLoggedIn
        ? <RouterLink
          className="primary-navigation__link"
          routeName={ LIKED_LESSONS_ROUTE }
          onClick={ handleClose }
        >
          <Icon className="primary-navigation__icon" name="thumbsUp" />
          Liked
        </RouterLink>
        : null
    }

    <RouterLink
      className="primary-navigation__link"
      routeName={ CONTACT_ROUTE }
      onClick={ handleClose }
    >
      <Icon className="primary-navigation__icon" name="envelope" />
      Contact
    </RouterLink>

    {
      session.isLoggedIn

      // TODO: Replace this with a proper button.
        ? <a className="primary-navigation__link" onClick={ handleLogOut } href="#">
          <Icon className="primary-navigation__icon" name="signOut" />
          Log Out
        </a>
        : <RouterLink
          className="primary-navigation__link"
          routeName={ LOG_IN_ROUTE }
          onClick={ handleClose }
        >
          <Icon className="primary-navigation__icon" name="signIn" />
          Log In
        </RouterLink>
    }

    {
      session.isLoggedIn
        ? null
        : <span className="primary-navigation__link">
          <Button
            className="primary-navigation__button"
            routeName={ SIGN_UP_ROUTE }
            iconClassName="primary-navigation__button-icon"
            icon="user"
            text="Sign Up"
            treatment="primary"
            onClick={ handleClose }
          />
        </span>
    }
  </div>;
});

PrimaryNavigationItems.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired
};

PrimaryNavigationItems.displayName = "PrimaryNavigationItems";

function ResponsivePrimaryNavigation({ children }) {
  let isDesktopPrimaryNavigation = useDesktopPrimaryNavigationMediaQuery();

  if (isDesktopPrimaryNavigation) {
    return children;
  }

  return <Portal>{ children }</Portal>;
}

ResponsivePrimaryNavigation.propTypes = {
  children: PropTypes.node.isRequired
};

export function PrimaryNavigation({ className }) {
  let [ open, setOpen ] = useState(false);

  function toggleOpen() {
    setOpen((oldOpen) => !oldOpen);
  }

  return <nav className={ classNames("primary-navigation", className) }>
    <MenuOpenButton
      className="primary-navigation__open-button"
      onClick={ toggleOpen }
      type="bars"
    />

    <h1 className="primary-navigation__title">
      <a className="primary-navigation__link" href="/">
        <Icon className="primary-navigation__icon" name="logoFilledCircle" filled={ false } />
        Chessercise
        <span className="primary-navigation__beta">Beta</span>
      </a>
    </h1>

    <ResponsivePrimaryNavigation>
      <PrimaryNavigationItems open={ open } setOpen={ setOpen } />
    </ResponsivePrimaryNavigation>
  </nav>;
}

PrimaryNavigation.propTypes = {
  className: PropTypes.string
};
