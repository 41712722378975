import _ from "lodash";
import { isFenValid } from "../utilities/fen";

const BOTH_KINGS_MISSING_MESSAGE = "Must include kings";
const WHITE_KING_MISSING_MESSAGE = "Must include white king";
const BLACK_KING_MISSING_MESSAGE = "Must include black king";
const TOO_MANY_WHITE_KINGS_MESSAGE = "Too many white kings";
const TOO_MANY_BLACK_KINGS_MESSAGE = "Too many black kings";

// TODO: Replace this with the functional pipeline operator.
const letterCounts = _.flow(
  (fen) => fen.split(" ")[0],
  _.countBy,
  counts => _.merge({ k: 0, K: 0 }, counts)
);

/**
 * Validates that the given FEN contains the proper number of kings.
 * @return Returns null if the value is valid or an error string if it isn't.
 */
export function validateFenKings(fen) {

  // Don't bother to validate invalid FENs
  if (!isFenValid(fen)) {
    return null;
  }

  // Determine the number of each letter in the FEN.
  let counts = letterCounts(fen);

  // Both kings missing
  if (counts["k"] === 0 && counts["K"] === 0) {
    return BOTH_KINGS_MISSING_MESSAGE;
  }

  // White king missing
  if (counts["K"] === 0) {
    return WHITE_KING_MISSING_MESSAGE;
  }

  // Black king missing
  if (counts["k"] === 0) {
    return BLACK_KING_MISSING_MESSAGE;
  }

  // Too many white kings
  if (counts["K"] > 1) {
    return TOO_MANY_WHITE_KINGS_MESSAGE;
  }

  // Too many black kings
  if (counts["k"] > 1) {
    return TOO_MANY_BLACK_KINGS_MESSAGE;
  }

  // The FEN has the correct number of kings!
  return null;
}
