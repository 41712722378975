import { observer } from "mobx-react";
import React from "react";
import _ from "lodash";

import { Blurb } from "../shared/blurb";
import { Button } from "../shared/button";
import {
  CREATED_LESSONS_ROUTE,
  LESSONS_HISTORY_ROUTE,
  LIKED_LESSONS_ROUTE,
  NEW_LESSON_ROUTE,
  POPULAR_LESSONS_ROUTE
} from "../../stores/routes";
import {
  EmptyCreatedLessonsMessage,
  EmptyLikedLessonsMessage,
  EmptyPlayedLessonsMessage,
  EmptyPopularLessonsMessage
} from "../lesson/empty-lessons-messages";
import { LessonList } from "../lesson/lesson-list";
import { useStore } from "../../stores/store-provider";

const EmptyLessonsBlurb = observer(() => {
  let { routeName } = useStore("routerParams");

  if (routeName === CREATED_LESSONS_ROUTE) {
    let message = <>
      <EmptyCreatedLessonsMessage />
      Give it a shot! Creating lessons is quick and easy.
    </>;

    return <Blurb className="lessons-index-page__blurb" icon="pawn" message={ message }>
      <Button treatment="primary" routeName={ NEW_LESSON_ROUTE } text="New Lesson" />
    </Blurb>;
  }

  let blurbMessage = {
    [LESSONS_HISTORY_ROUTE]: <EmptyPlayedLessonsMessage />,
    [LIKED_LESSONS_ROUTE]: <EmptyLikedLessonsMessage />,
    [POPULAR_LESSONS_ROUTE]: <EmptyPopularLessonsMessage />
  }[routeName];

  if (_.isNil(blurbMessage)) {
    return null;
  }

  return <Blurb className="lessons-index-page__blurb" icon="pawn" message={ blurbMessage } />;
});

EmptyLessonsBlurb.displayName = "EmptyLessonsBlurb";

/**
 * This page is a bit overloaded. Because so many pages are similar, this page is used in multiple
 * locations, and makes slight alterations to its content based on what's being displayed. In the
 * future, this will probably need to be broken up more.
 */
export const LessonsIndexPage = observer(() => {
  let { lessons } = useStore("routerParams");

  if (_.isNil(lessons)) {
    return null;
  }

  return <div className="lessons-index-page">
    { _.isEmpty(lessons) ? <EmptyLessonsBlurb /> : null }
    <LessonList className="lessons-index-page__lessons" lessons={ lessons } />
  </div>;
});

LessonsIndexPage.displayName = "LessonsIndexPage";
