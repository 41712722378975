import { makeObservable, isObservableProp, isAction } from "mobx";
import _ from "lodash";

function isTopLevelObjectPrototype(target) {
  return _.isNil(target) || target === Object.prototype;
}

// Recursively determines if the give property is a getter in the prototype chain of the target.
function isGetter(target, property) {
  if (isTopLevelObjectPrototype(target)) {
    return false;
  }

  return !_.isNil(Object.getOwnPropertyDescriptor(target, property)?.get)
    || isGetter(Object.getPrototypeOf(target), property);
}

// Returns true if the target property is annotated by MobX.
function isMobxAnnotatedProperty(target, property) {
  return _.isSymbol(property) && property.toString().includes("mobx")
      || isObservableProp(target, property)
      || !isGetter(target, property) && isAction(target[property]);
}

/**
 * This purposefully-limited version of `makeAutoObservable` that supports subclasses.
 *
 * There is valid complexity in supporting `makeAutoObservable` across disparate/edge-casey class
 * hierarchies, and so mobx doesn't support it out of the box.
 *
 * So this implementation adds a few limitations that lets us get away with it. Specifically:
 *
 * - We always auto-infer a key's action/computed/observable, and don't support user-provided config
 *   values
 * - Subclasses should not override parent class methods (although this might? work)
 * - Only the "most child" subclass should call `makeSimpleAutoObservable`, to avoid each
 *   constructor in the inheritance chain potentially re-decorating keys.
 */
export function makeSubclassAutoObservable(target, prototype = target) {

  // If the target is null, we know we've hit the end of the prototype chain.
  if (isTopLevelObjectPrototype(prototype)) {
    return null;
  }

  // Grab the attributes, but exclude the constructor and any properties that are already
  // observable.
  let attributes = _.reject(Reflect.ownKeys(prototype), property => {
    return property === "constructor" || isMobxAnnotatedProperty(target, property);
  });

  // Convert the attribute to an object whose values are true. If a value is true, MobX
  // automatically infers the attribute's value.
  let annotations = _.fromPairs(attributes.map(attribute => [ attribute, true ]));

  // Make the attributes observable.
  makeObservable(target, annotations);

  // Recursively make the prototypes observable as well.
  makeSubclassAutoObservable(target, Object.getPrototypeOf(prototype));
}
