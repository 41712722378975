import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";
import classNames from "classnames";

import { Icon } from "./icon";

export function Blurb({ className, icon, message, children }) {

  return <div className={ classNames("blurb", className) }>
    {
      _.isNil(icon)
        ? null
        : <Icon className="blurb__icon" name={ icon } />
    }

    <p className="blurb__message">
      { message }
    </p>

    {
      _.isNil(children)
        ? null
        : <div className="blurb__actions">{ children }</div>
    }
  </div>;
}

Blurb.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  message: PropTypes.string.isRequired,
  children: PropTypes.node
};
