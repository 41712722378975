import _ from "lodash";
import { shuffle } from "shuffle-seed";

import { ExerciseStore } from "./exercise-store";
import { STATES, TRANSITION_TIME_LARGE } from "../../constants";
import { ModelStore } from "./model-store";
import { timer } from "../../utilities/timer";
import { validatePresence } from "../../validators/validate-presence";
import { validateUrl } from "../../validators/validate-url";
import { findIndexStartingAt, findLastIndexStartingAt } from "../../utilities/array";
import { apiClient } from "../../api/api-client";
import { maybeParseDate } from "../../utilities/date";

export const LIKED_VOTE = "like";
export const DISLIKED_VOTE = "dislike";
export const NEUTRAL_VOTE = null;

// Taken from: https://github.com/streamich/react-embed/
const TWITCH_URL_REGEX = /(?:www\.|go\.)?twitch\.tv\/videos\/(\d+)/;

// Taken from: https://stackoverflow.com/a/37704433/262125
const YOUTUBE_URL_REGEX = new RegExp(
  "^((?:https?:)?\\/\\/)?((?:www|m)\\.)?((?:youtube\\.com|youtu.be))(\\/(?:[\\w\\-]+\\?v=|embed"
    + "\\/|v\\/)?)([\\w\\-]+)(\\S+)?$"
);

/**
 * Returns an element at the index in the given array. If the index is out of bounds, this function
 * returns null. This is necessary because MobX prints a warning if the index is out of bounds.
 */
function safeGet(array, index) {
  if (index < 0 || index >= array.length) {
    return null;
  }

  return array[index];
}

export class LessonStore extends ModelStore {
  static RESOURCE_NAME = "lesson";

  static ATTRIBUTES = [
    "id",
    "title",
    "description",
    "sourceName",
    "sourceUrl",
    "sourceStartTime",
    "sourceEndTime",
    "vote",
    "numberOfLikes",
    "numberOfDislikes",
    "creatorId"
  ]

  static VALIDATORS = {
    title: validatePresence,
    description: validatePresence,
    sourceName: validatePresence,
    sourceUrl: [ validatePresence, validateUrl ]
  }

  // Attributes
  id = null
  title = null
  description = null
  sourceName = null
  sourceUrl = null
  sourceStartTime = null
  sourceEndTime = null
  published = true
  _publishedAt = null
  vote = NEUTRAL_VOTE
  numberOfLikes = 0
  numberOfDislikes = 0
  creatorId = null

  // Other properties
  _exercises = []
  shuffleSeed = null
  currentExerciseIndex = -1
  infoHidden = false

  constructor({ exercises, ...attributes } = {}) {
    super();
    this.initialize(attributes);
    this.exercises = exercises;
  }

  /**
   * Returns an object containing the lesson's attributes.
   */
  get attributes() {
    return super.attributes;
  }

  /**
   * Sets the attributes.
   */
  set attributes({ published, publishedAt, ...attributes }) {
    this.published = published;
    this.publishedAt = publishedAt;
    super.attributes = attributes;
  }

  /**
   * Returns all of the exercises for the lesson. If the lesson is shuffled, the exercises are
   * returned in a pseudo random order.
   */
  get exercises() {
    return _.isNil(this.shuffleSeed) ? this._exercises : shuffle(this._exercises, this.shuffleSeed);
  }

  /**
   * Sets the exercises for the lesson, creating ExerciseStores if necessary.
   */
  set exercises(exercises) {
    this._exercises = _.map(exercises, exercise => {
      return new ExerciseStore({ lessonId: this.id, ...exercise });
    });
  }

  /**
   * Returns the scores belonging to the exercises in this lesson.
   */
  get scores() {
    return _.map(this.exercises, "score");
  }

  /**
   * Returns the exercise currently being displayed.
   * HACK: Right now, the first page is indicated by using a `null` current exercise. The future,
   * this should be removed and the the "exercises" should be replaced with pages and a content
   * model.
   */
  get currentExercise() {
    return this.currentExerciseIndex === -1
      ? null
      : this.exercises[this.currentExerciseIndex];
  }

  /**
   * Returns true if there are any uncompleted exercises in the lesson besides the current one. In
   * other words, this method determines if the nextUncompletedExercise action should be enabled.
   *
   * NOTE: Previously this was split into hasNextUncompletedExercise and
   * hasPreviousUncompletedExercise methods. However, now that the next and previous buttons wrap,
   * these two methods are indentical, so this one replaces them both.
   */
  get hasUncompletedExercise() {
    let nextExerciseIndex = findIndexStartingAt(
      this.exercises,
      this.currentExerciseIndex + 1,
      exercise => !exercise.isCompleted
    );

    return nextExerciseIndex !== -1 && this.currentExerciseIndex !== nextExerciseIndex;
  }

  /**
   * Returns an object whose keys are the possible score states and values are the number of scores
   * scores with those states.
   */
  get scoreCounts() {
    // TODO: Replace this with the functional pipeline operator
    let rawCounts = _.countBy(_.map(this.scores, "state"));
    return _.fromPairs(STATES.map(state => [ state, rawCounts[state] || 0 ]));
  }

  /**
   * Returns the number of exercises that have been completed.
   */
  get numberOfCompletedExercises() {
    return _.filter(this.scores, "isCompleted").length;
  }

  /**
   * Returns the number of exercises completed perfectly.
   */
  get numberOfPerfects() {
    return _.filter(this.scores, "isPerfect").length;
  }

  /**
   * Returns the number of exercises completed perfectly.
   */
  get numberOfHints() {
    return _.filter(this.scores, "isHint").length;
  }

  /**
   * Returns the number of exercises completed perfectly.
   */
  get numberOfAssists() {
    return _.filter(this.scores, "isAssist").length;
  }

  /**
   * Returns the number of exercises completed perfectly.
   */
  get numberOfMistakes() {
    return _.filter(this.scores, "isMistake").length;
  }

  /**
   * Returns true if this lesson has been shuffled.
   */
  get isShuffled() {
    return !_.isNil(this.shuffleSeed);
  }

  /**
   * Returns true if the lesson has been started.
   */
  get isStarted() {
    return _.some(this.exercises, "isStarted");
  }

  /**
   * Returns true if the lesson has been completed.
   */
  get isCompleted() {
    return _.every(this.exercises, "isCompleted");
  }

  /**
   * Returns true if the lesson has exercises.
   */
  get hasExercises() {
    return this.exercises.length !== 0;
  }

  /**
   * Returns true if the source URL is twitch.
   */
  get isSourceUrlTwitch() {
    return _.isString(this.sourceUrl) && TWITCH_URL_REGEX.test(this.sourceUrl);
  }

  /**
   * Returns the ID of the Twitch video. If the URL is not a Twitch video, this returns null.
   */
  get twitchVideoId() {
    return this.isSourceUrlTwitch
      ? this.sourceUrl.match(TWITCH_URL_REGEX)[1]
      : null;
  }

  /**
   * Returns true if the source URL is YouTube.
   */
  get isSourceUrlYouTube() {
    return _.isString(this.sourceUrl) && YOUTUBE_URL_REGEX.test(this.sourceUrl);
  }

  /**
   * Returns the ID of the YouTube video. If the URL is not a Twitch video, this returns null.
   */
  get youTubeVideoId() {
    return this.isSourceUrlYouTube
      ? this.sourceUrl.match(YOUTUBE_URL_REGEX)[5]
      : null;
  }

  /**
   * Returns the published at date.
   */
  get publishedAt() {
    return this._publishedAt;
  }

  /**
   * Sets the published at date.
   */
  set publishedAt(publishedAt) {
    this._publishedAt = maybeParseDate(publishedAt);
  }

  /**
   * Returns the published at time formatted to the current locale.
   */
  get formattedPublishedAt() {
    if (_.isNil(this.publishedAt)) {
      return this.publishedAt;
    }

    let formatter = new Intl.DateTimeFormat(
      undefined,
      {
        year: "numeric",
        month: "long",
        day: "numeric"
      }
    );

    return formatter.format(this.publishedAt);
  }

  /**
   * Navigates to the previous exercise. If the exercise is the first exercise, navigates to the
   * last exercise.
   */
  previousExercise() {
    if (!this.hasExercises) {
      throw new Error("The lesson does not have any exercises.");
    }

    this.currentExercise?.updateNavigation();

    this.currentExerciseIndex = this.currentExerciseIndex - 1;

    if (this.currentExerciseIndex < -1) {
      this.currentExerciseIndex = this.exercises.length - 1;
    }
  }

  /**
   * Navigates to the next exercise. If the exercise is the last exercise, navigates to the first
   * exercise.
   */
  nextExercise() {
    if (!this.hasExercises) {
      throw new Error("The lesson does not have any exercises.");
    }

    this.currentExercise?.updateNavigation();

    this.currentExerciseIndex = this.currentExerciseIndex + 1;

    if (this.currentExerciseIndex >= this.exercises.length) {
      this.currentExerciseIndex = -1;
    }
  }

  /**
   * Navigates to the previous exercise that has not been completed.
   */
  previousUncompletedExercise() {
    if (!this.hasUncompletedExercise) {
      throw new Error("There is no next exercise.");
    }

    this.currentExercise?.updateNavigation();

    this.currentExerciseIndex = findLastIndexStartingAt(
      this.exercises,
      this.currentExerciseIndex - 1,
      exercise => !exercise.isCompleted
    );
  }

  /**
   * Navigates to the next exercise.
   */
  nextUncompletedExercise() {
    if (!this.hasUncompletedExercise) {
      throw new Error("There is no next exercise.");
    }

    this.currentExercise?.updateNavigation();

    this.currentExerciseIndex = findIndexStartingAt(
      this.exercises,
      this.currentExerciseIndex + 1,
      exercise => !exercise.isCompleted
    );
  }

  /**
   * Navigates to the next exercise after a delay.
   */
  async nextUncompletedExerciseAfterDelay() {
    await timer(TRANSITION_TIME_LARGE);
    this.nextUncompletedExercise();
  }

  /**
   * Navigates to the first incomplete exercise. If no exercises are incomplete, this method goes to
   * the first exercise.
   */
  goToFirstIncompleteExercise() {
    let index = this.exercises.findIndex(exercise => {
      return !exercise.isCompleted;
    });

    this.currentExerciseIndex = index === -1 ? -1 : index;
  }

  /**
   * Restarts all of the exercises in the lesson.
   */
  restartAll() {
    this.currentExerciseIndex = this.currentExerciseIndex === -1 ? -1 : 0;
    this.exercises.forEach(exercise => exercise.restart());
  }

  /**
   * Restarts all of the exercises that were not perfect in the current lesson.
   */
  restartMissed() {

    // Restart the missed exercises.
    this.exercises
      .filter(exercise => !exercise.score.isPerfect)
      .forEach(exercise => exercise.restart());

    // Go to the first restarted exercise.
    if (this.currentExerciseIndex !== -1) {
      this.goToFirstIncompleteExercise();
    }
  }

  /**
   * Toggles whether the exercises are shuffled or not.
   */
  toggleShuffle() {

    // Store the seed number to ensure the exercises are sorted the same way after any update.
    this.shuffleSeed = _.isNil(this.shuffleSeed) ? Math.random() : null;
  }

  /**
   * Sets the currently selected exercise to the exercise with the given ID.
   */
  goToExercise(exerciseId) {
    let index = _.findIndex(this.exercises, { id: exerciseId });

    if (index !== -1) {
      this.currentExerciseIndex = index;
    }
  }

  /**
   * Toggles the lesson's published status.
   */
  async togglePublished() {
    this.published = !this.published;
    this.publishedAt = this.published ? new Date() : null;

    // TODO: Call the API to publish the lesson.
    await apiClient.updateLesson({ id: this.id, published: this.published });
  }

  /**
   * Likes or neutralizes the lesson's vote.
   */
  async toggleLike() {
    if (this.vote === LIKED_VOTE) {
      this.vote = NEUTRAL_VOTE;
      this.numberOfLikes -= 1;
      return await apiClient.deleteVote({ lessonId: this.id });
    }

    if (this.vote === DISLIKED_VOTE) {
      this.numberOfDislikes -= 1;
    }

    this.numberOfLikes += 1;
    this.vote = LIKED_VOTE;
    await apiClient.upsertVote({ lessonId: this.id, like: true });
  }

  /**
   * Likes or neutralizes the lesson's vote.
   */
  async toggleDislike() {
    if (this.vote === DISLIKED_VOTE) {
      this.vote = NEUTRAL_VOTE;
      this.numberOfDislikes -= 1;
      return await apiClient.deleteVote({ lessonId: this.id });
    }

    if (this.vote === LIKED_VOTE) {
      this.numberOfLikes -= 1;
    }

    this.numberOfDislikes += 1;
    this.vote = DISLIKED_VOTE;
    await apiClient.upsertVote({ lessonId: this.id, like: false });
  }

  /**
   * Toggles whether the lesson's info is hidden or not.
   */
  toggleHideInfo() {
    this.infoHidden = !this.infoHidden;
  }

  /**
   * Clones the exercise with the given ID.
   */
  async cloneExercise(exerciseId) {
    let index = _.findIndex(this._exercises, exercise => exercise.id === exerciseId);

    if (index === -1) {
      throw new Error(`No exercise could be found with the ID '${ exerciseId }'.`);
    }

    let exercise = this._exercises[index];
    let nextExercise = index + 1 >= this._exercises.length ? null : this._exercises[index + 1];
    let clonedExercise = exercise.clone();

    // The exercise's order is determined by averaging the previous and next exercises. Since there
    // has to be an exercise to clone, and we always insert the cloned exercise *after* the original
    // exercise, we only need to check to see if there is a next exercise. If so, then the order is
    // the average of the two exercises' orders. If not, then it's the previous order + 1.
    clonedExercise.order = _.isNil(nextExercise)
      ? exercise.order + 1
      : (exercise.order + nextExercise.order) / 2;

    this._exercises.splice(index + 1, 0, clonedExercise);

    return await clonedExercise.save();
  }

  /**
   * Moves an exercise from one place to another in the exercises array. The `toIndex` is the index
   * the exercise would have *if the exercise were cloned instead of moved*.
   *
   * The movement implementation of this method is based on the examples provided by
   * react-beautiful-dnd. This ensures the methods is compatible with the library.
   */
  async moveExercise(fromIndex, toIndex) {
    if (fromIndex < 0 || fromIndex >= this._exercises.length) {
      throw new Error(`The fromIndex ${ fromIndex } is out of bounds.`);
    }

    if (toIndex < 0 || toIndex >= this._exercises.length) {
      throw new Error(`The toIndex ${ toIndex } is out of bounds.`);
    }

    // Remove the exercise from the array.
    let [ exercise ] = this._exercises.splice(fromIndex, 1);

    // Grab the exercises preceding and following the exercise
    let previousExercise = safeGet(this._exercises, toIndex - 1);
    let nextExercise = safeGet(this._exercises, toIndex);

    // Place the exercise back into the array
    this._exercises.splice(toIndex, 0, exercise);

    // Update the exercise's order
    if (!_.isNil(previousExercise) && _.isNil(nextExercise)) {
      exercise.order = previousExercise.order + 1;
    }
    else if (_.isNil(previousExercise) && !_.isNil(nextExercise)) {
      exercise.order = nextExercise.order - 1;
    }
    else if (!_.isNil(previousExercise) && !_.isNil(nextExercise)) {
      exercise.order = (previousExercise.order + nextExercise.order) / 2;
    }

    // Save the result
    await exercise.save();
  }

  /**
   * Destroys the exercise with the given ID.
   */
  async destroyExercise(exerciseId) {
    let index = _.findIndex(this._exercises, exercise => exercise.id === exerciseId);
    let exercise = this._exercises[index];

    if (index === -1) {
      throw new Error(`No exercise could be found with the ID '${ exerciseId }'.`);
    }

    this._exercises.splice(index, 1);
    await exercise.destroy();
  }
}
