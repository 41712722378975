import _ from "lodash";
import { padArrayStart } from "./array";

const DURATION_REGEX = /^(\d+:)?(\d+:)?(\d+)$/;

/**
 * Given a string value, this parses the value as a duration and returns the duration length in
 * seconds. If the value can't be parsed, this method returns null.
 */
export function parseDuration(value) {
  if (!_.isString(value)) {
    return value;
  }

  // Remove leading and trailing colons
  value = value.replace(/^:/, "").replace(/:$/, "");

  if (!DURATION_REGEX.test(value)) {
    return null;
  }

  // TODO: Replace this with the smart pipeline operator.
  let times = _.flow(
    duration => duration.split(":"),
    duration => duration.map(time => parseInt(time, 10)),
    duration => padArrayStart(duration, 3, 0)
  )(value);

  return times[0] * 60 * 60 + times[1] * 60 + times[2];
}

/**
 * Given a number, this function pretty prints the number as a duration string.
 */
export function prettyPrintDuration(value) {
  if (!_.isNumber(value) || value < 0) {
    return "";
  }

  let hours = Math.floor(value / (60 * 60));
  let minutes = Math.floor(value % (60 * 60) / 60);
  let seconds = Math.floor(value % 60);

  // Populate the array with the appropriate number of values.
  let times = hours === 0
    ? [ minutes, seconds ]
    : [ hours, minutes, seconds ];

  // Pad any of the tail times.
  times = times.map((time, index) => {
    if (index === 0) {
      return time;
    }

    return _.padStart(time, 2, "0");
  });

  // Join the result with colons
  return times.join(":");
}
