import { observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";
import classNames from "classnames";

import { Controls } from "./controls";
import { Button } from "../shared/button";
import { useStore } from "../../stores/store-provider";
import { VoteButton } from "../lesson/vote-button";
import { EDIT_LESSON_ROUTE } from "../../stores/routes";
import { DISLIKED_VOTE, LIKED_VOTE } from "../../stores/models/lesson-store";
import { useDesktopMediaQuery } from "../../hooks/media-queries";
import { ShareLessonButton } from "../shared/share-lesson-button";

export const LessonControls = observer(({ className }) => {
  let isDesktop = useDesktopMediaQuery();
  let { lesson } = useStore("routerParams");
  let { user } = useStore("session");

  if (_.isNil(lesson)) {
    return null;
  }

  return <Controls className={ classNames("lesson-controls", className) }>
    <ShareLessonButton lesson={ lesson } treatment="clear" />
    <VoteButton lesson={ lesson } type={ LIKED_VOTE } />
    <VoteButton lesson={ lesson } type={ DISLIKED_VOTE } />
    {
      lesson.creatorId === user?.id && isDesktop
        ? <Button
          icon="pencil"
          text="Edit"
          routeName={ EDIT_LESSON_ROUTE }
          params={ { lessonId: lesson.id } }
          treatment="clear"
        />
        : null
    }
    <Button
      className="lesson-controls__practice"
      text="Practice"
      icon="chessKnight"
      onClick={ () => lesson.nextExercise() }
      treatment="primary"
    />
  </Controls>;
});

LessonControls.displayName = "LessonControls";

LessonControls.propTypes = {
  className: PropTypes.string
};
