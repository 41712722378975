import { observer } from "mobx-react";
import React from "react";

import { Button } from "../shared/button";
import { IconModal } from "../shared/icon-modal";
import { RainingChessPieces } from "../authentication/raining-chess-pieces";

export const ContactPage = observer(() => {
  return <>
    <RainingChessPieces />

    <IconModal
      title="Contact"
      open
      closable={ false }
      icon="envelopeFilledCircle"
      text={
        "Questions? Comments? Concerns? Bugs? Just want to say hi? Donʼt hesitate to reach out!"
      }
    >

      <Button
        treatment="primary"
        text="Email"
        // FIX: For some reason, this won't open as a regular href ¯\_(ツ)_/¯.
        onClick={ () => window.open("mailto:hello@chessercise.xyz").focus() }
      />
    </IconModal>
  </>;
});

ContactPage.displayName = "ContactPage";
