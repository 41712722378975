import { observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";
import classNames from "classnames";

import { Button } from "../shared/button";
import { Controls } from "../controls/controls";
import { EDIT_EXERCISE_ROUTE, EDIT_LESSON_ROUTE } from "../../stores/routes";
import { useStore } from "../../stores/store-provider";

export const EditExerciseSidebarControls = observer(({ className }) => {
  let router = useStore("router");
  let { lesson, exercise } = useStore("routerParams");

  if (_.isNil(exercise) || _.isNil(lesson)) {
    return null;
  }

  async function handleSave() {

    // Save the exercise
    await exercise.save();

    // If the exercise isn't valid, we know the save wasn't successful.
    if (!exercise.isValid) {
      return;
    }

    // Add the exercise to the lesson's exercises.
    lesson.exercises.push(exercise);

    // Update the route to be the edit exercise route
    router.goTo(EDIT_EXERCISE_ROUTE, {
      replaceHistory: true,
      params: { lessonId: exercise.lessonId, exerciseId: exercise.id }
    });
  }

  return <Controls className={ className }>
    <Button
      className={
        classNames({
          "controls__button--error": exercise.lastSaveFailed && !exercise.isValid
        })
      }
      icon="save"
      text="Save"
      onClick={ handleSave }
      disabled={ !exercise.isDirty }
      treatment="clear"
    />
    <Button
      icon="arrowLeft"
      text="Back"
      routeName={ EDIT_LESSON_ROUTE }
      params={ { lessonId: exercise.lessonId } }
      treatment="clear"
    />
  </Controls>;
});

EditExerciseSidebarControls.displayName = "EditExerciseSidebarControls";

EditExerciseSidebarControls.propTypes = {
  className: PropTypes.string
};
