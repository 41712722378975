
import React from 'react';


export const attributes = {};
export default function() {
  return (
    <div>
      <h1>Copyright Infringement Claims</h1>
<p><em>Last updated: June 9, 2021</em></p>
<h2>Notification of Copyright Infringement Claims</h2>
<p>Making original work is hard! As described in our <a href="../abuse/index.md">Use Restrictions policy</a>, you
can’t use Chessercise to make or disseminate work that uses the intellectual property of others
beyond the bounds of <a href="https://www.copyright.gov/fair-use/more-info.html">fair use</a>.</p>
<p>Are you a copyright owner? Under the Digital Millennium Copyright Act (17 U.S.C. § 512), you have
the right to notify us (Landon Schropp, LLC) if you believe that an account user of any product we
built and maintain has infringed on your work(s) as copyright owner. To be effective, the
notification of claimed infringement must be written. Please include the following information:</p>
<ul>
<li>A physical or electronic signature of a person authorized to act on behalf of the owner of an
exclusive right that is allegedly infringed.</li>
<li>Identification of the copyrighted work(s) claimed to have been infringed. If there are multiple,
please share a representative list of those works.</li>
<li>A way for us to locate the material you believe is infringing the copyrighted work.</li>
<li>Your name and contact information so that we can get back to you. Email address is preferred but a
telephone number or mailing address works too.</li>
<li>A statement that you, in good faith, believe that use of the material in the manner complained of
is not authorized by the copyright owner, its agent, or the law.</li>
<li>A statement that the information in the notification is accurate, and under penalty of perjury,
that you are authorized to act on behalf of the owner of an exclusive right that is allegedly
infringed.</li>
</ul>
<h2>Digital Millennium Copyright Act (“DCMA”) Counter-notifications</h2>
<p>On the flip-side, if you believe your material has been removed in error, you can file a written
counter-notification. Please include the following information:</p>
<ul>
<li>A physical or electronic signature, or the signature of the person authorized to act on your
behalf.</li>
<li>A description of the material that was removed.</li>
<li>A description of where the material appeared in Chessercise product prior to their removal.</li>
<li>Your name and contact information so that we can get back to you. Email address is preferred but a
telephone number or mailing address works too.</li>
<li>A statement under penalty of perjury that you have a good faith belief that the material was
removed or disabled as a result of mistake or misidentification.</li>
<li>A statement that you consent to the jurisdiction of the Federal District Court for the judicial
district in which your address is located, or if your address is outside of the United States, in
the Western District of Missouri (where Landon Schropp, LLC is located).</li>
<li>A statement that you will accept service of process from the person who filed the original DMCA
notice or an agent of that person. (In other words, you’ve designated that person to receive
documents on your behalf.)</li>
</ul>
<h2>Where to Send Notices</h2>
<p>You can notify us of either copyright infringement claims or DCMA counter-notifications through
either of the following channels:</p>
<p><strong>By email</strong>: <a href="mailto:hello@chessercise.xyz">hello@chessercise.xyz</a></p>
<p><strong>By mail</strong>: Landon Schropp, LLC
6732 N Mercier St
Kansas City, MO 64118 USA</p>
<p><strong>This policy and process applies to any product created and owned by Landon Schropp, LLC.</strong></p>
<h2>Attribution</h2>
<p>This policy was adapted from open source legal documents from <a href="https://github.com/Automattic/legalmattic">Automattic
Legalmattic</a> under the <a href="https://creativecommons.org/licenses/by-sa/4.0/">CC BY-SA
4.0</a> license and the <a href="https://github.com/basecamp/policies">Basecamp open-source
policies</a> under the <a href="https://creativecommons.org/licenses/by/4.0/">CC BY
4.0</a> license.</p>

    </div>
  );
};