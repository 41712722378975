import _ from "lodash";

import {
  ASSIST_STATE,
  HINT_STATE,
  MISTAKE_STATE,
  TRANSITION_TIME_MEDIUM,
  TRANSITION_TIME_XSMALL,
  UNSOLVED_STATE,
  WHITE
} from "../../constants";
import { Chessboard } from "../../utilities/chessboard";
import { ChessboardStore } from "../chessboard-store";
import { ModelStore } from "./model-store";
import { ScoreStore } from "./score-store";
import { findInvalidMoveIndex, sanitizeMoves } from "../../utilities/moves";
import { isFenValid } from "../../utilities/fen";
import { timer } from "../../utilities/timer";
import { validateNotEmpty } from "../../validators/validate-not-empty";
import { validatePresence } from "../../validators/validate-presence";
import { validateFenKings } from "../../validators/validate-fen-kings";

export const DETAILS_STATE = "details";
export const POSITION_STATE = "position";

/**
 * Returns true if the starting position is a valid FEN.
 */
function validateStartingPosition(startingPosition) {
  return isFenValid(startingPosition) ? null : "Not a valid FEN";
}

/**
 * Returns true if the starting position is a valid FEN.
 */
function validateMoves(moves, attribute, exercise) {
  let index = exercise.invalidMoveIndex;

  return _.isNil(index)
    ? null
    : `Invalid move '${ moves[index] }' at index ${ index }`;
}

/**
 * This is a store for practicing and editing exercises.
 *
 * There are a few important index properties of this store that can be a little confusing at first
 * glance.
 *
 * * moveIndex: The index of the move that's *attempting* to be set. If no moves have been made,
 *   then the value of the move index is -1. This helps keep the move index useful with the
 *   moves array.
 * * completedMoveIndex: This is the furthest valid move the player has made. This keeps the player
 *   from advancing beyond the moves they've made.
 * * autoMoveIndex: This is the index the moves should automatically be played to.
 * * invalidMoveIndex The index of the first invalid move in the moves array.
 */
export class ExerciseStore extends ModelStore {
  static RESOURCE_NAME = "exercise";

  static ATTRIBUTES = [
    "id",
    "autoMoveIndex",
    "lessonId",
    "description",
    "moves",
    "startingPosition",
    "title",
    "order",
    "player"
  ]

  static VALIDATORS = {
    lessonId: validatePresence,
    description: validatePresence,
    moves: [ validatePresence, validateMoves, validateNotEmpty ],
    startingPosition: [ validatePresence, validateFenKings, validateStartingPosition ],
    title: validatePresence,
    order: validatePresence,
    player: validatePresence,
    autoMoveIndex: validatePresence
  }

  // The model attributes
  id = null
  autoMoveIndex = -1
  lessonId = null
  description = ""
  moves = []
  title = ""
  order = null
  player = WHITE

  // Other properties
  _score = null
  chessboard = null
  editState = DETAILS_STATE
  moveIndex = -1
  _completedMoveIndex = -1
  hasNavigatedSinceCompletion = false

  constructor(attributes) {
    super();

    this.chessboard = new ChessboardStore({ startingPosition: attributes.startingPosition });
    this.initialize({ score: {}, ...attributes });

    if (this.score.isCompleted) {
      this.goToLastMove();
    }
  }

  // If we override the attributes setter, we also have to override the attributes getter.
  get attributes() {
    return super.attributes;
  }

  // Override the attributes setter to assign the score.
  set attributes(attributes) {
    super.attributes = _.omit(attributes, "score");
    Object.assign(this, _.pick(attributes, "score"));
  }

  /**
   * Returns true if it's the first player's turn.
   */
  get isPlayerTurn() {
    let playerStarts = this.player === this.chessboard.startingPlayer;
    return (this.chessboard.history.length + (playerStarts ? 0 : 1)) % 2 === 0;
  }

  /**
   * Returns true if it's the second player's turn.
   */
  get isComputerTurn() {
    return !this.isPlayerTurn;
  }

  get completedMoveIndex() {
    return this._completedMoveIndex;
  }

  set completedMoveIndex(completedMoveIndex) {
    if (completedMoveIndex > this._completedMoveIndex) {
      this._completedMoveIndex = completedMoveIndex;
    }
  }

  get score() {
    return this._score;
  }

  set score(score) {
    score = _.isNil(score) ? {} : score;
    this._score = new ScoreStore({ exerciseId: this.id, ...score });
  }

  get startingPosition() {
    return this.chessboard.startingPosition;
  }

  set startingPosition(startingPosition) {
    this.chessboard.startingPosition = startingPosition;
  }

  /**
   * Returns the move index of the first invalid move. If all of the moves are valid, this method
   * returns null.
   */
  get invalidMoveIndex() {
    return findInvalidMoveIndex(this.startingPosition, this.moves);
  }

  /**
   * Returns verbose versions of the moves in the exercise.
   */
  get verboseMoves() {
    let chessboard = new Chessboard({ startingPosition: this.startingPosition });
    this.moves.forEach(move => chessboard.move(move));
    return chessboard.history;
  }

  /**
   * Returns the next move in the sequence, or null if there is no next move.
   */
  get nextVerboseMove() {
    return _.get(this.verboseMoves, this.chessboard.numberOfMoves, null);
  }

  /**
   * Returns true if the exercise has a previous move.
   */
  get hasPreviousMove() {
    return this.moveIndex !== -1;
  }

  /**
   * Returns true if the exercise has a next move.
   */
  get hasNextMove() {
    return this.moveIndex !== this.moves.length - 1;
  }

  /**
   * Returns true if the exercise has a next move that has been completed.
   */
  get hasNextCompletedMove() {
    return this.moveIndex < this.completedMoveIndex;
  }

  /**
   * Returns true if the move index is the latest completed move.
   */
  get isLatestCompletedMoveIndex() {
    return this.moveIndex === this.completedMoveIndex;
  }

  /**
   * Returns true if the next move to be made is an auto move.
   */
  get isNextMoveAutoMove() {
    // Don't auto move if the exercise is complete.
    if (this.isCompleted) {
      return false;
    }

    // Don't auto move if the user has navigated backwards.
    if (!this.isLatestCompletedMoveIndex) {
      return false;
    }

    // Always auto move if it's the computer's turn.
    if (this.isComputerTurn) {
      return true;
    }

    // If we've reached this point, only auto move if the current move is less than the auto move
    // index.
    return this.moveIndex < this.autoMoveIndex;
  }

  /**
   * Returns true if the current move is an auto move.
   */
  get isAutoMove() {
    return this.moveIndex - 1 < this.autoMoveIndex || this.isPlayerTurn;
  }

  /**
   * Determines if the exercise has been started.
   */
  get isStarted() {
    return this.score.isStarted;
  }

  /**
   * Determines if the exercise has been completed.
   */
  get isCompleted() {
    return this.score.isCompleted;
  }

  /**
   * Indicates whether the current move is a hint or not.
   */
  get currentMoveHint() {
    return !_.isNil(this.chessboard.hint) && _.isNil(this.chessboard.assist);
  }

  /**
   * Indicates whether the current move is an assist or not.
   */
  get currentMoveAssist() {
    return !_.isNil(this.chessboard.assist);
  }

  /**
   * Indicates whether the current move is a mistake or not.
   */
  get currentMoveMistake() {
    return !_.isNil(this.chessboard.mistake);
  }

  /**
   * Returns the state of the current move.
   */
  get currentMoveState() {
    if (this.currentMoveMistake) {
      return MISTAKE_STATE;
    }

    if (this.currentMoveHint) {
      return HINT_STATE;
    }

    if (this.currentMoveAssist) {
      return ASSIST_STATE;
    }

    return UNSOLVED_STATE;
  }

  /**
    * Gets the *row* the auto move should occupy
    */
  get autoMoveRowIndex() {
    if (this.autoMoveIndex === -1) {
      return 0;
    }

    let startingPlayerOffset = this.chessboard.isStartingPlayerWhite ? 0 : 1;

    return Math.floor((this.autoMoveIndex + startingPlayerOffset) / 2) + 1;
  }

  /**
   * Sets the *row* the auto move should occupy.
   */
  set autoMoveRowIndex(autoMoveRowIndex) {
    if (autoMoveRowIndex < 0) {
      throw new Error("The autoMoveRowIndex can't be less than 0");
    }

    let startingPlayerOffset = this.chessboard.isStartingPlayerWhite ? -1 : -2;

    this.autoMoveIndex = _.isNil(autoMoveRowIndex) || autoMoveRowIndex === 0
      ? -1
      : autoMoveRowIndex * 2 + startingPlayerOffset;
  }

  /**
   * Goes to the move at the given index, playing through all of the previous moves. If any of the
   * previous moves are invalid, this method stops at the last valid move.
   * @param moveIndex The index of the move to go to, with the -1 index representing the starting
   * state of the board and all other indices representing the moves after that.
   * @param updateMoveIndex A flag determining if the move index should be updated. It's useful to
   * not update the move index when the move is being displayed temporarily, or if the move is
   * updated as an in-between step.
   */
  goToMove(moveIndex, updateMoveIndex = true) {
    if (moveIndex < -1) {
      throw new Error(`The move index ${ moveIndex } is not valid.`);
    }

    // Reset the chessboard
    this.chessboard.reset();

    // Play the moves.
    for (let i = 0; i <= moveIndex && i < this.moves.length; i++) {

      // If the move is invalid, stop.
      if (!this.chessboard.move(this.moves[i])) {
        break;
      }
    }

    // Update the move index and completed move index.
    if (updateMoveIndex) {
      this.moveIndex = moveIndex;
      this.completedMoveIndex = this.chessboard.numberOfMoves - 1;
    }
  }

  /**
   * Goes to the previous move.
   */
  goToPreviousMove() {
    this.goToMove(this.moveIndex - 1);
  }

  /**
   * Goes to the next move.
   */
  goToNextMove() {
    if (!this.hasNextMove) {
      throw new Error("There is not next move.");
    }

    this.goToMove(this.moveIndex + 1);
  }

  /**
   * Goes to the first move.
   */
  goToFirstMove() {
    this.goToMove(-1);
  }

  /**
   * Goes to the last move.
   */
  goToLastMove() {
    this.goToMove(this.moves.length - 1);
  }

  /**
   * Updates the move at the given index.
   * @param index The index of the move in in the moves array to update.
   * @param move The move to make.
   */
  updateMove(index, move) {

    // Go to the move *prior* to the current move.
    this.goToMove(index - 1, false);

    // Attempt to play the current move.
    let moveWasValid = this.chessboard.move(move);

    // If the move is a string, then we always set it in the moves array, regardless of whether or
    // not it's valid. If the move is an object, then we only set it in the moves array if it's
    // valid. Also, if the move was an object and was successful, then select it. We also always
    // update the move index when the move is an object, but when it's a string we don't.
    if (_.isString(move)) {
      this.moves[index] = move;
    }
    else if (moveWasValid) {
      this.moves[index] = this.chessboard.lastMove.algebraic;
      this.moveIndex = index;
      this.completedMoveIndex = index;
    }
  }

  /**
   * Switches the state to position.
   */
  editPosition() {
    this.editState = POSITION_STATE;
    this.goToMove(-1, false);
    this.chessboard.editingStartingPosition = true;
  }

  /**
   * Switches the state to details.
   */
  editDetails() {
    this.editState = DETAILS_STATE;
    this.goToMove(this.moveIndex, false);
    this.chessboard.editingStartingPosition = false;
  }

  /**
   * Trigger a hint for the user.
   */
  hint() {
    this._clearHelp();
    this.chessboard.hint = this.nextVerboseMove;
    this.score.hint();
  }

  /**
   * Trigger assistance for the user.
   */
  assist() {
    this._clearHelp();
    this.chessboard.assist = this.nextVerboseMove;
    this.score.assist();
  }

  /**
   * Attempts to make a move in the exercise. If the move is valid, then this method triggers the
   * next auto move (after a delay).
   * @param move The move to make. This can either be a string representing the algebraic move or an
   * object with from and to properties.
   * @return Returns true if the move was successful.
   */
  move(move) {

    // Determine if the move we're about to make is an auto move.
    let autoMove = this.isNextMoveAutoMove;

    // Attempt to make the move. If the move was not legal, don't do anything.
    if (!this.chessboard.move(move, autoMove)) {
      return false;
    }

    // Start the score if the user made the move
    if (!autoMove) {
      this.score.start();
    }

    // If the last move was a mistake, roll it back and mark is as a mistake.
    if (!this._isLastMoveCorrect) {

      // Grab the mistake
      let mistake = this.chessboard.lastMove;

      // Undo the move in the chessboard
      this.chessboard.undo();

      // Mark the move as a mistake
      this.chessboard.mistake = mistake;
      this.score.mistake();

      return false;
    }

    // Increment the move index
    this.moveIndex = this.moveIndex + 1;
    this.completedMoveIndex = this.moveIndex;

    // Unset the current move state
    this.chessboard.hint = null;
    this.chessboard.assist = null;
    this.chessboard.mistake = null;

    // If the user performed a checkmate, or if they've hit the last move in the exercise, mark it
    // as complete.
    //
    // HACK: Right now, we're not awaiting the completion of the score. This is a bit sloppy, and
    // should probably be rethought in the future. Since the goal right now is to finish the MVP,
    // I'm letting this slide.
    if (this.chessboard.isCheckmate || this.completedMoveIndex === this.moves.length - 1) {
      this.score.complete();
    }

    // Return true to indicate the move was successful
    return true;
  }

  /**
   * Automatically make the next move in the sequence. If the computer shouldn't auto move, this
   * method won't do anything.
   */
  autoMove() {

    // If the exercise shouldn't auto move, then don't.
    if (!this.isNextMoveAutoMove) {
      return false;
    }

    // Make the next auto move.
    return this.move(this.nextVerboseMove.algebraic, true);
  }

  /**
   * Triggers *all* of the auto moves that need to be made.
   */
  async autoMoveAfterDelay() {

    let delay = this.moveIndex < this.autoMoveIndex
      ? TRANSITION_TIME_XSMALL
      : TRANSITION_TIME_MEDIUM;

    // Keep making auto moves with a delay until they can't be made anymore.
    await timer(delay);
    this.autoMove();
  }

  /**
   * Whenever the exercise is navigated, this method should be called.
   */
  updateNavigation() {
    this.hasNavigatedSinceCompletion = this.isCompleted;
  }

  /**
   * Completely resets the exercise back to its starting state, including the score.
   */
  restart() {
    this.chessboard.reset();
    this.score = {};
    this.goToFirstMove();
    this._clearHelp();
    this.updateNavigation();
    this.moveIndex = -1;
    this._completedMoveIndex = -1;
  }

  /**
   * Sanitizes all of the valid moves in this exercise, replacing them with their correct symbols.
   */
  sanitizeMoves() {
    this.moves = sanitizeMoves(this.startingPosition, this.moves);
  }

  /**
   * Returns true if the last move played in the chessboard was correct this assumes the move index
   * should be incremented by one, since it hasn't been incremented yet.
   */
  get _isLastMoveCorrect() {
    let moveIndex = this.moveIndex + 1;

    // If the last move was a checkmate, then it's always correct.
    if (this.chessboard.isCheckmate) {
      return true;
    }

    // Ensure the last move matches the moves array.
    let expectedMove = this.moves[moveIndex];
    let actualMove = this.chessboard.lastMove.algebraic;

    return expectedMove === actualMove;
  }

  _clearHelp() {
    this.chessboard.hint = null;
    this.chessboard.assist = null;
    this.chessboard.mistake = null;
  }
}
