import { observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";

import { Icon } from "../shared/icon";
import { Modal } from "../../../javascript/components/shared/modal";

export const IconModal = observer(({
  className,
  title,
  icon,
  text,
  children,
  closable,
  open,
  stacked,
  requiresInteraction,
  onClose
}) => {

  return <Modal
    contentClassName={ classNames("icon-modal", className) }
    title={ title }
    open={ open }
    closable={ closable }
    requiresInteraction={ requiresInteraction }
    onClose={ onClose }
  >
    <Icon
      className="icon-modal__icon"
      name={ icon }
      filled={ false }
    />
    <p className="icon-modal__text">
      { text }
    </p>

    <div
      className={
        classNames(
          "icon-modal__actions",
          { "icon-modal__actions--stacked": stacked }
        )
      }
    >
      { children }
    </div>
  </Modal>;
});

IconModal.displayName = "IconModal";

IconModal.defaultProps = {
  closable: true,
  stacked: false
};

IconModal.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  closable: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  stacked: PropTypes.bool,
  requiresInteraction: PropTypes.bool,
  onClose: PropTypes.func
};
