
import React from 'react';


export const attributes = {};
export default function() {
  return (
    <div>
      <h1>Privacy Policy</h1>
<p><em>Last updated: June 9, 2021</em></p>
<p>Your privacy is critically important to us. At Chessercise, we have a few fundamental principles:</p>
<ul>
<li><p>We are thoughtful about the personal information we ask you to provide and the personal
information that we collect about you through the operation of our services.</p></li>
<li><p>We store personal information for only as long as we have a reason to keep it.</p></li>
<li><p>We aim to make it as simple as possible for you to control what information on your website is
shared publicly (or kept private), indexed by search engines, and permanently deleted.</p></li>
<li><p>We help protect you from overreaching government demands for your personal information.</p></li>
<li><p>We aim for full transparency on how we gather, use, and share your personal information.</p>
<p>Below is our Privacy Policy, which incorporates and clarifies these principles.</p></li>
</ul>
<h2>Who We Are and What This Policy Covers</h2>
<p>When we say “Company”, “we”, “our”, or “us” in this document, we are referring to Landon Schropp,
LLC, doing business as Chessercise.</p>
<p>In this policy, we lay out: what data we collect and why; how your data is handled; and your rights
to your data. This policy applies to all products built and maintained by Landon Schropp, LLC
including the Chessercise website and mobile apps.</p>
<h2>Information We Collect</h2>
<p>We only collect information about you if we have a reason to do so — for example, to provide our
Services, to communicate with you, or to make our Services better. We collect this information from
three sources: if and when you provide information to us, automatically through operating our
Services, and from outside sources. Let’s go over the information that we collect.</p>
<h3>Information You Provide to Us</h3>
<p>It’s probably no surprise that we collect information that you provide to us directly. Here are some
examples:</p>
<ul>
<li><strong>Basic account information:</strong> We ask for basic information from you in order to set up your
account. For example, we require individuals who sign up for a Chessercise account to provide an
email address and password, along with a username or name — and that’s it. You may provide us with
more information — like your address and other information you want to share — but we don’t
require that information to create a Chessercise account.</li>
<li><strong>Public profile information:</strong> If you have an account with us, we collect the information that
you provide for your public profile. For example, if you have a Chessercise account, your username
is part of that public profile, along with any other information you put into your public profile,
like a photo or an “About Me” description. Your public profile information is just that — public —
so please keep that in mind when deciding what information you would like to include.</li>
<li><strong>Payment and contact information:</strong> If you buy something from us , we’ll collect information to
process those payments and contact you, like your name, credit card information, and contact
information. We also keep a record of the purchases you’ve made.</li>
<li><strong>Content information:</strong> You might provide us with information about you in draft and published
content (a chess lesson you create, or any media or files you upload).</li>
<li><strong>Communications with us (hi there!):</strong> You may also provide us with information when you respond
to surveys, communicate with us about a support question, post a question in our public forums, or
sign up for a newsletter or course. When you communicate with us via form, email, phone, comment,
or otherwise, we store a copy of our communications (including any call recordings as permitted by
applicable law).</li>
<li><strong>Job applicant information:</strong> If you apply for a job with us — awesome! You may provide us with
information like your name, contact information, resume or CV, and work authorization verification
as part of the application process.</li>
</ul>
<h3>Information We Collect Automatically</h3>
<p>We also collect some information automatically:</p>
<ul>
<li><strong>Log information:</strong> Like most online service providers, we collect information that web browsers,
mobile devices, and servers typically make available, including the browser type, IP address,
unique device identifiers, language preference, referring site, the date and time of access,
operating system, and mobile network information. We collect log information when you use our
Services — for example, when you create or make changes to content on Chessercise.</li>
<li><strong>Usage information:</strong> We collect information about your usage of our Services. We use this
information to, for example, provide our Services to you, get insights on how people use our
Services so we can make our Services better, and understand and make predictions about user
retention.</li>
<li><strong>Location information:</strong> We may determine the approximate location of your device from your IP
address. We collect and use this information to, for example, calculate how many people visit our
Services from certain geographic regions.</li>
<li><strong>Stored information:</strong> We may access information stored on your mobile device via our mobile
apps. We access this stored information through your device operating system’s permissions. For
example, if you give us permission to access the photographs on your mobile device’s camera roll,
our Services may access the photos stored on your device when you upload a profile photo.</li>
<li><strong>Information from cookies &amp; other technologies:</strong> A cookie is a string of information that a
website stores on a visitor’s computer, and that the visitor’s browser provides to the website
each time the visitor returns. Pixel tags (also called web beacons) are small blocks of code
placed on websites and emails. Chessercise uses cookies and other technologies like pixel tags to
help us identify and track visitors, usage, and access preferences for our Services, as well as
track and understand email campaign effectiveness and to deliver targeted ads. For more
information about our use of cookies and other technologies for tracking, including how you can
control the use of cookies, please see our <a href="/cookie-policy">Cookie Policy</a>.</li>
</ul>
<h3>Information We Collect from Other Sources</h3>
<p>We may also get information about you from other sources. The information we receive depends on
which services you use or authorize and what options are available. Third-party services may also
give us information, like mailing addresses for individuals who are not yet our users (but we hope
will be!). We use this information for marketing purposes like postcards and other mailers
advertising our Services.</p>
<h2>How and Why We Use Information</h2>
<h3>Purposes for Using Information</h3>
<p>We use information about you for the purposes listed below:</p>
<ul>
<li><strong>To provide our Services.</strong> For example, to set up and maintain your account, host your content,
backup and restore your content, provide customer service, process payments and orders, and verify
user information.</li>
<li><strong>To ensure quality, maintain safety, and improve our Services.</strong> For example, by providing
automatic upgrades and new versions of our Services. Or, for example, by monitoring and analyzing
how users interact with our Services so we can create new features that we think our users will
enjoy or make our Services easier to use.</li>
<li><strong>To place and manage ads in our advertising program.</strong> For example, to place ads on our own sites
as part of our advertising program, and understand ad performance.</li>
<li><strong>To market our Services and measure, gauge, and improve the effectiveness of our marketing.</strong> For
example, by targeting our marketing messages to groups of our users (like those who have a
particular plan with us or have been users for a certain length of time), advertising our
Services, analyzing the results of our marketing campaigns (like how many people purchased a paid
plan after receiving a marketing message), and understanding and forecasting user retention.</li>
<li><strong>To protect our Services, our users, and the public.</strong> For example, by detecting security
incidents; detecting and protecting against malicious, deceptive, fraudulent, or illegal activity;
fighting spam; complying with our legal obligations; and protecting the rights and property of
Landon Schropp LLC and others, which may result in us, for example, declining a transaction or
terminating Services.</li>
<li><strong>To fix problems with our Services.</strong> For example, by monitoring, debugging, repairing, and
preventing issues.</li>
<li><strong>To customize the user experience.</strong> For example, to personalize your experience by serving you
relevant notifications and advertisements for our Services, recommending content through our
suggestions, and providing content for your viewing pleasure.</li>
<li><strong>To communicate with you.</strong> For example, by emailing you to ask for your feedback, share tips for
getting the most out of our products, or keep you up to date on Chessercise; or emailing you to
share offers and promotions that we think will be of interest to you. If you don’t want to hear
from us, you can opt out of marketing communications at any time. (If you opt out, we’ll still
send you important updates relating to your account.)</li>
<li><strong>To recruit and hire new employees.</strong> For example, by evaluating job applicants and communicating
with them.</li>
</ul>
<h3>Legal Bases for Collecting and Using Information</h3>
<p>A note here for those in the European Union about our legal grounds for processing information about
you under EU data protection laws, which is that our use of your information is based on the grounds
that: (1) The use is necessary in order to fulfill our commitments to you under the applicable terms
of service or other agreements with you or is necessary to administer your account — for example, in
order to enable access to our website on your device or charge you for a paid plan; or (2) The use
is necessary for compliance with a legal obligation; or (3) The use is necessary in order to protect
your vital interests or those of another person; or (4) We have a legitimate interest in using your
information — for example, to provide and update our Services; to improve our Services so that we
can offer you an even better user experience; to safeguard our Services; to communicate with you; to
measure, gauge, and improve the effectiveness of our advertising; and to understand our user
retention and attrition; to monitor and prevent any problems with our Services; and to personalize
your experience; or (5) You have given us your consent — for example before we place certain cookies
on your device and access and analyze them later on, as described in our <a href="/cookie-policy">Cookie
Policy</a>.</p>
<h2>Sharing Information</h2>
<h3>How We Share Information</h3>
<p>We share information about you in limited circumstances, and with appropriate safeguards on your
privacy. These are spelled out below, as well as in the section Ads and Analytics Services
Provided by Others:</p>
<ul>
<li><strong>Subsidiaries and independent contractors:</strong> We may disclose information about you to our
subsidiaries and independent contractors who need the information to help us provide our
Services or process the information on our behalf. We require our subsidiaries and independent
contractors to follow this Privacy Policy for any personal information that we share with them.</li>
<li><strong>Third-party vendors:</strong> We may share information about you with third-party vendors who need the
information in order to provide their services to us, or to provide their services to you or your
site. This includes vendors that help us provide our Services to you (like Stripe or other payment
providers that process your credit and debit card information, fraud prevention services that
allow us to analyze fraudulent payment transactions, cloud storage services, postal and email
delivery services that help us stay in touch with you, customer chat and email support services
that help us communicate with you, registrars, and registries); those that assist us with our
marketing efforts (e.g., by providing tools for identifying a specific marketing target group or
improving our marketing campaigns, and by placing ads to market our services); those that help us
understand and enhance our Services (like analytics providers); those that make tools to help us
run our operations (like programs that help us with task management, scheduling, word processing,
email and other communications, and collaboration among our teams); other third-party tools that
help us manage operations; and companies that make products available on our websites, who may
need information about you in order to, for example, provide technical or other support services
to you. We require vendors to agree to privacy commitments in order to share information with
them. Other vendors are listed in our more specific policies (e.g., our <a href="/cookie-policy">Cookie
Policy</a>).</li>
<li><strong>Legal and regulatory requirements:</strong> If US law enforcement authorities have the necessary
warrant, criminal subpoena, or court order requiring we share data, we have to comply. Otherwise,
we flat-out reject requests from local and federal law enforcement when they seek data. And unless
we’re legally prevented from it, we’ll always inform you when such requests are made. In the event
a government authority outside the US approaches Landon Schropp, LLC with a request, our default
stance is to refuse unless the US government compels us to comply through procedures outlined in a
mutual legal assistance treaty or agreement. <em>We have never received a National Security Letter
or Foreign Intelligence Surveillance Act (FISA) order.</em> Similarly, if Landon Schropp, LLC receives
a request to preserve data, we refuse unless compelled by either the US Federal Stored
Communications Act, 18 U.S.C. Section 2703(f) or a properly served US subpoena for civil matters.
In both of these situations, we have to comply. In these situations, we notify affected customers
as soon as possible unless we are legally prohibited from doing so. We do not share preserved data
unless absolutely required under the Stored Communications Act or compelled by a court order that
we choose not to appeal. Furthermore, unless we receive a proper warrant, court order, or subpoena
before the required preservation period expires, we destroy any preserved copies we made of
customer data once the preservation period lapses.</li>
<li><strong>To protect rights, property, and others:</strong> We may disclose information about you when we
believe in good faith that disclosure is reasonably necessary to protect the property or rights
of Landon Schropp, LLC, third parties, or the public at large. For example, if we have a good faith
belief that there is an imminent danger of death or serious physical injury, we may disclose
information related to the emergency without delay.</li>
<li><strong>Business transfers:</strong> In connection with any merger, sale of company assets, or acquisition of
all or a portion of our business by another company, or in the unlikely event that Landon Schropp,
LLC goes out of business or enters bankruptcy, user information would likely be one of the assets
that is transferred or acquired by a third party. If any of these events were to happen, this
Privacy Policy would continue to apply to your information and the party receiving your
information may continue to use your information, but only consistent with this Privacy Policy.</li>
<li><strong>With your consent:</strong> We may share and disclose information with your consent or at your
direction. For example, we may share your information with third parties when you authorize us
to do so.</li>
<li><strong>Aggregated or de-identified information:</strong> We may share information that has been aggregated
or de-identified, so that it can no longer reasonably be used to identify you. For instance, we
may publish aggregate statistics about the use of our Services, or share a hashed version of
your email address to facilitate customized ad campaigns on other platforms.</li>
<li><strong>Published support requests:</strong> If you send us a request for assistance (for example, via a
support email or one of our other feedback mechanisms), we reserve the right to publish that
request in order to clarify or respond to your request, or to help us support other users.</li>
</ul>
<p>We have a long-standing policy that we do not sell our users' data. We aren't a data broker, we
don't sell your personal information to data brokers, and we don't sell your information to other
companies that want to spam you with marketing emails.</p>
<h3>Information Shared Publicly</h3>
<p>Information that you choose to make public is — you guessed it — disclosed publicly. That means
information like your public profile, posts, other content that you make public, and your
“likes” and comments on other content are all available to others! Public information may also
be indexed by search engines or used by third parties. Please keep all of this in mind when deciding
what you would like to share publicly.</p>
<h2>How Long We Keep Information</h2>
<p>In many of our applications, we give you the option to trash data. Anything you trash on your
product accounts while they are active may be kept in an accessible trash can for up to 30 days (it
varies by the type of data). After that, the trashed data are no longer accessible via the
application and are deleted from our active servers within the next 30 days. We also have some
backups of our application databases, which are kept for up to another 180 days. In total, when you
trash things in our applications, they are purged within 240 days from all of our systems and logs.
Retrieving data for a single account from a backup is cost-prohibitive and unduly burdensome so if
you change your mind you’ll need to do so before your data are deleted from our active servers.</p>
<h2>Security</h2>
<p>While no online service is 100% secure, we work very hard to protect information about you against
unauthorized access, use, alteration, or destruction, and take reasonable measures to do so. We
monitor our Services for potential vulnerabilities and attacks.</p>
<h2>Choices</h2>
<p>You have several choices available when it comes to information about you:</p>
<ul>
<li><strong>Limit the information that you provide:</strong> If you have an account with us, you can choose not to
provide the optional account information, profile information, and transaction and billing
information. Please keep in mind that if you do not provide this information, certain features of
our Services may not be accessible.</li>
<li><strong>Limit access to information on your mobile device:</strong> Your mobile device operating system should
provide you with the option to discontinue our ability to collect stored information or location
information via our mobile apps. If you choose to limit this, you may not be able to use certain
features.</li>
<li><strong>Opt out of marketing communications:</strong> You may opt out of receiving promotional communications
from us. Just follow the instructions in those communications or let us know. If you opt out of
promotional communications, we may still send you other communications, like those about your
account and legal notices.</li>
<li><strong>Set your browser to reject cookies:</strong> At this time, Chessercise does not respond to “do not
track” signals across all of our Services. However, you can usually choose to set your browser to
remove or reject browser cookies before using Chessercise’s websites, with the drawback that
certain features of Chessercise’s websites may not function properly without the aid of cookies.
For more information, please see our <a href="/cookie-policy">Cookie Policy</a>.</li>
<li><strong>Close your account:</strong> While we’d be very sad to see you go, you can delete your account if you
no longer want to use our Services. (Please contact our customer support at
<a href="mailto:hello@chessercise.xyz">hello@chessercise.xyz</a> to delete your account.) Please keep in mind
that we may continue to retain your information after closing your account, as described in How
Long We Keep Information above — for example, when that information is reasonably needed to comply
with (or demonstrate our compliance with) legal obligations such as law enforcement requests, or
reasonably needed for our legitimate business interests.</li>
</ul>
<h2>Your Rights</h2>
<p>If you are located in certain parts of the world, including California and countries that fall under
the scope of the European General Data Protection Regulation (aka the “GDPR”), you may have certain
rights regarding your personal information, like the right to request access to or deletion of your
data.</p>
<h3>European General Data Protection Regulation (GDPR)</h3>
<p>If you are located in a country that falls under the scope of the GDPR, data protection laws give
you certain rights with respect to your personal data, subject to any exemptions provided by the
law, including the rights to:</p>
<ul>
<li><p>Request access to your personal data;</p></li>
<li><p>Request correction or deletion of your personal data;</p></li>
<li><p>Object to our use and processing of your personal data;</p></li>
<li><p>Request that we limit our use and processing of your personal data; and</p></li>
<li><p>Request portability of your personal data.</p>
<p>You also have the right to make a complaint to a government supervisory authority.</p></li>
</ul>
<h3>California Consumer Privacy Act (CCPA)</h3>
<p>The California Consumer Privacy Act (“CCPA”) requires us to provide California residents with some
additional information about the categories of personal information we collect and share, where we
get that personal information, and how and why we use it. The CCPA also requires us to provide a
list of the “categories” of personal information we collect, as that term is defined in the law, so,
here it is.</p>
<ul>
<li>Identifiers (like your name, contact information, and device and online identifiers);</li>
<li>Commercial information (your billing information and purchase history, for example);</li>
<li>Internet or other electronic network activity information (such as your usage of our Services);</li>
<li>Geolocation data (such as your location based on your IP address);</li>
<li>Audio, electronic, visual or similar information (such as your profile picture, if you uploaded
one);</li>
<li>Professional or employment-related information (for example, information you provide in a job
application); and</li>
<li>Inferences we make (such as likelihood of retention or attrition).</li>
</ul>
<p>We collect personal information for the business and commercial purposes described in the How and
Why We Use Information section above. And we share this information with the categories of third
parties described in the Sharing Information section above. If you are a California resident, you
have additional rights under the CCPA, subject to any exemptions provided by the law, including the
right to:</p>
<ul>
<li>Request to know the categories of personal information we collect, the categories of business or
commercial purpose for collecting and using it, the categories of sources from which the
information came, the categories of third parties we share it with, and the specific pieces of
information we collect about you;</li>
<li>Request deletion of personal information we collect or maintain;</li>
<li>Opt out of any sale of personal information; and</li>
<li>Not receive discriminatory treatment for exercising your rights under the CCPA.</li>
</ul>
<h3>Contacting Us About These Rights</h3>
<p>You can usually access, correct, or delete your personal data using your account settings and tools
that we offer, but if you aren’t able to or you’d like to contact us about one of the other rights,
scroll down to “How to Reach Us” to, well, find out how to reach us. When you contact us about one
of your rights under this section, we’ll need to verify that you are the right person before we
disclose or delete anything. For example, if you are a user, we will need you to contact us from the
email address associated with your account. You can also designate an authorized agent to make a
request on your behalf by giving us written authorization. We may still require you to verify your
identity with us.</p>
<h2>How to Reach Us</h2>
<p>If you have a question about this Privacy Policy, or you would like to contact us about any of the
rights mentioned in the Your Rights section above, please contact us via
<a href="mailto:hello@chessercise.xyz">email</a>. This is the fastest ways to get a response to your inquiry.</p>
<h2>Other Things You Should Know (Keep Reading!)</h2>
<h3>Transferring Information</h3>
<p>Because Automattic’s Services are offered worldwide, the information about you that we process when
you use the Services in the EU may be used, stored, and/or accessed by individuals operating outside
the European Economic Area (EEA) who work for us, other members of our group of companies, or
third-party data processors. This is required for the purposes listed in the <a href="https://automattic.com/privacy/#how-and-why-we-use-information">How and Why We Use
Information section</a> above. When
providing information about you to entities outside the EEA, we will take appropriate measures to
ensure that the recipient protects your personal information adequately in accordance with this
Privacy Policy as required by applicable law. These measures include entering into European
Commission approved standard contractual arrangements with entities based in countries outside the
EEA. You can ask us for more information about the steps we take to protect your personal
information when transferring it from the EU.</p>
<h3>Ads and Analytics Services Provided by Others</h3>
<p>Ads appearing on any of our Services may be delivered by advertising networks. Other parties may
also provide analytics services via our Services. These ad networks and analytics providers may set
tracking technologies (like cookies) to collect information about your use of our Services and
across other websites and online services. These technologies allow these third parties to recognize
your device to compile information about you or others who use your device. This information allows
us and other companies to, among other things, analyze and track usage, determine the popularity of
certain content, and deliver ads that may be more targeted to your interests. Please note this
Privacy Policy only covers the collection of information by Landon Schropp, LLC and does not cover
the collection of information by any third-party advertisers or analytics providers.</p>
<h2>Privacy Policy Changes</h2>
<p>Although most changes are likely to be minor, Landon Schropp, LLC may change its Privacy Policy from
time to time. Landon Schropp, LLC encourages visitors to frequently check this page for any changes
to its Privacy Policy. If we make changes, we may provide additional notice (like adding a statement
to our homepage or sending you a notification through email or your dashboard). Your further use of
the Services after a change to our Privacy Policy will be subject to the updated policy.</p>
<h2>Attribution</h2>
<p>This policy was adapted from open source legal documents from <a href="https://github.com/Automattic/legalmattic">Automattic
Legalmattic</a> under the <a href="https://creativecommons.org/licenses/by-sa/4.0/">CC BY-SA
4.0</a> license and the <a href="https://github.com/basecamp/policies">Basecamp open-source
policies</a> under the <a href="https://creativecommons.org/licenses/by/4.0/">CC BY
4.0</a> license.</p>

    </div>
  );
};