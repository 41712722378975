import { RouterLink } from "mobx-state-router";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";

import { Blurb } from "../shared/blurb";
import { Button } from "../shared/button";
import {
  EmptyCreatedLessonsMessage,
  EmptyLikedLessonsMessage,
  EmptyPlayedLessonsMessage
} from "../lesson/empty-lessons-messages";
import { Icon } from "../shared/icon";
import { LessonIcon } from "../lesson/lesson-icon";
import { LessonStore } from "../../stores/models/lesson-store";
import {
  NEW_LESSON_ROUTE,
  PRACTICE_LESSON_ROUTE,
  POPULAR_LESSONS_ROUTE,
  CREATED_LESSONS_ROUTE,
  LIKED_LESSONS_ROUTE
} from "../../stores/routes";
import { ScoreSummaryStats } from "../score-summary/score-summary-stats";
import { anyNil } from "../../utilities/nil";
import { useStore } from "../../stores/store-provider";

const WelcomePageLesson = observer(({ lesson }) => {
  return <li className="welcome-page__lesson">
    <RouterLink
      className="welcome-page__lesson-anchor"
      routeName={ PRACTICE_LESSON_ROUTE }
      params={ { lessonId: lesson.id } }
    >
      <LessonIcon className="welcome-page__lesson-icon" lesson={ lesson } />
      <h3 className="welcome-page__lesson-title">{ lesson.title }</h3>
      <p className="welcome-page__lesson-source-name">{ lesson.sourceName }</p>
    </RouterLink>
  </li>;
});

WelcomePageLesson.displayName = "WelcomePageLesson";

WelcomePageLesson.propTypes = {
  lesson: PropTypes.instanceOf(LessonStore).isRequired
};

const WelcomePageLessons = observer(({ lessons, emptyMessage }) => {

  if (_.isEmpty(lessons)) {
    return <p className="welcome-page__empty">
      { emptyMessage }
    </p>;
  }

  return <ul className="welcome-page__lessons">
    { lessons.map(lesson => <WelcomePageLesson lesson={ lesson } key={ lesson.id } />) }
  </ul>;
});

WelcomePageLessons.displayName = "WelcomePageLessons";

WelcomePageLessons.propTypes = {
  lessons: PropTypes.arrayOf(PropTypes.instanceOf(LessonStore)).isRequired,
  emptyMessage: PropTypes.node.isRequired
};

const WelcomePageSection = observer(({ header, icon, children, routeName }) => {
  return <section className="welcome-page__section">
    <h2 className="welcome-page__header">
      <Icon className="welcome-page__icon" name={ icon } alt={ header } />
      {
        _.isNil(routeName)
          ? header
          : <RouterLink className="welcome-page__header-link" routeName={ routeName }>
            { header }
          </RouterLink>
      }
    </h2>
    { children }
  </section>;
});

WelcomePageSection.propTypes = {
  header: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  routeName: PropTypes.string
};

WelcomePageSection.displayName = "WelcomePageSection";

const NewLessonBlurb = observer(() => {
  return <Blurb
    className="welcome-page__blurb"
    icon={ null }
    message="Creating lessons is quick and easy. Give it a shot!"
  >
    <Button treatment="primary" routeName={ NEW_LESSON_ROUTE } text="Create Lesson" icon="plus" />
  </Blurb>;
});

NewLessonBlurb.displayName = "NewLessonBlurb";

export const WelcomePage = observer(() => {
  let { scoreSummaries, likedLessons, playedLessons, createdLessons } = useStore("routerParams");

  if (anyNil(scoreSummaries, likedLessons, playedLessons, createdLessons)) {
    return null;
  }

  return <div className="welcome-page">
    <WelcomePageSection header="Stats" icon="chart">
      <ScoreSummaryStats
        className="welcome-page__stats"
        scoreSummaries={ scoreSummaries }
      />
    </WelcomePageSection>

    <WelcomePageSection
      header="Liked Lessons"
      icon="thumbsUp"
      routeName={ LIKED_LESSONS_ROUTE }
    >
      <WelcomePageLessons
        emptyMessage={ <EmptyLikedLessonsMessage /> }
        lessons={ likedLessons }
      />
    </WelcomePageSection>

    <WelcomePageSection
      header="History"
      icon="history"
      routeName={ POPULAR_LESSONS_ROUTE }
    >
      <WelcomePageLessons
        lessons={ playedLessons }
        emptyMessage={ <EmptyPlayedLessonsMessage /> }
      />
    </WelcomePageSection>

    <WelcomePageSection
      header="Created Lessons"
      icon="plus"
      routeName={ CREATED_LESSONS_ROUTE }
    >
      <WelcomePageLessons
        lessons={ createdLessons }
        emptyMessage={ <EmptyCreatedLessonsMessage /> }
      />
      <NewLessonBlurb />
    </WelcomePageSection>
  </div>;
});

WelcomePage.displayName = "WelcomePage";
