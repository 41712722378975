import { observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";
import classNames from "classnames";

import { Controls } from "./controls";
import { Button } from "../shared/button";
import { useStore } from "../../stores/store-provider";

export const ExerciseControls = observer(({ className }) => {
  let { lesson } = useStore("routerParams");

  if (_.isNil(lesson)) {
    return null;
  }

  let exercise = lesson.currentExercise;

  return <Controls
    className={ classNames("exercise-controls-controls", className) }
    onLeftArrowClicked={ () => exercise?.goToPreviousMove() }
    leftArrowDisabled={ _.isNil(exercise) || !exercise.hasPreviousMove }
    onRightArrowClicked={ () => exercise?.goToNextMove() }
    rightArrowDisabled={ _.isNil(exercise) || !exercise.hasNextCompletedMove }
  >
    <Button
      className={
        classNames(
          "exercise-controls-controls__button",
          { "exercise-controls-controls__button--hint": exercise?.currentMoveHint }
        )
      }
      icon="lightbulb"
      text="Hint"
      onClick={ () => exercise.hint() }
      title="Show Next Piece"
      disabled={
        _.isNil(exercise)
          || exercise.currentMoveHint
          || exercise.currentMoveAssist
          || exercise.isCompleted
      }
      treatment="clear"
    />

    <Button
      className={
        classNames(
          "exercise-controls-controls__button",
          { "exercise-controls-controls__button--assist": exercise?.currentMoveAssist }
        )
      }
      icon="move"
      text="Assist"
      title="Show Next Move"
      onClick={ () => exercise.assist() }
      disabled={ _.isNil(exercise) || exercise.currentMoveAssist || exercise.isCompleted }
      treatment="clear"
    />
  </Controls>;
});

ExerciseControls.displayName = "ExerciseControls";

ExerciseControls.propTypes = {
  className: PropTypes.string
};
