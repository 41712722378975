import { UnauthorizedError } from "../../utilities/errors";
import { apiClient } from "../../api/api-client";
import { validateEmail } from "../../validators/validate-email";
import { validatePresence } from "../../validators/validate-presence";
import { FormStore } from "./form-store";
import { ScoreStore } from "../models/score-store";

export class LogInStore extends FormStore {
  static RESOURCE_NAME = "session";
  static ATTRIBUTES = [ "email", "password" ]

  static VALIDATORS = {
    email: [ validatePresence, validateEmail ],
    password: [ validatePresence ]
  }

  email = null
  password = null

  constructor(attributes) {
    super();
    this.initialize(attributes);
  }

  /**
   * Submits the form.
   */
  async submit() {
    return this.wrapSubmit(
      async () => {
        await apiClient.createSession(this.attributes);
        await ScoreStore.saveStored();
      },
      (error) => {
        return error instanceof UnauthorizedError
          ? "unauthorized"
          : null;
      }
    );
  }
}
