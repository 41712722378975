import { observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";

import {
  EDIT_EXERCISE_ROUTE,
  EDIT_LESSON_ROUTE,
  NEW_EXERCISE_ROUTE,
  NEW_LESSON_ROUTE,
  PRACTICE_LESSON_ROUTE
} from "../../stores/routes";
import { POSITION_STATE } from "../../stores/models/exercise-store";
import { RouterView } from "../shared/router-view";
import { useStore } from "../../stores/store-provider";

const EditExerciseTitle = observer(() => {
  let { exercise } = useStore("routerParams");

  if (exercise?.editState === POSITION_STATE) {
    return <>Edit Starting Position</>;
  }

  return <>Edit Exercise</>;
});

EditExerciseTitle.displayName = "EditExerciseTitle";

export const SidebarTitle = observer(({ className }) => {
  return <h2 className={ className }>
    <RouterView
      viewMap={
        {
          [PRACTICE_LESSON_ROUTE]: () => "Practice",
          [NEW_LESSON_ROUTE]: () => "Edit Lesson",
          [EDIT_LESSON_ROUTE]: () => "Edit Lesson",
          [NEW_EXERCISE_ROUTE]: EditExerciseTitle,
          [EDIT_EXERCISE_ROUTE]: EditExerciseTitle
        }
      }
    />
  </h2>;
});

SidebarTitle.propTypes = {
  className: PropTypes.string
};

SidebarTitle.displayName = "SidebarTitle";
