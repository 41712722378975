import PropTypes from "prop-types";
import React from "react";

import { Button } from "../shared/button";
import { LessonStore } from "../../stores/models/lesson-store";

/**
 * This functions exactly like a regular button, except it's already configured to trigger the share
 * button. If the browser does not support the Web Share API, this function returns null.
 */
export function ShareLessonButton({ lesson, ...props }) {
  if (!navigator.share) {
    return null;
  }

  return <Button
    icon="externalLink"
    text="Share"
    onClick={
      async () => {
        try {
          await navigator.share({
            url: window.location.href,
            title: lesson.title,
            text: lesson.description
          });
        }
        catch (error) {
        // Ignore the simulator cancellation.
        }
      }
    }
    { ...props }
  />;
}

ShareLessonButton.propTypes = {
  lesson: PropTypes.instanceOf(LessonStore).isRequired
};
