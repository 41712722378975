// NOTE: Unlike the other store files, this file is responsible for returning the global *instance*
// of the router store.
//
// The implementation for this was taken from:
// https://nareshbhatia.github.io/mobx-state-router/docs/guides-getting-started.

import {
  browserHistory,
  createRouterState,
  HistoryAdapter,
  RouterStore
} from "mobx-state-router";
import { runInAction } from "mobx";
import ReactGA from "react-ga";

import { NOT_FOUND_ROUTE, ROUTES } from "./routes";
import { RouterParamsStore } from "./router-params-store";
import { SessionStore } from "./session-store";
import { ErrorStore } from "./error-store";

// Set up Google Analytics. This has to happen here first before the page view even fires.
process.env.NODE_ENV === "test"
  ? ReactGA.initialize("G-0000000000", { testMode: true })
  : ReactGA.initialize("G-SLHXDCMQT9");

const notFound = createRouterState(NOT_FOUND_ROUTE);

export const routerParamsStore = new RouterParamsStore();

function onEnter(fromState, toState, routerStore) {
  let { session, error } = routerStore.options;

  runInAction(() => {
    routerParamsStore.routeName = toState.routeName;
    routerParamsStore.params = { ...toState.queryParams, ...toState.params };
  });

  // Clear out any errors in the error store.
  error.clear();

  // These methods are intentionally not awaited because the return value of onEnter is sensitive
  // and awaiting would return a promise.
  session.load();
  routerParamsStore.load();

  // Trigger a page view in analytics.
  ReactGA.pageview(window.location.pathname + window.location.search);
}

// Automatically update the router params whenever a route changes.
let routes = ROUTES.map(route => ({ onEnter, ...route }));

// HACK: There doesn't seem to be any other way to provide the session store to the router
// store other than to initialize it here.
export const routerStore = new RouterStore(
  routes,
  notFound,
  { session: new SessionStore(), error: new ErrorStore() }
);

const historyAdapter = new HistoryAdapter(routerStore, browserHistory);
historyAdapter.observeRouterStateChanges();
