import { action, runInAction } from "mobx";
import { observer, useLocalObservable } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";

import { ExerciseStore } from "../../stores/models/exercise-store";
import { InputWithLabel } from "./input-with-label";
import { sanitizeFEN, isFenValid } from "../../utilities/fen";

/* eslint-disable react/no-this-in-sfc */

export const FENInputWithLabel = observer(({ exercise }) => {

  const localObservable = useLocalObservable(() => ({
    value: exercise.startingPosition,
    lastValidValue: exercise.startingPosition,
    focused: false,

    get sanitizedValue() {
      return sanitizeFEN(this.value);
    },

    get isValid() {
      return isFenValid(this.sanitizedValue);
    },

    get errors() {
      return this.isValid ? [] : [ "Must be valid FEN" ];
    }
  }));

  // If the exercise's value is different than the last valid value, update the local observable to
  // match.
  if (localObservable.lastValidValue !== exercise.startingPosition) {
    runInAction(() => {
      localObservable.value = exercise.startingPosition;
      localObservable.lastValidValue = exercise.startingPosition;
    });
  }

  return <InputWithLabel
    label="FEN"
    className="fen-input-with-label"
    inputClassName="fen-input-with-label__textarea"
    errors={ exercise.errors.startingPosition }
    onFocus={ action(() => localObservable.focused = true) }
    value={ localObservable.value }
    minRows={ 2 }
    alwaysDisplayError={ !localObservable.focused }
    onBlur={
      action(() => {
        localObservable.focused = false;

        if (localObservable.isValid) {
          localObservable.value = localObservable.sanitizedValue;
        }
      })
    }
    onChange={
      action((event) => {
        localObservable.value = event.target.value;

        if (localObservable.isValid) {
          exercise.startingPosition = localObservable.sanitizedValue;
          localObservable.lastValidValue = exercise.startingPosition;
        }
      })
    }
  />;
});

FENInputWithLabel.displayName = "FENInputWithLabel";

FENInputWithLabel.propTypes = {
  exercise: PropTypes.instanceOf(ExerciseStore).isRequired
};
