import { Draggable } from "react-beautiful-dnd";
import PropTypes from "prop-types";
import React from "react";

/**
 * This component uses react-beautiful-dnd with a single div to create a draggable area. The goal is
 * to obfuscate some of the complexity in react-beautiful-dnd for the use cases in this project.
 * @param className The class name to append to the draggable div.
 * @param index The index of the item in its parent array.
 * @param id A unique ID to represent the draggable item.
 * @param children The children to render inside of the droppable area. This should be an *array* of
 * SimpleDraggable nodes.
 * @param locked A locked SimpleDraggable cannot be drug. It's still necessary to use a
 * SimpleDraggable because the component will need to be moved for other draggable components.
 */

export function SimpleDraggable({ className, index, id, children, locked }) {

  return <Draggable draggableId={ id } index={ index } isDragDisabled={ locked }>
    {
      (provided) => {
        return <div
          className={ className }
          ref={ provided.innerRef }
          { ...provided.draggableProps }
          { ...(provided.dragHandleProps) }
        >
          { children }
        </div>;
      }
    }
  </Draggable>;
}

SimpleDraggable.defaultParams = {
  locked: false
};

SimpleDraggable.propTypes = {
  className: PropTypes.string,
  index: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  locked: PropTypes.bool,
  children: PropTypes.node.isRequired
};
