import { RouterLink } from "mobx-state-router";
import { observer } from "mobx-react";
import React, { useState } from "react";

import { AttributeInputWithLabel } from "../shared/attribute-input-with-label";
import { AuthenticationPage } from "../authentication/authentication-page";
import { RESET_PASSWORD_SUCCESS_ROUTE, FORGOT_PASSWORD_ROUTE } from "../../stores/routes";
import { ResetPasswordStore } from "../../stores/forms/reset-password-store";
import { useStore } from "../../stores/store-provider";
import { PasswordStrength } from "../authentication/password-strength";

export const ResetPasswordPage = observer(() => {
  let [ resetPassword ] = useState(() => new ResetPasswordStore());
  let router = useStore("router");
  let { token } = useStore("routerParams").params;

  async function handleSubmit(event) {
    event.preventDefault();
    await resetPassword.submit({ token });

    if (resetPassword.isValid) {
      router.goTo(RESET_PASSWORD_SUCCESS_ROUTE);
    }
  }

  return <AuthenticationPage
    className="reset-password-page"
    title="Reset Password"
    onSubmit={ handleSubmit }
    buttonText="Reset Password"
    message={ <>Enter your new password below.</> }
    error={
      resetPassword.validations.submit
        ? null
        : <>
          Your password reset link has expired. Please
          { " " }
          <RouterLink
            className="authentication-page__error-link"
            routeName={ FORGOT_PASSWORD_ROUTE }
          >
            try again
          </RouterLink>.
        </>
    }
  >
    <AttributeInputWithLabel
      className="reset-password-page__password-input"
      label="Password"
      model={ resetPassword }
      attribute="password"
      type="password"
    />

    <PasswordStrength password={ resetPassword.password } />
  </AuthenticationPage>;
});

ResetPasswordPage.displayName = "ResetPasswordPage";
