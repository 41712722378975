import { observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";

import { Button } from "../shared/button";
import { LessonStore, LIKED_VOTE, DISLIKED_VOTE } from "../../stores/models/lesson-store";
import { useStore } from "../../stores/store-provider";

export const VoteButton = observer(({ lesson, className, type }) => {
  let { isLoggedIn } = useStore("session");

  function handleLike(event) {
    event.stopPropagation();
    event.preventDefault();
    lesson.toggleLike();
  }

  function handleDislike(event) {
    event.stopPropagation();
    event.preventDefault();
    lesson.toggleDislike();
  }

  return <Button
    className={
      classNames(
        "vote-button",
        className,
        { "vote-button--voted": lesson.vote === type }
      )
    }
    icon={ type === LIKED_VOTE ? "thumbsUp" : "thumbsDown" }
    text={ type === LIKED_VOTE ? "Like" : "Dislike" }
    onClick={ type === LIKED_VOTE ? handleLike : handleDislike }
    treatment="clear"
    disabled={ !isLoggedIn }
  />;
});

VoteButton.displayName = "VoteButton";

VoteButton.propTypes = {
  lesson: PropTypes.instanceOf(LessonStore).isRequired,
  className: PropTypes.string,
  type: PropTypes.oneOf([ LIKED_VOTE, DISLIKED_VOTE ])
};
