import { RouterLink } from "mobx-state-router";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";

import { LessonIcon } from "./lesson-icon";
import { DISLIKED_VOTE, LessonStore, LIKED_VOTE } from "../../stores/models/lesson-store";
import { EDIT_LESSON_ROUTE, PRACTICE_LESSON_ROUTE } from "../../stores/routes";
import { VoteButton } from "./vote-button";
import { ShareLessonButton } from "../shared/share-lesson-button";
import { Button } from "../shared/button";
import { useDesktopMediaQuery } from "../../hooks/media-queries";
import { useStore } from "../../stores/store-provider";

// TODO: Remove the duplication between this and LessonControls.
export const LessonListItem = observer(({ lesson }) => {
  let isDesktop = useDesktopMediaQuery();
  let { user } = useStore("session");

  return <li className="lesson-list-item" key={ lesson.id }>
    <RouterLink
      className="lesson-list-item__link"
      routeName={ PRACTICE_LESSON_ROUTE }
      params={ { lessonId: lesson.id } }
    >
      <LessonIcon className="lesson-list-item__icon" lesson={ lesson } />

      <div className="lesson-list-item__content">
        <h3 className="lesson-list-item__header">
          <span className="lesson-list-item__title">{ lesson.title }</span>
          { " " }
          <span className="lesson-list-item__separator">from</span>
          { " " }
          <span className="lesson-list-item__source-name">{ lesson.sourceName }</span>
        </h3>
        <p className="lesson-list-item__description">{ lesson.description }</p>
        <div className="lesson-list-item__controls">
          <ShareLessonButton
            className="lesson-list-item__vote-button"
            lesson={ lesson }
            treatment="clear"
          />
          <VoteButton
            className="lesson-list-item__vote-button"
            lesson={ lesson }
            type={ LIKED_VOTE }
          />
          <VoteButton
            className="lesson-list-item__vote-button"
            lesson={ lesson }
            type={ DISLIKED_VOTE }
          />
          {
            lesson.creatorId === user?.id && isDesktop
              ? <Button
                icon="pencil"
                text="Edit"
                routeName={ EDIT_LESSON_ROUTE }
                params={ { lessonId: lesson.id } }
                treatment="clear"
              />
              : null
          }
        </div>
      </div>
    </RouterLink>
  </li>;
});

LessonListItem.displayName = "LessonListItem";

LessonListItem.propTypes = {
  lesson: PropTypes.instanceOf(LessonStore).isRequired
};
