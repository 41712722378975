
import React from 'react';


export const attributes = {};
export default function() {
  return (
    <div>
      <h1>Cookie Policy</h1>
<p><em>Last updated: June 9, 2021</em></p>
<p>Our <a href="/privacy-policy">Privacy Policy</a> explains our principles when it comes to the
collection, processing, and storage of your information. This policy specifically explains how we,
our partners, and users of our services deploy cookies, as well as the options you have to control
them.</p>
<h3>What are cookies?</h3>
<p>Cookies are small pieces of data, stored in text files, that are stored on your computer or other
device when websites are loaded in a browser. They are widely used to &quot;remember&quot; you and your
preferences, either for a single visit (through a &quot;session cookie&quot;) or for multiple repeat visits
(using a &quot;persistent cookie&quot;). They ensure a consistent and efficient experience for visitors, and
perform essential functions such as allowing users to register and remain logged in. Cookies may be
set by the site that you are visiting (known as &quot;first party cookies&quot;), or by third parties, such as
those who serve content or provide advertising or analytics services on the website (&quot;third party
cookies&quot;). Both websites and HTML emails may also contain other tracking technologies such as &quot;web
beacons&quot; or &quot;pixels.&quot; These are typically small transparent images that provide us with statistics,
for similar purposes as cookies. They are often used in conjunction with cookies, though they are
not stored on your computer in the same way. As a result, if you disable cookies, web beacons may
still load, but their functionality will be restricted.</p>
<h3><strong>How we use cookies</strong></h3>
<p>We use cookies for a number of different purposes. Some cookies are necessary for technical reasons;
some enable a personalized experience for both visitors and registered users; and some allow the
display of advertising from selected third party networks. Some of these cookies may be set when a
page is loaded, or when a visitor takes a particular action (clicking the &quot;like&quot; or &quot;practice&quot;
button on a post, for example). Many of the cookies we use are only set if you are a registered
Chessercise user (so you don’t have to log in every time, for example), while others are set
whenever you visit one of our websites, irrespective of whether you have an account. For more
information on the choices you have about the cookies we use, please see the Controlling Cookies
section below.</p>
<h3><strong>Types of Cookie</strong></h3>
<p>The list below explains the types of cookies we use on our websites and why we use them.</p>
<ul>
<li><strong>Required</strong>: These cookies are essential for our websites and services to perform basic functions
and are necessary for us to operate certain features. These include those required to allow
registered users to authenticate and perform account-related functions, store preferences set by
users such as account name, language, and location, and ensure our services are operating
properly.</li>
<li><strong>Analytics and Performance</strong>: These cookies allow us to optimize performance by collecting
information on how users interact with our websites, including which pages are visited most, as
well as other analytical data. We use these details to improve how our websites function and to
understand how users interact with them.</li>
<li><strong>Advertising</strong>: These cookies are set by us and our advertising partners to provide you with
relevant content and to understand that content’s effectiveness. They may be used to collect
information about your online activities over time and across different websites to predict your
preferences and to display more relevant advertisements to you. These cookies also allow a profile
to be built about you and your interests and enable personalized ads to be shown to you based on
your profile.</li>
</ul>
<p>Please note that this section is not intended to be exhaustive, but rather aims to be
representative, and that our users may set additional cookies (such as Google Analytics), depending
on the features they have chosen to enable or the plugins they have installed.</p>
<h3>Controlling Cookies</h3>
<p>Visitors may wish to restrict the use of cookies or completely prevent them from being set. Most
browsers provide for ways to control cookie behavior such as the length of time they are stored –
either through built-in functionality or by utilizing third party plugins. If you disable cookies,
please be aware that some of the features of our service may not function correctly. To find out
more on how to manage and delete cookies, visit aboutcookies.org. For more details on your choices
regarding use of your web browsing activity for interest-based advertising visit
<a href="http://youronlinechoices.eu">youronlinechoices.eu</a> (EU based) or
<a href="http://optout.aboutads.info">optout.aboutads.info</a> (US based).</p>
<p>On a mobile device, you may also be to adjust your settings to limit tracking.</p>
<p>Some analytics services we use, which place their own cookies or web tags on your browser, offer
their own opt out choices. For example, you can opt out of Google Analytics by installing <a href="https://tools.google.com/dlpage/gaoptout">Google’s
opt-out browser add-on</a>, from Hotjar by using the <a href="https://www.hotjar.com/legal/compliance/opt-out">Do Not
Track header</a>, and from Nielsen by clicking the opt
out link found within <a href="https://www.nielsen.com/us/en/legal/privacy-statement/digital-measurement/">Nielsen's Privacy
Statement</a>.</p>
<h3>Consent for Advertising Cookies on Our Sites</h3>
<p>You may see a “cookie banner” on our websites and dashboards. If you are visiting one of our sites
from the EU, then we do not set, or allow our ad partners to set, cookies that are used to show you
targeted ads before you click to accept. When you consent in this manner, we and our advertising
partners may set advertising cookies on the site you are visiting and on our other websites,
dashboards, and services, which we use to show you advertisements about our various Automattic
products. We’ll display the banner to you periodically, just in case you change your mind.</p>
<h3><strong>Consent and Choices for Advertisements on Our Users’ Sites Through Our Ads Program</strong></h3>
<p>You may see a “cookie banner” on our users’ websites, particularly if you are in the European Union.
If so, then our ad program does not set, or allow our ad partners to set, cookies that are used to
show you targeted ads before you accept.</p>
<p>When you consent in this manner, we and our advertising partners may set third party advertising
cookies in order to show you personalized ads on the website you are visiting.</p>
<h3>Contact Us</h3>
<p>If you have any questions about our use of cookies, you can find the most relevant contact details
in our <a href="/privacy-policy">Privacy Policy</a>.</p>
<h2>Attribution</h2>
<p>This policy was adapted from open source legal documents from <a href="https://github.com/Automattic/legalmattic">Automattic
Legalmattic</a> under the <a href="https://creativecommons.org/licenses/by-sa/4.0/">CC BY-SA
4.0</a> license and the <a href="https://github.com/basecamp/policies">Basecamp open-source
policies</a> under the <a href="https://creativecommons.org/licenses/by/4.0/">CC BY
4.0</a> license.</p>

    </div>
  );
};