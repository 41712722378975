import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";

import {
  BISHOP,
  BLACK,
  KING,
  KNIGHT,
  PAWN,
  PLAYERS,
  QUEEN,
  ROOK,
  SQUARE_ASPECT_RATIO,
  WHITE
} from "../../constants";
import { ChessboardCoordinates } from "./chessboard-coordinates";
import { ChessboardStore } from "../../stores/chessboard-store";
import { AspectRatioContainer } from "../shared/aspect-ratio-container";
import backgroundImage from "../../../images/chessboard.svg";
import chessboardSprite from "../../../images/chessboard-sprite.svg";
import { convertSquareToIndices } from "../../utilities/squares";

// The piece size is set to the same size as the chessboard sprite's viewport.
const PIECE_SIZE = 40;
const BOARD_SIZE = PIECE_SIZE * 8;

const PIECE_IDS = {
  [BLACK]: {
    [KING]: "bk",
    [QUEEN]: "bq",
    [ROOK]: "br",
    [BISHOP]: "bb",
    [KNIGHT]: "bn",
    [PAWN]: "bp"
  },
  [WHITE]: {
    [KING]: "wk",
    [QUEEN]: "wq",
    [ROOK]: "wr",
    [BISHOP]: "wb",
    [KNIGHT]: "wn",
    [PAWN]: "wp"
  }
};

function squareToCoordinates(square, orientation) {

  // Convert the squares to SVG coordinate space.
  let coordinates = convertSquareToIndices(square, orientation);
  return [ coordinates[0] * PIECE_SIZE, coordinates[1] * PIECE_SIZE ];
}

/**
 * This component is a non-interactive chessboard that looks exactly like the regular Chessboard
 * component but only renders the chessboard's current position.
 */
export function StaticChessboard({ chessboard, orientation, className }) {
  return <AspectRatioContainer
    className={ classNames("static-chessboard", className) }
    aspectRatio={ SQUARE_ASPECT_RATIO }
  >
    <ChessboardCoordinates chessboard={ chessboard } orientation={ orientation }>
      <svg className="static-chessboard__svg" viewBox={ `0 0 ${ BOARD_SIZE } ${ BOARD_SIZE }` }>
        <image
          x={ 0 }
          y={ 0 }
          width={ BOARD_SIZE }
          height={ BOARD_SIZE }
          href={ backgroundImage }
        />
        {
          chessboard.pieces.map(({ square, color, piece }) => {
            let [ x, y ] = squareToCoordinates(square, orientation);

            return <use
              key={ square }
              xlinkHref={ `${ chessboardSprite }#${ PIECE_IDS[color][piece] }` }
              x={ x }
              y={ y }
            />;
          })
        }

      </svg>
    </ChessboardCoordinates>
  </AspectRatioContainer>;
}

StaticChessboard.propTypes = {
  chessboard: PropTypes.instanceOf(ChessboardStore),
  orientation: PropTypes.oneOf(PLAYERS).isRequired,
  className: PropTypes.string
};
