
import React from 'react';


export const attributes = {};
export default function() {
  return (
    <div>
      <h1>Terms of Service</h1>
<p><em>Last updated: June 9, 2021</em></p>
<p>Please print and keep a copy of this Agreement. If you do not agree to abide by the terms and
conditions of this Agreement, please do not become a user of Chessercise. Use of the software
constitutes acceptance of this agreement.</p>
<p>When we say “Company”, “we”, “our”, or “us” in this document, we are referring to Landon Schropp,
LLC, doing business as Chessercise.</p>
<p>When we say “Services”, we mean any product created and maintained by Landon Schropp, LLC. That
includes Chessercise, whether delivered within a web browser, desktop application, mobile
application, or another format.</p>
<p>When we say “You” or “your”, we are referring to the people or organizations that own an account
with one or more of our Services.</p>
<p>We may update these Terms of Service in the future.  Typically these changes have been
to clarify some of these terms by linking to an expanded related policy. Whenever we make a
significant change to our policies, we will also announce them via an email update.</p>
<p>When you use our Services, now or in the future, you are agreeing to the latest Terms of Service.
That's true for any of our existing and future products and all features that we add to our Services
over time. There may be times where we do not exercise or enforce any right or provision of the
Terms of Service; in doing so, we are not waiving that right or provision. <strong>These terms do contain
a limitation of our liability.</strong></p>
<p>Chessercise is not directed to children. Access to and use of Chessercise is only for those over the
age of 13 (or 16 in the European Union). If you are younger than this, you may not register for or
use Chessercise. Any person who registers as a user or provides their personal information
represents that they are 13 years of age or older (16 years or older in the European Union).</p>
<p>If you violate any of the terms, we may terminate your account.</p>
<h2>Account Terms</h2>
<ol>
<li>You are responsible for maintaining the security of your account and password. The Company cannot
and will not be liable for any loss or damage from your failure to comply with this security
obligation.</li>
<li>You may not use the Services for any purpose outlined in our Prohibited Uses.</li>
<li>You are responsible for all content posted and activity that occurs under your account. That
includes content posted by others who either: (a) have access to your login credentials; or (b)
have their own logins under your account.</li>
<li>You must be a human. Accounts registered by “bots” or other automated methods are not permitted.</li>
</ol>
<h2>Copyright and Ownership of the Information, Materials and Software</h2>
<p>You expressly acknowledge and agree that the software contains information, text, software, photos,
video, graphics, music, sounds and other material (called &quot;Materials&quot;) that are protected by
copyrights, trademarks, trade secrets, patents or other proprietary rights (called &quot;Intellectual
Property Rights&quot;), that these Intellectual Property Rights are valid and protected in all forms,
media and technologies existing now or developed later, and that all right, title and interest in
and to these Materials, including but not limited to all Intellectual Property Rights, belong solely
and exclusively to the owners and creators of Chessercise.</p>
<ol>
<li>All content posted on the Services must comply with U.S. copyright law. We provide details on
<a href="/copyright">how to file a copyright infringement claim</a>.</li>
<li>We claim no intellectual property rights over the material you provide to the Services. All
materials uploaded remain yours.</li>
<li>We do not pre-screen content, but reserve the right (but not the obligation) in our sole
discretion to refuse or remove any content that is available via the Service.</li>
<li>The names, look, and feel of the Services are copyright© to the Company. All rights reserved. You
may not duplicate, copy, or reuse any portion of the HTML, CSS, JavaScript, or visual design
elements without express written permission from the Company. You must request permission to use
the Company's logo or any Service logos for promotional purposes. Please <a href="mailto:hello@chessercise.xyz">email
us</a> requests to use logos. We reserve the right to rescind this
permission if you violate these Terms of Service.</li>
<li>You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Services,
use of the Services, or access to the Services without the express written permission by the
Company.</li>
<li>You must not modify another website so as to falsely imply that it is associated with the
Services or the Company.</li>
</ol>
<h2>Use of the Information and Materials of the Software</h2>
<p>The license granted herein is nonexclusive, personal to you and nontransferable.</p>
<ul>
<li><p>Except as you may be expressly permitted by this Agreement, you may not use, modify, adapt,
reformat, download, upload, post, reproduce, broadcast, publish, display, perform, transfer or
redistribute any Materials in any form, format or media or by means of any technology without
obtaining the prior written authorization of the owners and creators of Chessercise and any other
owner of the Intellectual Property Rights in such Materials.</p></li>
<li><p>You may, on an occasional and irregular basis, include insubstantial portions of Materials from
the website in memoranda, reports and presentations, and then only to the extent that such use
constitutes &quot;fair use&quot; under applicable copyright and intellectual property law, provided that in
each instance you include in all such memoranda, reports and presentations all copyright,
trademark and other notices from the website and the information used, original source
attribution, and the phrase &quot;Used with permission from the owners and creators of Chessercise&quot;
except for such other Materials which have been incorporated into Chessercise by reference and as
such require permission from the owners of such Materials.</p></li>
<li><p>If you reproduce Materials from the software, as permitted by this Agreement, you must preserve
any copyright, trademark or other notices contained in or associated with them. This means, among
other things, that if the specific Material you are reproducing does not contain the relevant
notices that appear on the website, you must go to the place on the website where such notices
appear and copy them into the Materials you are reproducing.</p></li>
<li><p>All copyrights and copyrightable materials which are a part of Chessercise including, without
limitation, the Chessercise logos, designs, text, graphics, pictures, photos, files, software,
applications, code, hidden text, databases and other files and the selection and arrangement
thereof are COPYRIGHT © 2021 Landon Schropp, LLC ALL RIGHTS RESERVED.</p></li>
<li><p>Except as stated herein, none of the Materials may be copied, reproduced, distributed,
republished, transferred, sold, used to create derivative works, performed, downloaded, displayed,
posted or transmitted in any form or by any means, including, but not limited to, electronic,
mechanical, photocopying, recording, or otherwise, without the prior written consent of
Landon Schropp, LLC or the respective copyright owner. Software may not be reverse engineered
unless specifically authorized by the owner of the software's patent and/or copyright.</p></li>
<li><p>Any unauthorized use of intellectual property will terminate the user's subscription and violate
applicable law. All violations will be prosecuted and all infringing parties will be pursued to
the full extent of the law. If you wish to request permission to reproduce Materials from the
website for other than the specifically authorized educational purpose, or if you have any
questions about the proper way to include such notices, contact the owners and creators of
Chessercise.</p></li>
</ul>
<h2>This Agreement</h2>
<p>This Agreement is the entire agreement between the owners and creators of Chessercise and you with
respect to the software. Headings in this Agreement are for your convenience only and do not have
any legal meaning or effect. If any part of this Agreement is invalid, the rest of this Agreement
will remain in effect. If the owners and creators of Chessercise waive or fails to enforce any term
or condition of this Agreement on any one or more occasions, whether by conduct or otherwise, its
waiver or failure to enforce will not mean that it must waive or cannot enforce such term or
condition on any other occasion, or any other term or condition of this Agreement. The meaning of
this Agreement cannot be changed by your or Landon Schropp, LLC's conduct, even if repeated, or by
any custom or practice of others engaged in the same or similar businesses.</p>
<h2>Prohibited Uses</h2>
<p>You agree NOT to use the Chessercise software for any of the following purposes or activities:</p>
<ul>
<li><strong>Unlawful material</strong>: The creation, transmission of or posting of any message, data, information,
text, software, graphics, files, materials or other Content that is unlawful, libelous,
defamatory, obscene, pornographic, indecent, harassing, threatening harmful, invasive of privacy
or publicity rights; or to violate any applicable laws, statutes or regulations.</li>
<li><strong>Spamming</strong>: In connection with any pyramid schemes, chain letters, junk email, spamming or any
duplicative or unsolicited messages.</li>
<li><strong>Violating privacy</strong>: To violate another's privacy.</li>
<li><strong>Violence, or threats thereof</strong>: If an activity qualifies as violent crime in the United States
or where you live, you may not use Chessercise products to plan, perpetrate, or threaten that
activity.</li>
<li><strong>Child exploitation, sexualization, or abuse</strong>: We don’t tolerate any activities that create,
disseminate, or otherwise cause child abuse. Keep away and stop. Just stop.</li>
<li><strong>Hate speech</strong>: You cannot use our products to advocate for the extermination, domination, or
oppression of people.</li>
<li><strong>Harassment</strong>: Intimidating or targeting people or groups through repeated communication,
including using racial slurs or dehumanizing language, is not welcome at Chessercise.</li>
<li><strong>Doxing</strong>: If you are using Chessercise products to share other peoples’ private personal
information for the purposes of harassment, we don’t want anything to do with you.</li>
<li><strong>Malware or spyware</strong>: To transmit or upload any material that contains viruses, trojan horses,
worms, time bombs, cancelbots, or any other harmful, destructive or deleterious programs;</li>
<li><strong>Phishing or otherwise attempting fraud</strong>: It is not okay to lie about who you are or who you
affiliate with to steal from, extort, or otherwise harm others.</li>
<li><strong>Spamming</strong>: No one wants unsolicited commercial emails. We don’t tolerate folks (including their
bots) using Chessercise products for spamming purposes. If your emails don’t pass muster with
<a href="https://www.ftc.gov/tips-advice/business-center/guidance/can-spam-act-compliance-guide-business">CAN-SPAM</a>
or any other anti-spam law, it’s not allowed.</li>
<li><strong>Cybersquatting</strong>: We don’t like username extortionists. If you purchase a Chessercise product
account in someone else’s name and then try to sell that account to them, you are
<a href="https://www.law.cornell.edu/uscode/text/15/1125">cybersquatting</a>. Cybersquatting accounts are
subject to immediate cancellation.</li>
<li><strong>Infringing on intellectual property</strong>: You can’t use Chessercise products to make or disseminate
work that uses the intellectual property of others beyond the bounds of <a href="https://www.copyright.gov/fair-use/more-info.html">fair
use</a>.</li>
<li><strong>Network interference</strong>: Interference with or disruption to the software's services or network,
including, but not limited to, attempting to gain unauthorized access to the Service, other
accounts, computer systems or networks connected to the Service, through password mining or any
other means;</li>
<li><strong>Monitoring or copying content</strong>: To use any robot, spider, or other automatic device, or manual
process to monitor or copy Chessercise's content without Landon Schropp, LLC's prior express
written permission;</li>
<li><strong>Pornography</strong>: To create, duplicate or transmit any pornography</li>
<li><strong>Linking</strong>: To post a link, address or other contact to any site which is for any of the above
purposes.</li>
</ul>
<p>While our prohibited uses are comprehensive, they can’t be exhaustive — it’s possible an offense
could defy categorization, present for the first time, or illuminate a moral quandary we hadn’t yet
considered. That said, we hope the overarching spirit is clear: Chessercise is not to be harnessed
for harm, whether mental, physical, personal or civic. Different points of view — philosophical,
religious, and political — are welcome, but ideologies like white nationalism, or hate-fueled
movements anchored by oppression, violence, abuse, extermination, or domination of one group over
another, will not be accepted here.</p>
<h2>Features and Bugs</h2>
<p>We design our Services with care, based on our own experience and the experiences of customers who
share their time and feedback. However, there is no such thing as a service that pleases everybody.
We make no guarantees that our Services will meet your specific requirements or expectations.</p>
<p>We also test all of our features extensively before shipping them. As with any software, our
Services inevitably have some bugs. We track the bugs reported to us and work through priority ones,
especially any related to security or privacy. Not all reported bugs will get fixed and we don't
guarantee completely error-free Services.</p>
<h2>Termination of this Agreement</h2>
<p>The owners and creators of Chessercise may, in their sole and absolute discretion, and with or
without notice, suspend or terminate your access to the software, or terminate this Agreement and
thus your rights to use the software, for any conduct or use (whether by you or anyone else using
your version of the software) that the owners and creators of Chessercise believe is a violation of
this Agreement or for any other conduct or use that the owners and creators of Chessercise believes
is harmful to other users or to the website or the interests of the owners and creators of
Chessercise.</p>
<h2>Disclaimer of Warranties</h2>
<ul>
<li><p>THE SOFTWARE AND ALL INFORMATION, MATERIALS AND WEBSITES ACCESSIBLE THROUGH THE SOFTWARE ARE
PROVIDED ON AN &quot;AS-IS,&quot; &quot;AS-AVAILABLE&quot; BASIS. THERE ARE NO WARRANTIES, EXPRESSED OR IMPLIED, BY
OPERATION OF LAW OR OTHERWISE, REGARDING OR RELATING TO PRODUCTS, SOFTWARE OR WEBSITES FURNISHED
TO SUBSCRIBERS UNDER THIS AGREEMENT OR IN CONNECTION WITH THE SOFTWARE. LANDON SCHROPP, LLC
EXPRESSLY DISCLAIM ALL WARRANTIES, EXPRESSED AND IMPLIED, INCLUDING, BUT NOT LIMITED TO,
WARRANTIES OF TITLE AND NON-INFRINGEMENT AND THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS
FOR A PARTICULAR PURPOSE. SOME STATES DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE
ABOVE EXCLUSION MAY NOT APPLY TO YOU.</p></li>
<li><p>You expressly agree that your use of the software is at your sole risk. The owners and creators of
Chessercise do not warrant that the software will be uninterrupted or error free; nor does it make
any warranty as to the results that may be obtained from your use of the software, or as to the
accuracy, reliability, completeness, or content of any information, website, software or
merchandise that may be provided through the software. No statement, information or advice,
including, but not limited to, statements regarding capacity, suitability for use or performance,
whether made by the owners and creators of Chessercise, employees, reseller or other
representative or otherwise, which is not contained in this agreement shall be deemed to be a
warranty by the owners and creators of Chessercise for any purpose or give rise to any liability
of Chessercise whatsoever, and you agree that you will not rely on any such statement, information
or advice.</p></li>
<li><p>Where the owners and creators of Chessercise are a distributor, rather than a publisher, of
Materials accessible through the software, the owners and creators of Chessercise exercise no more
editorial control over such Materials than does a typical public library, bookstore or newsstand.
The views and opinions expressed in such information do not necessarily reflect those of the
owners and creators of Chessercise or its content providers or licensors. Neither the owners nor
creators of Chessercise nor its content providers or licensors make any warranties or
representations regarding the accuracy, adequacy, truthfulness, completeness, or usefulness of
such information.</p></li>
<li><p>Landon Schropp, LLC reserves the right, at its sole discretion, to modify or replace any
part of this Agreement. It is your responsibility to check this Agreement periodically for
changes. Your continued use of the software following the posting of any changes to this Agreement
constitutes acceptance of those changes. Chessercise may also, in the future, offer new services
and/or features in the software (including, the release of new tools and resources). Such new
features and/or services shall be subject to the terms and conditions of this Agreement.</p></li>
</ul>
<h2>Limitation of Liability</h2>
<ul>
<li><p>UNDER NO CIRCUMSTANCES, INCLUDING NEGLIGENCE, SHALL Landon Schropp, LLC BE LIABLE TO YOU OR ANYONE
ELSE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, EXEMPLARY OR CONSEQUENTIAL DAMAGES
(INCLUDING DAMAGES THAT ARISE FROM LOSS OF ANTICIPATED REVENUE, LOSS OF INFORMATION OR MATERIAL OF
ANY KIND, LOST PROFITS, LOSS OF BUSINESS AND INJURY TO PROPERTY, EVEN IF Landon Schropp, LLC  HAS
BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES) THAT ARISE IN CONNECTION WITH OR RESULT FROM YOUR
USE OF OR INABILITY TO USE THE SOFTWARE INCLUDING, BUT NOT LIMITED TO, YOUR RELIANCE ON ANY
MATERIALS, CONTENT, INFORMATION, WEBSITES, SOFTWARE OR PRODUCTS OBTAINED IN THE SOFTWARE, OR
MISTAKES, OMISSIONS, INTERRUPTIONS, COMMUNICATIONS FAILURE, DELETION OF FILES OR E-MAIL, ERRORS,
DEFECTS, VIRUSES, TROJAN HORSES, DELAYS IN OPERATION OR TRANSMISSION, OR ANY FAILURE OF
PERFORMANCE, OR THEFT, DESTRUCTION OR UNAUTHORIZED ACCESS TO YOUR RECORDS, PROGRAMS, WEBSITES OR
SOFTWARE. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR
INCIDENTAL DAMAGES, AND IN SUCH STATES Landon Schropp, LLC'S LIMITED TO THE GREATEST EXTENT
PERMITTED BY LAW.</p></li>
<li><p>If, notwithstanding the foregoing, the owners and creators of Chessercise were to be held liable
for damages, then regardless of the form of action (whether in contract, tort, breach of warranty
or otherwise), in no event shall the maximum liability of the owners and creators of Chessercise
and the parties described in the next sentence (Landon Schropp LLC's &quot;Related Parties&quot;) exceed,
either jointly or severally, the aggregate dollar amount paid by you to the owners and creators of
Chessercise in the twelve (12) months prior to the claimed injury or damage. All of the provisions
of this section are also for the benefit of the owners and creators of Chessercise's directors,
officers, shareholders, employees, affiliates, agents, third-party content providers, licensors
and the like, and anyone else involved in creating, producing or distributing the software, and
each shall have the right to assert and enforce the provisions directly on their own behalf.</p></li>
<li><p>The resources on website's that can be accessed with hypertext links from or using Chessercise are
not maintained by Landon Schropp, LLC, and Landon Schropp, LLC is not responsible for the
availability or accuracy or such sites or resources, or the content, advertising, or products on
or available from such sites or resources. Such sites may be governed by legal notices and privacy
policies, which differ from those of this software.</p></li>
</ul>
<h2>Indemnification by You</h2>
<p>You agree to defend, indemnify and hold harmless Landon Schropp, LLC and its Related Parties from
any claims and expenses, including reasonable attorney's fees, related to any violation of this
Agreement by your use of the software, or in connection with the placement or transmission by you of
any material using the software except as expressly authorized in this Agreement.</p>
<h2>Notices</h2>
<p>Landon Schropp, LLC may give notice to you by electronic mail. Notice by the
owners and creators of Chessercise shall be effective on the date that the owners and creators of
Chessercise make a good faith effort to reach you. You shall give notice to the owners and creators
of Chessercise as stated below. Notice by you shall be effective on the date that the owners and
creators of Chessercise actually receive the notice.</p>
<h2>Privacy Information</h2>
<p>By using the software, you are agreeing that the owners and creators of Chessercise may collect,
use, sell, license and otherwise distribute demographic data about users in aggregated form (so that
no user is personally identifiable) to assist in market evaluation and for other purposes as
reasonably determined by the owners and creators of Chessercise.</p>
<h2>Contact Information</h2>
<p>For further information or support regarding this web site, you are encouraged to contact the owners
at <a href="mailto:hello@chessercise.xyz">hello@chessercise.xyz</a>.</p>
<h2>Payment and Renewal</h2>
<ul>
<li><p>By selecting an Upgrade you agree to pay Landon Schropp, LLC the monthly or annual subscription
fees indicated for that service. Payments will be charged on a pre-pay basis on the day you sign
up for an Upgrade and will cover the use of that service for a monthly or annual subscription
period as indicated.</p></li>
<li><p>Unless you notify Landon Schropp, LLC before the end of the applicable subscription period that
you want to cancel an Upgrade, your Upgrade subscription will automatically renew and you
authorize us to collect the then-applicable annual or monthly subscription fee for such Upgrade
(as well as any taxes) using any credit card or other payment mechanism we have on record for you.
Upgrades can be canceled at any time in the account page.</p></li>
<li><p>Chessercise will have no obligation to provide a refund of upgrade fees.</p></li>
</ul>
<h2>Miscellaneous</h2>
<ul>
<li><p>This Agreement is personal to you, and you may not assign, transfer or delegate your rights or
obligations to anyone.</p></li>
<li><p>In the event that any provision of this Agreement is held to be invalid or unenforceable, pursuant
to judicial decree or decision, the remainder of this Agreement shall remain valid and enforceable
according to its terms.</p></li>
<li><p>You acknowledge that the owners and creators of Chessercise will be irreparably harmed by any
breach of this Agreement by you or by your unauthorized use of the Materials or the website and,
further, that monetary damages may not be a sufficient remedy for such harm. You agree that the
owners and creators of Chessercise shall be entitled, without waiving any other rights or remedies
and without further demonstration of irreparable harm or the inadequacy of monetary damages, to
obtain injunctive or other equitable relief in the event of any breach of this Agreement by you or
by your unauthorized use of the Materials or the software.</p></li>
</ul>
<h2>Attribution</h2>
<p>This policy was adapted from open source legal documents from <a href="https://github.com/Automattic/legalmattic">Automattic
Legalmattic</a> under the <a href="https://creativecommons.org/licenses/by-sa/4.0/">CC BY-SA
4.0</a> license and the <a href="https://github.com/basecamp/policies">Basecamp open-source
policies</a> under the <a href="https://creativecommons.org/licenses/by/4.0/">CC BY
4.0</a> license.</p>

    </div>
  );
};