import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";
import classNames from "classnames";

import { Icon } from "../shared/icon";
import { PIECES } from "../../constants";

const NUMBER_OF_PIECES = 31;

export function RainingChessPieces({ className }) {

  return <div className={ classNames(className, "raining-chess-pieces") }>
    {
      _.times(NUMBER_OF_PIECES, index => {
        let icon = PIECES[index % PIECES.length];

        return <Icon
          className="raining-chess-pieces__piece"
          name={ `${ icon }Alt` }
          key={ index }
        />;
      })
    }
  </div>;
}

RainingChessPieces.propTypes = {
  className: PropTypes.string
};
