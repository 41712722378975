import { observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import { browserHistory as history } from "mobx-state-router";

import { Button } from "./button";
import {
  FORBIDDEN_ERROR,
  NOT_FOUND_ERROR,
  UNAUTHORIZED_ERROR
} from "../../constants";
import { IconModal } from "../shared/icon-modal";
import { useStore } from "../../stores/store-provider";
import { ErrorStore } from "../../stores/error-store";
import { LOG_IN_ROUTE } from "../../stores/routes";

function errorIcon(type) {
  switch (type) {
    case NOT_FOUND_ERROR:
      return "glassesFilledCircle";
    case FORBIDDEN_ERROR:
      return "lockFilledCircle";
    case UNAUTHORIZED_ERROR:
      return "userFilledCircle";
    default:
      return "exclamationFilledCircle";
  }
}

function errorText(type) {
  switch (type) {
    case NOT_FOUND_ERROR:
      return "The page you were looking for couldn’t be found. If you believe this is an error, " +
        "please report it so we can get it fixed as soon as possible.";
    case FORBIDDEN_ERROR:
      return "It looks like you don’t have permission to access this page. If you believe this " +
        "is an error, please report it so we can fix it as soon as possible.";
    case UNAUTHORIZED_ERROR:
      return "You must be signed in to view this page.";
    default:
      return "Oh no! Something went wrong. Please report the problem and we’ll fix it as soon as " +
        "possible.";
  }
}

const ReportButton = observer(({ error }) => {
  return <Button
    icon="envelope"
    text="Report"
    // TODO: Replace this with an href property on button.
    onClick={ () => window.open(error.reportUrl, "_blank").focus() }
    treatment="secondary"
  />;
});

ReportButton.displayName = "ReportButton";

ReportButton.propTypes = {
  error: PropTypes.instanceOf(ErrorStore).isRequired
};

function GoBackButton({ treatment }) {
  return <Button
    icon="arrowLeft"
    text="Back"
    onClick={ () => history.goBack() }
    treatment={ treatment }
  />;
}

GoBackButton.propTypes = {
  treatment: PropTypes.oneOf([ "primary", "secondary" ])
};

function RefreshButton() {
  return <Button
    icon="redo"
    text="Refresh"
    onClick={ () => location.reload() }
    treatment="primary"
  />;
}

function LogInButton() {
  return <Button
    icon="signIn"
    text="Log In"
    routeName={ LOG_IN_ROUTE }
    queryParams={ { redirectPath: window.location.pathname } }
    treatment="primary"
  />;
}

const ErrorButtons = observer(({ error }) => {
  if (error.type === UNAUTHORIZED_ERROR) {
    return <>
      <GoBackButton treatment="secondary" />
      <LogInButton />
    </>;
  }

  if (error.type === FORBIDDEN_ERROR || error.type === NOT_FOUND_ERROR) {
    return <>
      <ReportButton error={ error } />
      <GoBackButton treatment="primary" />
    </>;
  }

  return <>
    <ReportButton error={ error } />
    <RefreshButton />
  </>;
});

ErrorButtons.displayName = "ErrorButtons";

ErrorButtons.propTypes = {
  error: PropTypes.instanceOf(ErrorStore).isRequired
};

export const ErrorModal = observer(({ className }) => {
  let error = useStore("error");

  return <IconModal
    className={ className }
    icon={ errorIcon(error.type) }
    title={ error.title }
    text={ errorText(error.type) }
    closable={ false }
    open
  >
    <ErrorButtons error={ error } />
  </IconModal>;
});

ErrorModal.displayName = "ErrorModal";

ErrorModal.propTypes = {
  className: PropTypes.string
};
