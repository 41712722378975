import { makeAutoObservable, runInAction } from "mobx";
import { sub } from "date-fns";
import _ from "lodash";

import {
  EDIT_EXERCISE_ROUTE,
  EDIT_LESSON_ROUTE,
  CREATED_LESSONS_ROUTE,
  LIKED_LESSONS_ROUTE,
  LESSONS_HISTORY_ROUTE,
  POPULAR_LESSONS_ROUTE,
  NEW_EXERCISE_ROUTE,
  NEW_LESSON_ROUTE,
  PRACTICE_LESSON_ROUTE,
  ROOT_ROUTE,
  WELCOME_ROUTE
} from "./routes";
import { ExerciseStore } from "./models/exercise-store";
import { LessonStore } from "./models/lesson-store";
import { NotFoundError } from "../utilities/errors";
import {
  POPULAR_LESSONS_FILTER,
  LIKED_LESSONS_FILTER,
  PLAYED_LESSONS_FILTER,
  CREATED_LESSONS_FILTER
} from "../api/api-client";
import { ScoreSummaryStore } from "./models/score-summary-store";

const DEFAULT_PAGE_SIZE = 20;
const WELCOME_PAGE_SIZE = 9;

/**
 * This store houses the router parameters application context. It's responsible for transforming
 * the router parameters into store models.
 */
export class RouterParamsStore {
  routeName = ROOT_ROUTE
  params = {}
  router = null
  exercise = null
  lesson = null
  lessons = null
  page = 0
  lastPageEmpty = false
  likedLessons = null
  playedLessons = null
  createdLessons = null

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * Loads the data for the current route.
   */
  async load() {

    // When navigating from a new page to an edit page of the same content, it's possible for that
    // content to already have been fetched. If that's the case, then there's no need to re-fetch
    // that content.
    if (this._isAlreadyFetched()) {
      return;
    }

    // Reset all of the parameters.
    this.reset();

    // Fetch the parameters.
    switch (this.routeName) {

      case WELCOME_ROUTE:
        return await this._fetchWelcome();

      case LESSONS_HISTORY_ROUTE:
      case CREATED_LESSONS_ROUTE:
      case LIKED_LESSONS_ROUTE:
      case POPULAR_LESSONS_ROUTE:
        return await this._fetchLessons(this.page);

      case NEW_LESSON_ROUTE:
        return this._initializeLesson();

      case EDIT_LESSON_ROUTE:
      case PRACTICE_LESSON_ROUTE:
        return await this._fetchLesson(this.params.lessonId);

      case NEW_EXERCISE_ROUTE:
        return await this._initializeExercise(this.params.lessonId);

      case EDIT_EXERCISE_ROUTE:
        return await this._fetchExercise(this.params.lessonId, this.params.exerciseId);
    }
  }

  /**
   * Loads the next page of the current model for use on infinite-scrolling pages.
   */
  async fetchNextLessonsPage() {
    this.page += 1;
    return this._fetchLessons(this.page);
  }

  async _fetchWelcome() {
    let [ scoreSummaries, likedLessons, playedLessons, createdLessons ] = await Promise.all([
      ScoreSummaryStore.fetchAll({ startDate: sub(new Date(), { days: 6 }), endDate: new Date() }),
      LessonStore.fetchAll({ filter: LIKED_LESSONS_FILTER, page: 0, size: WELCOME_PAGE_SIZE }),
      LessonStore.fetchAll({ filter: PLAYED_LESSONS_FILTER, page: 0, size: WELCOME_PAGE_SIZE }),
      LessonStore.fetchAll({ filter: CREATED_LESSONS_FILTER, page: 0, size: WELCOME_PAGE_SIZE })
    ]);

    runInAction(() => {
      this.scoreSummaries = scoreSummaries;
      this.likedLessons = likedLessons;
      this.playedLessons = playedLessons;
      this.createdLessons = createdLessons;
    });
  }

  async _fetchLessons(page = 0) {
    let filter = _.get({
      [LIKED_LESSONS_ROUTE]: LIKED_LESSONS_FILTER,
      [LESSONS_HISTORY_ROUTE]: PLAYED_LESSONS_FILTER,
      [CREATED_LESSONS_ROUTE]: CREATED_LESSONS_FILTER
    }, this.routeName, POPULAR_LESSONS_FILTER);

    let lessons = await LessonStore.fetchAll({ filter, page, size: DEFAULT_PAGE_SIZE });

    runInAction(() => {
      if (this.lessons) {
        this.lessons.map(lesson => lesson.id);
      }

      this.lessons = _.isNil(this.lessons)
        ? lessons
        : _.uniqBy([ ...this.lessons, ...lessons ], "id");

      this.lastPageEmpty = _.isEmpty(lessons);
    });
  }

  _initializeLesson() {
    this.lesson = new LessonStore();
  }

  async _fetchLesson(lessonId, beforeAssign = _.noop) {

    // Fetch the lesson
    let lesson = await LessonStore.fetch(lessonId);

    // Restart the lesson. This must happen *before* the lesson is assigned, or it can trigger a
    // state update in the components.
    lesson.restartAll();

    // Assign the lesson.
    runInAction(() => this.lesson = lesson);
  }

  async _initializeExercise(lessonId) {
    await this._fetchLesson(lessonId);

    runInAction(() => {
      this.exercise = new ExerciseStore({
        lessonId,
        order: this.lesson.exercises.length
      });
    });
  }

  async _fetchExercise(lessonId, exerciseId) {
    await this._fetchLesson(lessonId);

    // Grab the exercise instance from the lesson.
    let exercise = this.lesson.exercises.find(lessonExercise => lessonExercise.id === exerciseId);

    // Ensure the exercise is contained in the lesson.
    if (_.isNil(exercise)) {
      throw new NotFoundError(
        `The exercise '${ exerciseId }' could not be found in the lesson '${ lessonId }'.`
      );
    }

    runInAction(() => this.exercise = exercise);
  }

  /**
   * Resets all of the data in the router.
   */
  reset() {
    this.page = 0;
    this.lastPageEmpty = false;
    this.exercise = null;
    this.lesson = null;
    this.lessons = null;
    this.likedLessons = null;
    this.playedLessons = null;
    this.createdLessons = null;
  }

  _isAlreadyFetched() {
    switch (this.routeName) {
      case EDIT_LESSON_ROUTE:
        return this.lesson?.id === this.params.lessonId && _.isNil(this.exercise);
      case EDIT_EXERCISE_ROUTE:
        return this.lesson?.id === this.params.lessonId
          && this.exercise?.id === this.params.exerciseId;
      default:
        return false;
    }
  }
}
