import { BORDER_TYPE, Chessboard as CMChessboard, COLOR, INPUT_EVENT_TYPE } from "cm-chessboard";
import { action } from "mobx";
import _ from "lodash";
import classNames from "classnames";

import {
  BLACK,
  CHECK_MARKER,
  DARK_SQUARE,
  LEGAL_MOVE_MARKER,
  TRANSITION_TIME_SMALL,
  WHITE
} from "../../constants";
import { fenPositionsEqual } from "../../utilities/fen";
import { squareColor } from "../../utilities/squares";
import chessboardSprite from "../../../images/chessboard-sprite.svg";

/**
 * This object maps app's colors to the cm-chessboard colors.
 */
let CM_COLORS = {
  [BLACK]: COLOR.black,
  [WHITE]: COLOR.white
};

/**
 * The default options for the cm-chessboard.
 */
const DEFAULT_OPTIONS = {
  responsive: true,
  animationDuration: TRANSITION_TIME_SMALL,
  sprite: {
    url: chessboardSprite
  },
  style: {
    cssClass: "cm-chessboard__svg",
    showCoordinates: false,
    borderType: BORDER_TYPE.none
  }
};

/**
 * The overrides for the default options for mobile.
 */
const MOBILE_OPTIONS = {
  style: {
    showCoordinates: true
  }
};

/**
 * Returns the proper slice of the SVG marker layer in the cm-chessboard.
 */
function markerSlice(type) {
  switch (type) {
    case LEGAL_MOVE_MARKER:
      return "dot";
    case CHECK_MARKER:
      return "outlined-square";
    default:
      return "square";
  }
}

/**
 * Adds a marker to the cm-chessbaord.
 */
function addMarker(cmChessboard, square, type) {
  if (_.isNil(square)) {
    return;
  }

  cmChessboard.addMarker(
    square,
    {
      "class": classNames(
        `chessboard__marker--${ type }`,
        `chessboard__marker--${ squareColor(square) === DARK_SQUARE ? "dark" : "light" }`
      ),
      slice: markerSlice(type)
    }
  );
}

/**
 * Returns a move handler function for cm-chessboard.
 */
function buildHandleMove({ onMoveStart, onMoveDone, onMoveCancel }) {
  return action((event) => {
    switch (event.type) {
      case INPUT_EVENT_TYPE.moveStart:
        return _.isNil(onMoveStart) ? true : onMoveStart(event);
      case INPUT_EVENT_TYPE.moveDone:
        return _.isNil(onMoveDone) ? true : onMoveDone(event);
      case INPUT_EVENT_TYPE.moveCanceled:
        return _.isNil(onMoveCancel) ? true : onMoveCancel(event);
    }
  });
}

/**
 * Updates the cm-chessboard's orientation.
 */
export function updateCMChessboardOrientation({ orientation, cmChessboard }) {

  // BUG FIX: Setting the orientation on every move was breaking animations. To fix this problem,
  // this only changes the orientation if it's different than the current orientation.
  if (cmChessboard.getOrientation() !== CM_COLORS[orientation]) {
    cmChessboard.setOrientation(CM_COLORS[orientation]);
  }
}

/**
 * Adds the markers in the chessboard to the cm-chessboard.
 */
export function updateCMChessboardMarkers({ chessboard, cmChessboard }) {

  // Clear the old markers from the board.
  cmChessboard.removeMarkers();

  // BUG FIX: In order to ensure that the component rerenders when a move is selected or deselected,
  // we have to observe that property. We can't just do this inside of the handleMove callback below
  // because that's not called until a move is actually handled. So we have to explicitly access
  // those properties here.
  chessboard.selectedSquare; // eslint-disable-line no-unused-expressions
  chessboard.selectedSquareLegalMoves; // eslint-disable-line no-unused-expressions

  // Add the markers to the board.
  chessboard.verboseMarkers.forEach(({ square, type }) => addMarker(cmChessboard, square, type));

  // Draw the selected square markers (if there is a selected square and the starting position is
  // not being edited).
  if (chessboard.selectedSquare && !chessboard.editingStartingPosition) {
    chessboard.selectedSquareLegalMoves.forEach(square => {
      addMarker(cmChessboard, square, LEGAL_MOVE_MARKER);
    });
  }
}

/**
 * Updates the position in the cm-chessboard, if necessary. If the position is updated, this
 * function returns a promise. If it's not updated, this function returns null.
 *
 * This function takes an onPositionUpdated callback that will only be called when the position
 * actually changes.
 *
 * The position is provided her because it's possible for the chessboard to display a different FEN
 * than what the chessboard currently contains (such as during a promotion).
 */
export async function updateCMChessboardPosition({
  cmChessboard,
  chessboard,
  onPositionUpdated,
  position
}) {

  // If the FENs are equal, don't update the board's position.
  if (fenPositionsEqual(cmChessboard.getPosition(), position)) {
    return null;
  }

  // Set the position on the board. If the move was an auto move, then animate the position change.
  let positionUpdatedPromise = cmChessboard.setPosition(position);

  // If the last move was an auto move, then use the set position promise.
  if (chessboard.autoMove) {
    await positionUpdatedPromise;
  }

  // Trigger the position callback when the position is updated.
  onPositionUpdated();
}

/**
 * Enabled movement for the chessboard, if necessary.
 */
export function updateCMChessboardMovement({
  chessboard,
  cmChessboard,
  onMove,
  onMoveOutOfBoard,
  allowMovement
}) {

  // Disable move input so we're sure the user can't move the wrong player's color.
  cmChessboard.disableMoveInput();

  // If the player isn't allowed to move, stop here.
  if (!allowMovement) {
    return;
  }

  let handleMove = buildHandleMove({

    onMoveStart(event) {
      document.activeElement?.blur();
      chessboard.selectedSquare = event.square;
      return true;
    },

    onMoveDone({ squareFrom, squareTo }) {
      chessboard.selectedSquare = null;
      return onMove({ from: squareFrom, to: squareTo });
    },

    onMoveCancel(event) {
      let square = chessboard.selectedSquare;
      chessboard.selectedSquare = null;

      if (event.reason !== "movedOutOfBoard") {
        return true;
      }

      // HACK: If the board is currently being edited, immediately remove the piece from the board.
      // This prevents a fade animation for the piece.
      if (chessboard.editingStartingPosition) {
        cmChessboard.setPiece(square, undefined);
      }

      // TODO: I shouldn't need to manually set the square for the event. Remove this when the
      // cm-chessboard bug is fixed. https://github.com/shaack/cm-chessboard/issues/53
      return onMoveOutOfBoard({ ...event, square });
    }
  });

  // Enable movement for the correct players
  let player = chessboard.editingStartingPosition
    ? undefined
    : CM_COLORS[chessboard.currentPlayer];

  cmChessboard.enableMoveInput(handleMove, player);
}

/**
 * Creates a new instance of cm-chessboard using this application's ChessboardStore.
 */
export function createCMChessboard({ element, chessboard, isLessThanTablet }) {
  if (_.isNil(element)) {
    return null;
  }

  let options = _.merge(
    {},
    DEFAULT_OPTIONS,
    isLessThanTablet ? MOBILE_OPTIONS : {},
    { position: chessboard.fen }
  );

  return new CMChessboard(element, options);
}
