import { apiClient } from "../../api/api-client";
import { validateEmail } from "../../validators/validate-email";
import { validatePresence } from "../../validators/validate-presence";
import { FormStore } from "./form-store";

export class ForgotPasswordStore extends FormStore {
  static ATTRIBUTES = [ "email" ]
  static VALIDATORS = { email: [ validatePresence, validateEmail ] }

  email = null

  constructor(attributes) {
    super();
    this.initialize(attributes);
  }

  /**
   * Submits the form.
   */
  async submit() {
    return this.wrapSubmit(async () => await apiClient.sendPasswordReset({ email: this.email }));
  }
}
