import PropTypes from "prop-types";
import React from "react";

import { passwordStrength } from "../../utilities/password";

export function PasswordStrength({ password }) {
  let strength = passwordStrength(password);

  return <div className="password-strength" data-password-strength={ strength }>
    <span className="password-strength__level" />
    <span className="password-strength__level" />
    <span className="password-strength__level" />
    <span className="password-strength__level" />
  </div>;
}

PasswordStrength.propTypes = {
  password: PropTypes.string
};
