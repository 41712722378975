import PropTypes from "prop-types";
import _ from "lodash";

// Board
export const BOARD_SIZE = 8;

// Ranks and files
export const FILES = "abcdefgh".split("");
export const RANKS = "12345678".split("");

// Squares
export const SQUARES = _.flatMap(FILES, file => {
  return _.map(RANKS, rank => `${ file }${ rank }`);
});

// Positions
export const EMPTY_POSITION = "8/8/8/8/8/8/8/8 w KQkq - 0 1";
export const STARTING_POSITION = "rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1";

// Players
export const WHITE = "white";
export const BLACK = "black";
export const PLAYERS = [ WHITE, BLACK ];

// SIDES
export const KINGSIDE = "kingside";
export const QUEENSIDE = "queenside";
export const SIDES = [ KINGSIDE, QUEENSIDE ];

export const playerPropType = PropTypes.oneOf(PLAYERS);

// Pieces
export const PAWN = "pawn";
export const KNIGHT = "knight";
export const BISHOP = "bishop";
export const ROOK = "rook";
export const QUEEN = "queen";
export const KING = "king";

export const PIECES = [
  KING,
  QUEEN,
  ROOK,
  BISHOP,
  KNIGHT,
  PAWN
];

export const piecePropType = PropTypes.oneOf(PIECES);

// Marker types
export const ASSIST_MARKER = "assist";
export const CHECK_MARKER = "check";
export const DRAG_MARKER = "drag";
export const HINT_MARKER = "hint";
export const LAST_MOVE_FROM_MARKER = "lastMoveFrom";
export const LAST_MOVE_TO_MARKER = "lastMoveTo";
export const LEGAL_MOVE_MARKER = "legalMove";
export const SELECTED_MARKER = "selected";
export const USER_MARKER = "user";
export const MISTAKE_MARKER = "mistake";

export const MARKERS = [
  ASSIST_MARKER,
  CHECK_MARKER,
  DRAG_MARKER,
  HINT_MARKER,
  LAST_MOVE_FROM_MARKER,
  LAST_MOVE_TO_MARKER,
  LEGAL_MOVE_MARKER,
  SELECTED_MARKER,
  USER_MARKER,
  MISTAKE_MARKER
];

export const markerPropType = PropTypes.oneOf(MARKERS);

// States
export const ASSIST_STATE = "assist";
export const HINT_STATE = "hint";
export const PERFECT_STATE = "perfect";
export const UNSOLVED_STATE = "unsolved";
export const MISTAKE_STATE = "mistake";
export const CONTENT_STATE = "content";

export const STATES = [
  PERFECT_STATE,
  HINT_STATE,
  ASSIST_STATE,
  MISTAKE_STATE,
  UNSOLVED_STATE,
  CONTENT_STATE
];

export const statePropType = PropTypes.oneOf(STATES);

// Transition times (corresponding to the Sass $transition-time variables)
// These values are all set to 1 millisecond in testing environments.
let isTest = process.env.NODE_ENV === "test";
export const TRANSITION_TIME_LARGE = isTest ? 1 : 60 / 60 * 1000; // 60 frames @ 60fps
export const TRANSITION_TIME_MEDIUM = isTest ? 1 : 30 / 60 * 1000; // 30 frames @ 60fps
export const TRANSITION_TIME_SMALL = isTest ? 1 : 15 / 60 * 1000; // 15 frames @ 60fps
export const TRANSITION_TIME_XSMALL = isTest ? 1 : 8 / 60 * 1000; // 8 frames @ 60fps
export const TRANSITION_TIME_XXSMALL = isTest ? 1 : 4 / 60 * 1000; // 4 frames @ 60fps

// Square colors
export const LIGHT_SQUARE = "lightSquare";
export const DARK_SQUARE = "darkSquare";

export const SQUARE_COLORS = [ LIGHT_SQUARE, DARK_SQUARE ];

export const squareColorPropTypes = PropTypes.oneOf(SQUARE_COLORS);

// Email
export const SUPPORT_EMAIL = "hello@chessercize.xyz";

// Errors
export const BAD_REQUEST_ERROR = "badRequest";
export const FORBIDDEN_ERROR = "forbidden";
export const INTERNAL_ERROR = "internalError";
export const INTERNAL_SERVER_ERROR = "internalServerError";
export const NOT_FOUND_ERROR = "notFound";
export const UNAUTHORIZED_ERROR = "unauthorized";

// Aspect ratios
export const SQUARE_ASPECT_RATIO = 1;
export const VIDEO_ASPECT_RATIO = 16 / 9;
