import { useMediaQuery } from "react-responsive";

import { EDITING_ROUTES } from "../stores/routes";

export const TABLET_MEDIA_QUERY = { minWidth: 640 };
export const DESKTOP_MEDIA_QUERY = { minWidth: 960 };
export const LARGE_DESKTOP_MEDIA_QUERY = { minWidth: 1280 };
export const LESS_THAN_TABLET_MEDIA_QUERY = { maxWidth: 639 };
export const LESS_THAN_DESKTOP_MEDIA_QUERY = { maxWidth: 959 };
const DESKTOP_PRIMARY_NAVIGATION_MEDIA_QUERY = { minWidth: 720 };

export function useTabletMediaQuery() {
  return useMediaQuery(TABLET_MEDIA_QUERY);
}

export function useDesktopMediaQuery() {
  return useMediaQuery(DESKTOP_MEDIA_QUERY);
}

export function useLargeDesktopMediaQuery() {
  return useMediaQuery(LARGE_DESKTOP_MEDIA_QUERY);
}

export function useLessThanTabletMediaQuery() {
  return useMediaQuery(LESS_THAN_TABLET_MEDIA_QUERY);
}

export function useLessThanDesktopMediaQuery() {
  return useMediaQuery(LESS_THAN_DESKTOP_MEDIA_QUERY);
}

// HACK: This media query is specific to the primary navigation.
export function useDesktopPrimaryNavigationMediaQuery() {
  return useMediaQuery(DESKTOP_PRIMARY_NAVIGATION_MEDIA_QUERY);
}

// HACK: This is a workaround to display the mobile editing page. In the future, this should be
// removed.
export function isMobileLessonCreationPageDisplayed(routeName) {
  return EDITING_ROUTES.includes(routeName)
    && window.matchMedia("(max-width: 959px)").matches;
}
