import { runInAction } from "mobx";
import _ from "lodash";

import { ModelStore } from "./model-store";
import { ValidationError } from "../../utilities/errors";
import { uniqueConstraint } from "../../validators/unique-constraint";
import { validateEmail } from "../../validators/validate-email";
import { validatePasswordStrength } from "../../validators/validate-password-strength";
import { validatePresence } from "../../validators/validate-presence";
import { validateMatches } from "../../validators/validate-matches";
import { ScoreStore } from "./score-store";

// NOTE: This regex exactly matches the regex used on the server.
const USERNAME_REGEX = /^[a-z\d]+$/i;

export class UserStore extends ModelStore {
  static RESOURCE_NAME = "user";

  static ATTRIBUTES = [
    "id",
    "username",
    "email",
    "password"
  ]

  static VALIDATORS = {
    username: [ validatePresence, uniqueConstraint, validateMatches(USERNAME_REGEX) ],
    email: [ validatePresence, validateEmail, uniqueConstraint ],
    password: [ validatePresence, validatePasswordStrength ]
  }

  // Attributes
  id = null
  username = null
  email = null
  password = null

  // Constraints
  takenEmails = []
  takenUsernames = []

  constructor(attributes = {}) {
    super();
    this.initialize(attributes);
  }

  // Since we're overriding the setter, we also have to override the getter.
  get attributes() {
    return super.attributes;
  }

  // Override the attributes setter to ignore accessToken and renewalToken.
  set attributes(attributes) {
    super.attributes = _.omit(attributes, "accessToken", "renewalToken");
  }

  // Override the save method to handle constraints.
  async save() {
    try {
      await super.save();
      await ScoreStore.saveStored();
      return this;
    }
    catch (error) {

      // If the error is not a validation error.
      if (!(error instanceof ValidationError)) {
        throw error;
      }

      let hasUniqueError = false;

      // TODO: This logic should be able to display taken emails *and* users.
      if (error.errors?.email?.includes("has already been taken")) {
        hasUniqueError = true;
        runInAction(() => this.takenEmails.push(this.email));
      }

      if (error.errors?.username?.includes("has already been taken")) {
        hasUniqueError = true;
        runInAction(() => this.takenUsernames.push(this.username));
      }

      if (!hasUniqueError) {
        throw error;
      }
    }
  }
}
