import React from "react";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import { LessonStore } from "../../stores/models/lesson-store";
import classNames from "classnames";
import { PracticeText } from "./practice-text";
import { PreviewLesson } from "../lesson/preview-lesson";
import { Button } from "../shared/button";

export const LessonContent = observer(({ className, lesson }) => {
  return <div className={ classNames("lesson-content", className) }>
    <div className="lesson-content__description-container">
      <PracticeText className="lesson-content__description" />
    </div>
    <PreviewLesson className="lesson-content__preview" lesson={ lesson } />
  </div>;
});

LessonContent.displayName = "LessonContent";

LessonContent.propTypes = {
  className: PropTypes.string,
  lesson: PropTypes.instanceOf(LessonStore)
};
