import { observer } from "mobx-react";
import PropTypes from "prop-types";
import React, { useRef } from "react";
import _ from "lodash";
import classNames from "classnames";

import { ChessboardArrow } from "./chessboard-arrow";
import { ChessboardStore } from "../../stores/chessboard-store";
import { USER_MARKER, ASSIST_MARKER, PLAYERS } from "../../constants";
import { squareFromEvent } from "../../utilities/squares";

// Prevent the context menu from being displayed.
function handleContextMenu(event) {
  event.stopPropagation();
  event.preventDefault();
}

// When the mouse goes down, store the starting position of the arrow.
function handleMouseDown(event, startRef, chessboard, orientation) {

  // If the event is not a right click, clear the arrows and return
  if (event.button !== 2) {
    chessboard.clearArrows();
    chessboard.clearMarkers();
    return;
  }

  // Prevent the event from propagating.
  event.stopPropagation();
  event.preventDefault();

  // Store the starting position of the arrow.
  startRef.current = squareFromEvent(event, ".chessboard-arrows", orientation);
}

// If the mouse leaves the area, cancel the arrow. We have to use mouseleave instead of mouseout
// to prevent the child elements' events from bubbling.
function handleMouseLeave(event, startRef) {
  startRef.current = null;
}

// When the mouse is let up, draw an arrow (if the arrow was not cancelled first).
function handleMouseUp(event, startRef, chessboard, orientation) {

  // Ignore the event unless it's a right click.
  if (event.button !== 2) {
    return;
  }

  // Prevent the event from propagating.
  event.stopPropagation();
  event.preventDefault();

  // Ignore the event if the arrow was cancelled.
  if (_.isNil(startRef.current)) {
    return;
  }

  // Determine the marker squares.
  let from = startRef.current;
  let to = squareFromEvent(event, ".chessboard-arrows", orientation);

  // Create the marker or arrow.
  from === to
    ? chessboard.toggleMarker(from, USER_MARKER)
    : chessboard.toggleArrow(from, to, USER_MARKER);
}

/**
 * This component wraps a chessboard and layers arrows on top of it. It's allows user interaction,
 * setting the arrows in the ChessboardStore.
 */
export const ChessboardArrows = observer(({ chessboard, className, children, orientation }) => {
  let startRef = useRef(null);

  return <div
    className={ classNames("chessboard-arrows", className) }
    onContextMenu={ handleContextMenu }
    onMouseDown={ event => handleMouseDown(event, startRef, chessboard, orientation) }
    onMouseLeave={ event => handleMouseLeave(event, startRef) }
    onMouseUp={ event => handleMouseUp(event, startRef, chessboard, orientation) }
  >
    { children }
    {
      _.map(chessboard.arrows, ({ from, to }) => {
        return <ChessboardArrow
          className="chessboard__arrow"
          from={ from }
          to={ to }
          type={ USER_MARKER }
          key={ `${ from },${ to }` }
          orientation={ orientation }
        />;
      })
    }
    {
      _.isNil(chessboard.assist)
        ? null
        : <ChessboardArrow
          className="chessboard__arrow"
          from={ chessboard.assist.from }
          to={ chessboard.assist.to }
          type={ ASSIST_MARKER }
          key="assist-arrow"
          orientation={ orientation }
        />
    }
  </div>;
});

ChessboardArrows.displayName = "ChessboardArrows";

ChessboardArrows.propTypes = {
  chessboard: PropTypes.instanceOf(ChessboardStore).isRequired,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  orientation: PropTypes.oneOf(PLAYERS).isRequired
};
