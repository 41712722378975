import { RouterLink, browserHistory as history } from "mobx-state-router";
import { observer } from "mobx-react";
import React, { useState } from "react";
import _ from "lodash";

import { AttributeInputWithLabel } from "../shared/attribute-input-with-label";
import { AuthenticationPage } from "../authentication/authentication-page";
import { FORGOT_PASSWORD_ROUTE, ROOT_ROUTE, SIGN_UP_ROUTE } from "../../stores/routes";
import { LogInStore } from "../../stores/forms/log-in-store";
import { useStore } from "../../stores/store-provider";

export const LogInPage = observer(() => {
  let [ logIn ] = useState(() => new LogInStore());
  let router = useStore("router");

  let message = <>
    Donʼt have an account?
    { " " }
    <RouterLink routeName={ SIGN_UP_ROUTE }>Sign&nbsp;up</RouterLink>
    &nbsp;for&nbsp;free!
  </>;

  let { redirectPath } = router.routerState.queryParams;

  async function handleSubmit(event) {
    event.preventDefault();
    await logIn.submit();

    if (logIn.isValid) {
      _.isNil(redirectPath)
        ? router.goTo(ROOT_ROUTE)
        : history.push(redirectPath);
    }
  }

  return <AuthenticationPage
    className="log-in-page"
    title="Log In"
    onSubmit={ handleSubmit }
    buttonText="Log In"
    message={ message }
    error={
      logIn.validations.submit
        ? null
        : "The email or password you entered is not correct. Please try again."
    }
  >
    <AttributeInputWithLabel
      label="Email"
      model={ logIn }
      attribute="email"
      type="email"
    />

    <AttributeInputWithLabel
      className="log-in-page__password-input"
      label="Password"
      model={ logIn }
      attribute="password"
      type="password"
    />

    <p className="log-in-page__forgot-password">
      <RouterLink routeName={ FORGOT_PASSWORD_ROUTE } className="log-in-page__forgot-password-link">
        Forgot Password?
      </RouterLink>
    </p>
  </AuthenticationPage>;
});

LogInPage.displayName = "LogInPage";
