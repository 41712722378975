import { runInAction, toJS } from "mobx";
import { BaseStore } from "../base-store";
import _ from "lodash";

/**
 * Defines the form store that can be extended by other forms.
 */
export class FormStore extends BaseStore {
  submitFailed = false
  _submitErrorMessage = null
  _invalidAttributes = []

  /**
   * @inheritdoc
   */
  get errors() {
    let errors = super.errors;

    return {
      ...errors,
      submit: this._submitErrorMessage ? [ this._submitErrorMessage ] : []
    };
  }

  /**
   * Returns true if the current attributes match a previous submit error.
   */
  get _hasSubmitError() {
    return this._invalidAttributes.find(attributes => _.isEqual(this.attributes, attributes));
  }

  /**
   * @inheritdoc
   */
  get isValid() {
    let errors = {
      ..._.omit(toJS(this.errors), "submit"),
      submit: this._hasSubmitError ? [ "submit" ] : []
    };

    return _.isEmpty(_.flatten(_.values(errors)));
  }

  /**
   * Calls the submit function, wrapping it in error-handling behavior. This function is only
   * intended to be used by other form stores.
   * @param submitFunction The function to run.
   * @param submitErrorFunction This function is called if the submit function rejects. If it
   * returns a string, the error message is set to the string. Otherwise, the error is thrown.
   * @return Returns the result of the submit function.
   */
  async wrapSubmit(submitFunction, submitErrorFunction = _.noop) {
    if (!this.isValid) {
      this.submitFailed = true;
      return;
    }

    try {
      let result = await submitFunction();
      runInAction(() => this.submitFailed = false);
      return result;
    }
    catch (error) {
      runInAction(() => this.submitFailed = true);

      let errorMessage = submitErrorFunction(error);

      if (_.isNil(errorMessage)) {
        throw error;
      }
      else {
        runInAction(() => {
          this._invalidAttributes.push(this.attributes);
          this._submitErrorMessage = errorMessage;
        });
      }
    }
  }
}
