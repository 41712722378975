import { isBlank } from "voca";
import { action } from "mobx";
import { observer, useLocalObservable } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";

import { LessonStore } from "../../stores/models/lesson-store";
import { ExerciseStore } from "../../stores/models/exercise-store";
import { InputWithLabel } from "./input-with-label";
import { parseDuration, prettyPrintDuration } from "../../utilities/duration";

/* eslint-disable react/no-this-in-sfc */

// TODO: If this pattern of serialization and deserialization repeats itself, extract it into a new
// component.
export const TimeInputWithLabel = observer(({ label, model, attribute, disabled }) => {

  const localObservable = useLocalObservable(() => ({
    value: prettyPrintDuration(model[attribute]),
    focused: false,

    get isValid() {
      return isBlank(this.value) || !_.isNil(parseDuration(this.value));
    },

    get errors() {

      // An empty string is used here to display the error without a message
      return this.isValid ? [] : [ "" ];
    },

    handleBlur() {
      this.focused = false;

      if (this.isValid) {
        this._pushValue();
        this._pullValue();
      }
    },

    handleChange({ target }) {
      this.value = target.value;

      if (this.isValid) {
        this._pushValue();
      }
    },

    _pushValue() {
      model[attribute] = parseDuration(this.value);
    },

    _pullValue() {
      this.value = prettyPrintDuration(model[attribute]);
    }
  }));

  return <InputWithLabel
    label={ label }
    errors={ localObservable.errors }
    onFocus={ action(() => localObservable.focused = true) }
    value={ localObservable.value }
    onBlur={ () => localObservable.handleBlur() }
    onChange={ event => localObservable.handleChange(event) }
    disabled={ disabled }
    type="text"
  />;
});

TimeInputWithLabel.displayName = "TimeInputWithLabel";

TimeInputWithLabel.propTypes = {
  label: PropTypes.string.isRequired,
  model: PropTypes.oneOfType([
    PropTypes.instanceOf(LessonStore),
    PropTypes.instanceOf(ExerciseStore)
  ]),
  attribute: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};
