import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";

import { FILES, PLAYERS, RANKS } from "../../constants";
import { useLessThanTabletMediaQuery } from "../../hooks/media-queries";

function Coordinate({ letter, position }) {
  let rank = RANKS.includes(letter) ? letter : null;
  let file = FILES.includes(letter) ? letter : null;

  return <svg
    viewBox="0 0 20 20"
    preserveAspectRatio="xMidYMid"
    data-rank={ rank }
    data-file={ file }
    className={
      classNames(
        "chessboard-coordinates__coordinate",
        `chessboard-coordinates__coordinate--${ position }`
      )
    }
  >
    <text className="chessboard-coordinates__text" x="10" y="10">{ letter }</text>
  </svg>;
}

Coordinate.propTypes = {
  letter: PropTypes.string.isRequired,
  position: PropTypes.oneOf([ "top", "bottom", "left", "right" ])
};

export function ChessboardCoordinates({ children, orientation }) {
  let isLessThanTablet = useLessThanTabletMediaQuery();

  if (isLessThanTablet) {
    return children;
  }

  return <div className="chessboard-coordinates" data-orientation={ orientation }>
    { RANKS.map(rank => <Coordinate key={ rank } letter={ rank } position="left" />) }
    { RANKS.map(rank => <Coordinate key={ rank } letter={ rank } position="right" />) }
    { FILES.map(file => <Coordinate key={ file } letter={ file } position="top" />) }
    { FILES.map(file => <Coordinate key={ file } letter={ file } position="bottom" />) }
    <div className="chessboard-coordinates__content">{ children }</div>
  </div>;
}

ChessboardCoordinates.propTypes = {
  children: PropTypes.node.isRequired,
  orientation: PropTypes.oneOf(PLAYERS).isRequired
};
