import { observer } from "mobx-react";
import React from "react";

import { Button } from "../shared/button";
import { IconModal } from "../shared/icon-modal";
import { LOG_IN_ROUTE } from "../../stores/routes";
import { RainingChessPieces } from "../authentication/raining-chess-pieces";

export const ResetPasswordSuccessPage = observer(() => {
  return <>
    <RainingChessPieces />
    <IconModal
      icon="checkFilledCircle"
      title="Password Reset Successful"
      text="Your password was reset! You can now log in with it."
      closable={ false }
      open
    >
      <Button
        icon="signIn"
        text="Log In"
        routeName={ LOG_IN_ROUTE }
        treatment="primary"
      />
    </IconModal>
  </>;
});

ResetPasswordSuccessPage.displayName = "ResetPasswordSuccessPage";
