import { Label } from "recharts";
import PropTypes from "prop-types";
import pluralize from "pluralize";

const UNPLURALIZED_WORDS = [
  "Perfect",
  "Done",
  "Complete"
];

/**
 * Returns the pluralized text based on the number.
 */
export function Pluralize({ text, number }) {
  if (UNPLURALIZED_WORDS.includes(text)) {
    return text;
  }

  return pluralize(text, number);
}

Label.propTypes = {
  text: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired
};
