import { observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import _ from "lodash";

import {
  ASSIST_STATE,
  HINT_STATE,
  PERFECT_STATE,
  MISTAKE_STATE,
  UNSOLVED_STATE
} from "../../constants";
import { Icon } from "../shared/icon";
import { ExerciseStore } from "../../stores/models/exercise-store";
import { useStore } from "../../stores/store-provider";
import { Spoiler } from "../shared/spoiler";

const InnerPracticeText = observer(({ exercise }) => {
  let { lesson } = useStore("routerParams");

  if (_.isNil(exercise)) {
    return <>
      <Icon className="practice-text__icon" name="infoCircle" alt="Info" />
      <p className="practice-text__text">
        { lesson.description }
      </p>
    </>;
  }

  if (exercise.isCompleted) {
    switch (exercise.score.state) {
      case PERFECT_STATE:
        return <>
          <Icon className="practice-text__icon" name="checkCircle" alt="Success" />
          <p className="practice-text__text">
            {
              exercise.chessboard.isCheckmate
                ? "You solved it with a checkmate! Nice job"
                : "You solved it! Nice job."
            }
          </p>
        </>;
      case HINT_STATE:
        return <>
          <Icon className="practice-text__icon" name="lightbulbCircle" alt="Hint" />
          <p className="practice-text__text">
            You solved it with a hint.
          </p>
        </>;
      case ASSIST_STATE :
        return <>
          <Icon className="practice-text__icon" name="moveCircle" alt="Assist" />
          <p className="practice-text__text">
            You solved it with some help.
          </p>
        </>;
      case MISTAKE_STATE:
        return <>
          <Icon className="practice-text__icon" name="timesCircle" alt="Mistake" />
          <p className="practice-text__text">
            You solved it, but you played a mistake.
          </p>
        </>;
    }
  }

  switch (exercise.currentMoveState) {

    case MISTAKE_STATE:
      return <>
        <Icon className="practice-text__icon" name="timesCircle" alt="Mistake" />
        <p className="practice-text__text">
          That’s not the right move.
        </p>
      </>;

    case HINT_STATE:
      return <>
        <Icon className="practice-text__icon" name="lightbulbCircle" alt="Hint" />
        <p className="practice-text__text">
          Move the { exercise.nextVerboseMove.piece } on
          { " " }
          <code className="practice-text__code">{ exercise.nextVerboseMove.from }</code>.
        </p>
      </>;

    case ASSIST_STATE:
      return <>
        <Icon className="practice-text__icon" name="moveCircle" alt="Assist" />
        <p className="practice-text__text">
          Move the { exercise.nextVerboseMove.piece } from
          { " " }
          <code className="practice-text__code">{ exercise.nextVerboseMove.from }</code> to
          { " " }
          <code className="practice-text__code">{ exercise.nextVerboseMove.to }</code>.
        </p>
      </>;
  }

  return <>
    <Icon className="practice-text__icon" name="infoCircle" alt="Info" />
    <p className="practice-text__text">
      <Spoiler enabled={ lesson.infoHidden }>
        { exercise.description }
      </Spoiler>
    </p>
  </>;
});

InnerPracticeText.displayName = "InnerPracticeText";

InnerPracticeText.propTypes = {
  exercise: PropTypes.instanceOf(ExerciseStore)
};

export const PracticeText = observer(({ className, exercise }) => {
  return <div
    className={ classNames("practice-text", className) }
    data-state={
      exercise?.isCompleted
        ? exercise?.score.state
        : exercise?.currentMoveState || UNSOLVED_STATE
    }
  >
    <InnerPracticeText exercise={ exercise } />
  </div>;
});

PracticeText.displayName = "PracticeText";

PracticeText.propTypes = {
  className: PropTypes.string,
  exercise: PropTypes.instanceOf(ExerciseStore)
};
