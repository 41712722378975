import { observable } from "mobx";

let store = observable.map({}, { deep: false });

/**
 * This is an implementation of Storage and wrapper for localStorage that implements an observable
 * MobX store. The major difference between this and the normal local storage is this object assumes
 * the values are JSON objects.
 *
 * This implementation is heavily inspired by mobx-localstorage. The reason I'm not using that
 * library is because I ran into issues while testing.
 */
export const localStorage = {

  getItem(key) {

    // If the item is in the store, return it.
    if (store.has(key)) {
      return store.get(key);
    }

    // Otherwise, fetch the item from localStorage.
    let value = window.localStorage.getItem(key);
    value = value === null ? value : JSON.parse(value);

    // Store the value so it doesn't need to be fetched again.
    store.set(key, value);

    // Return the value.
    return value;
  },

  setItem(key, value) {

    // Store the item in the store.
    store.set(key, value);

    // Store the item in localStorage.
    window.localStorage.setItem(key, value === null ? value : JSON.stringify(value));
  },

  removeItem(key) {
    store.delete(key);
    window.localStorage.removeItem(key);
  },

  clear() {
    store.clear();
    window.localStorage.clear();
  }
};
