import { isBlank } from "voca";
import _ from "lodash";

/**
 * Validates that the given value is not blank or nil.
 * @return Returns null if the value is valid or an error string if it isn't.
 */
export function validatePresence(value) {
  return _.isObject(value) || _.isArray(value) || !isBlank(value) ? null : "Required";
}
