import { observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";

import { ChessboardAudio } from "./chessboard-audio";
import { ExerciseStore } from "../../stores/models/exercise-store";
import { TRANSITION_TIME_SMALL } from "../../constants";
import { useReaction } from "../../hooks/use-reaction";
import { useAudio } from "../../hooks/use-audio";

import assistSound from "../../../sounds/assist.mp3";
import completeSound from "../../../sounds/complete.mp3";
import hintSound from "../../../sounds/hint.mp3";
import mistakeSound from "../../../sounds/mistake.mp3";

export const PracticeAudio = observer(({ exercise }) => {
  let playHintSound = useAudio(hintSound, { volume: 0.3 });
  let playAssistSound = useAudio(assistSound, { volume: 0.3 });
  let playCompleteSound = useAudio(completeSound, { volume: 0.3 });
  let playMistakeSound = useAudio(mistakeSound, { volume: 0.3 });

  function playSoundIfComplete() {
    if (exercise.isCompleted) {
      setTimeout(() => playCompleteSound(), TRANSITION_TIME_SMALL);
    }
  }

  function playSoundIfMistake() {
    if (exercise.currentMoveMistake) {
      playMistakeSound();
    }
  }

  function playSoundIfHint() {
    if (exercise.currentMoveHint) {
      playHintSound();
    }
  }

  function playSoundIfAssist() {
    if (exercise.currentMoveAssist) {
      playAssistSound();
    }
  }

  // If the exercise changes, play the appropriate sound.
  // BUG FIX: We *must* use the full move properties in the data functions rather than the current
  // properties. Otherwise, the sound effects won't be triggered if an mistake is played more than
  // once in a row.
  useReaction(() => exercise.isCompleted, playSoundIfComplete, [ exercise.id ]);
  useReaction(() => exercise.chessboard.mistake, playSoundIfMistake, [ exercise.id ]);
  useReaction(() => exercise.chessboard.hint, playSoundIfHint, [ exercise.id ]);
  useReaction(() => exercise.chessboard.assist, playSoundIfAssist, [ exercise.id ]);

  return <ChessboardAudio chessboard={ exercise.chessboard } />;
});

PracticeAudio.displayName = "PracticeAudio";

PracticeAudio.propTypes = {
  exercise: PropTypes.instanceOf(ExerciseStore).isRequired
};
