import { observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import _ from "lodash";

import {
  EDIT_EXERCISE_ROUTE,
  NEW_EXERCISE_ROUTE,
  PRACTICE_LESSON_ROUTE
} from "../../stores/routes";
import { EditExerciseControls } from "../controls/edit-exercise-controls";
import { LessonControls } from "../controls/lesson-controls";
import { RouterView } from "../shared/router-view";
import { useStore } from "../../stores/store-provider";
import { ExerciseControls } from "../controls/exercise-controls";

const LessonAndExerciseControls = observer(() => {
  let { lesson } = useStore("routerParams");

  return _.isNil(lesson?.currentExercise)
    ? <LessonControls />
    : <ExerciseControls />;
});

LessonAndExerciseControls.displayName = "LessonAndExerciseControls";

export const CONTROLS_VIEW_MAP = {
  [PRACTICE_LESSON_ROUTE]: LessonAndExerciseControls,
  [NEW_EXERCISE_ROUTE]: EditExerciseControls,
  [EDIT_EXERCISE_ROUTE]: EditExerciseControls
};

export const ControlsLayout = observer(({ className }) => {
  let { hasError } = useStore("error");

  if (hasError) {
    return null;
  }

  return <div className={ classNames("controls-layout", className) }>
    <RouterView viewMap={ CONTROLS_VIEW_MAP } />
  </div>;
});

ControlsLayout.displayName = "ControlsLayout";

ControlsLayout.propTypes = {
  className: PropTypes.string
};
