import PropTypes from "prop-types";
import React from "react";
import MediaQuery from "react-responsive";

export const TABLET_MEDIA_QUERY = { minWidth: 640 };
export const DESKTOP_MEDIA_QUERY = { minWidth: 960 };
export const LARGE_DESKTOP_MEDIA_QUERY = { minWidth: 1280 };
export const LESS_THAN_TABLET_MEDIA_QUERY = { maxWidth: 639 };
export const LESS_THAN_DESKTOP_MEDIA_QUERY = { maxWidth: 959 };

export function TabletMediaQuery({ children }) {
  return <MediaQuery { ...TABLET_MEDIA_QUERY }>{ children }</MediaQuery>;
}

TabletMediaQuery.propTypes = {
  children: PropTypes.node.isRequired
};

export function DesktopMediaQuery({ children }) {
  return <MediaQuery { ...DESKTOP_MEDIA_QUERY }>{ children }</MediaQuery>;
}

DesktopMediaQuery.propTypes = {
  children: PropTypes.node.isRequired
};

export function LargeDesktopMediaQuery({ children }) {
  return <MediaQuery { ...LARGE_DESKTOP_MEDIA_QUERY }>{ children }</MediaQuery>;
}

LargeDesktopMediaQuery.propTypes = {
  children: PropTypes.node.isRequired
};

export function LessThanTabletMediaQuery({ children }) {
  return <MediaQuery { ...LESS_THAN_TABLET_MEDIA_QUERY }>{ children }</MediaQuery>;
}

LessThanTabletMediaQuery.propTypes = {
  children: PropTypes.node.isRequired
};

export function LessThanDesktopMediaQuery({ children }) {
  return <MediaQuery { ...LESS_THAN_DESKTOP_MEDIA_QUERY }>{ children }</MediaQuery>;
}

LessThanDesktopMediaQuery.propTypes = {
  children: PropTypes.node.isRequired
};
