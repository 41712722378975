import { routerStateToUrl } from "mobx-state-router";

export const LESSONS_HISTORY_ROUTE = "lessonsHistory";
export const CREATED_LESSONS_ROUTE = "createdLessons";
export const LIKED_LESSONS_ROUTE = "likedLessons";
export const POPULAR_LESSONS_ROUTE = "popularLessons";
export const NEW_LESSON_ROUTE = "newLesson";
export const PRACTICE_LESSON_ROUTE = "practiceLesson";
export const EDIT_LESSON_ROUTE = "editLesson";
export const NEW_EXERCISE_ROUTE = "newExercise";
export const EDIT_EXERCISE_ROUTE = "editExercise";
export const LOG_IN_ROUTE = "logIn";
export const LOG_OUT_ROUTE = "logOut";
export const SIGN_UP_ROUTE = "signUp";
export const FORGOT_PASSWORD_ROUTE = "forgotPassword";
export const RESET_PASSWORD_SENT_ROUTE = "resetPasswordSent";
export const RESET_PASSWORD_ROUTE = "resetPassword";
export const RESET_PASSWORD_SUCCESS_ROUTE = "resetPasswordSuccess";
export const TERMS_OF_SERVICE_ROUTE = "termsOfService";
export const PRIVACY_POLICY_ROUTE = "privacyPolicy";
export const COOKIE_POLICY_ROUTE = "cookiePolicy";
export const COPYRIGHT_ROUTE = "copyright";
export const WELCOME_ROUTE = "welcome";
export const ROOT_ROUTE = "root";
export const NOT_FOUND_ROUTE = "notFound";
export const CONTACT_ROUTE = "contact";

export const LEGAL_ROUTES = [
  TERMS_OF_SERVICE_ROUTE,
  PRIVACY_POLICY_ROUTE,
  COOKIE_POLICY_ROUTE,
  COPYRIGHT_ROUTE
];

export const EDITING_ROUTES = [
  NEW_LESSON_ROUTE,
  EDIT_LESSON_ROUTE,
  NEW_EXERCISE_ROUTE,
  EDIT_EXERCISE_ROUTE
];

// A `beforeEnter` function that requires the user to be logged in.
function requireLoggedIn(_fromState, toState, routerStore) {

  let { session } = routerStore.options;

  if (!session.isLoggedIn) {
    let redirectPath = routerStateToUrl(routerStore, toState);
    return routerStore.goTo(LOG_IN_ROUTE, { queryParams: { redirectPath } });
  }
}

export const ROUTES = [

  // Lessons
  {
    name: LESSONS_HISTORY_ROUTE,
    pattern: "/lessons/history",
    beforeEnter: requireLoggedIn
  },
  {
    name: CREATED_LESSONS_ROUTE,
    pattern: "/lessons/created",
    beforeEnter: requireLoggedIn
  },
  {
    name: LIKED_LESSONS_ROUTE,
    pattern: "/lessons/liked",
    beforeEnter: requireLoggedIn
  },
  {
    name: POPULAR_LESSONS_ROUTE,
    pattern: "/lessons"
  },
  {
    name: NEW_LESSON_ROUTE,
    pattern: "/lessons/new",
    beforeEnter: requireLoggedIn
  },
  {
    name: PRACTICE_LESSON_ROUTE,
    pattern: "/lessons/:lessonId"
  },
  {
    name: EDIT_LESSON_ROUTE,
    pattern: "/lessons/:lessonId/edit",
    beforeEnter: requireLoggedIn
  },

  // Exercises
  {
    name: NEW_EXERCISE_ROUTE,
    pattern: "/lessons/:lessonId/exercises/new",
    beforeEnter: requireLoggedIn
  },
  {
    name: EDIT_EXERCISE_ROUTE,
    pattern: "/lessons/:lessonId/exercises/:exerciseId/edit",
    beforeEnter: requireLoggedIn
  },

  // Authentication
  {
    name: LOG_IN_ROUTE,
    pattern: "/log-in"
  },
  {
    name: SIGN_UP_ROUTE,
    pattern: "/sign-up"
  },
  {
    name: FORGOT_PASSWORD_ROUTE,
    pattern: "/forgot-password"
  },
  {
    name: RESET_PASSWORD_SENT_ROUTE,
    pattern: "/reset-password-sent"
  },
  {
    name: RESET_PASSWORD_ROUTE,
    pattern: "/reset-password"
  },
  {
    name: RESET_PASSWORD_SUCCESS_ROUTE,
    pattern: "/reset-password-success"
  },

  // Legal
  {
    name: TERMS_OF_SERVICE_ROUTE,
    pattern: "/terms-of-service"
  },
  {
    name: PRIVACY_POLICY_ROUTE,
    pattern: "/privacy-policy"
  },
  {
    name: COOKIE_POLICY_ROUTE,
    pattern: "/cookie-policy"
  },
  {
    name: COPYRIGHT_ROUTE,
    pattern: "/copyright"
  },

  // Contact
  {
    name: CONTACT_ROUTE,
    pattern: "/contact"
  },

  // Root
  {
    name: WELCOME_ROUTE,
    pattern: "/welcome"
  },
  {
    name: ROOT_ROUTE,
    pattern: "/",
    beforeEnter(_fromState, _toState, routerStore) {
      let { session } = routerStore.options;

      if (session.isLoggedIn) {
        return routerStore.goTo(WELCOME_ROUTE);
      }
    }
  },

  // Errors
  {
    name: NOT_FOUND_ROUTE,
    pattern: "/404"
  }
];
