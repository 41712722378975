import { observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";
import classNames from "classnames";

import { Controls } from "../controls/controls";
import { Button } from "../shared/button";
import { useStore } from "../../stores/store-provider";

export const EditExerciseChildControls = observer(({ className }) => {
  let { exercise } = useStore("routerParams");

  if (_.isNil(exercise)) {
    return null;
  }

  return <Controls className={ classNames(className, "edit-exercise-sidebar-controls") }>
    <Button
      icon="arrowLeft"
      text="Back"
      className="edit-exercise-sidebar-controls__button"
      onClick={ () => exercise.editDetails() }
      treatment="clear"
    />
  </Controls>;
});

EditExerciseChildControls.displayName = "EditExerciseChildControls";

EditExerciseChildControls.propTypes = {
  className: PropTypes.string
};
