import { action } from "mobx";
import { observer, useLocalObservable } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";

import { Icon } from "../shared/icon";
import { ScoreSummaryChart } from "./score-summary-chart";
import { ScoreSummaryStore } from "../../stores/models/score-summary-store";
import { Pluralize } from "../shared/pluralize";

const ScoreSummaryStat = observer(({ icon, number, label }) => {

  return <div className="score-summary-stats__stat">
    <Icon className="score-summary-stats__icon" filled={ false } name={ icon } />
    <p className="score-summary-stats__stat-value">
      <span className="score-summary-stats__number">
        { number }
      </span>
      { " " }
      <Pluralize text={ label } number={ number } />
    </p>
  </div>;
});

ScoreSummaryStat.displayName = "ScoreSummaryStat";

ScoreSummaryStat.propTypes = {
  icon: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired
};

export const ScoreSummaryStats = observer(({ scoreSummaries, className }) => {

  let localObservable = useLocalObservable(() => ({
    index: scoreSummaries.length - 1,

    get currentScoreSummary() {
      // eslint-disable-next-line react/no-this-in-sfc
      return scoreSummaries[this.index];
    }
  }));

  let scoreSummary = localObservable.currentScoreSummary;

  const handleHover = action(({ index }) => {
    localObservable.index = index;
  });

  const handleUnhover = action(() => {
    localObservable.index = scoreSummaries.length - 1;
  });

  return <div className={ classNames("score-summary-stats", className) }>
    <h3 className="score-summary-stats__time-period-header">Last 7 Days</h3>
    <ScoreSummaryChart
      className="score-summary-stats__chart"
      scoreSummaries={ scoreSummaries }
      onHover={ handleHover }
      onUnhover={ handleUnhover }
    />
    <h3 className="score-summary-stats__current-day-header">{ scoreSummary.label }</h3>
    <ScoreSummaryStat
      icon="checkFilledCircle"
      number={ scoreSummary.numberOfPerfects }
      label="Perfect"
    />
    <ScoreSummaryStat
      icon="lightbulbFilledCircle"
      number={ scoreSummary.numberOfHints }
      label="Hint"
    />
    <ScoreSummaryStat
      icon="moveFilledCircle"
      number={ scoreSummary.numberOfAssists }
      label="Assist"
    />
    <ScoreSummaryStat
      icon="timesFilledCircle"
      number={ scoreSummary.numberOfMistakes }
      label="Mistake"
    />
  </div>;
});

ScoreSummaryStats.displayName = "ScoreSummaryStats";

ScoreSummaryStats.propTypes = {
  scoreSummaries: PropTypes.arrayOf(PropTypes.instanceOf(ScoreSummaryStore)).isRequired,
  className: PropTypes.string
};
