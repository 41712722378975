import { observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";

import { playerPropType, markerPropType } from "../../constants";
import { isKnightMove, convertSquareToIndices } from "../../utilities/squares";
import { distance, radiansToDegrees } from "../../utilities/math";

const STROKE_WIDTH = 0.2;
const BOARD_SIZE = 8;
const TRIANGLE_WIDTH = 0.6;
const TRIANGLE_HEIGHT = 0.45;
const START_OFFSET = 0.45;

/**
 * Converts chess coordinates from board-relative indices to the viewBox size.
 */
function centerIndices([ x, y ]) {
  return [ x + 0.5, y + 0.5 ];
}

/**
 * Shortens the given line by the provided length.
 * @param from The starting point of the line.
 * @param toIndices The ending point of the line.
 * @return Returns the coordinates of the new to.
 */
function shortenLine(fromIndices, toIndices, length) {
  let lineLength = distance(fromIndices, toIndices);
  let t = (lineLength - length) / lineLength;

  return [
    fromIndices[0] + (toIndices[0] - fromIndices[0]) * t,
    fromIndices[1] + (toIndices[1] - fromIndices[1]) * t
  ];
}

function Triangle({ fromIndices, toIndices }) {

  let [ fromX, fromY ] = centerIndices(fromIndices);
  let [ toX, toY ] = centerIndices(toIndices);

  let height = toX - fromX < 0 ? -TRIANGLE_HEIGHT : TRIANGLE_HEIGHT;

  // Hello
  let trianglePoints = [
    [ toX, toY ],
    [ toX - TRIANGLE_WIDTH / 2, toY + height ],
    [ toX + TRIANGLE_WIDTH / 2, toY + height ]
  ];

  let rotationAngle = radiansToDegrees(Math.atan((toY - fromY) / (toX - fromX))) + 90;

  return <polygon
    transform={ `rotate(${ rotationAngle } ${ toX } ${ toY })` }
    className="chessboard-arrow__triangle"
    points={ trianglePoints.map(point => point.join(",")).join(" ") }
  />;
}

Triangle.propTypes = {
  fromIndices: PropTypes.arrayOf(PropTypes.number).isRequired,
  toIndices: PropTypes.arrayOf(PropTypes.number).isRequired
};

function Line({ fromIndices, toIndices, shortenStart, shortenEnd }) {

  fromIndices = centerIndices(fromIndices);
  toIndices = centerIndices(toIndices);

  // Shorten the line

  if (shortenStart) {
    fromIndices = shortenLine(toIndices, fromIndices, START_OFFSET);
  }

  if (shortenEnd) {
    toIndices = shortenLine(fromIndices, toIndices, TRIANGLE_HEIGHT);
  }

  return <line
    className="chessboard-arrow__line"
    strokeWidth={ STROKE_WIDTH }
    strokeLinecap="square"
    x1={ fromIndices[0] }
    y1={ fromIndices[1] }
    x2={ toIndices[0] }
    y2={ toIndices[1] }
  />;
}

Line.propTypes = {
  fromIndices: PropTypes.arrayOf(PropTypes.number).isRequired,
  toIndices: PropTypes.arrayOf(PropTypes.number).isRequired,
  shortenStart: PropTypes.bool,
  shortenEnd: PropTypes.bool
};

function StraightArrow({ fromIndices, toIndices }) {
  return <>
    <Line fromIndices={ fromIndices } toIndices={ toIndices } shortenStart shortenEnd />
    <Triangle fromIndices={ fromIndices } toIndices={ toIndices } />
  </>;
}

StraightArrow.propTypes = {
  fromIndices: PropTypes.arrayOf(PropTypes.number).isRequired,
  toIndices: PropTypes.arrayOf(PropTypes.number).isRequired
};

function OrthagonalArrow({ fromIndices, toIndices }) {

  let [ fromX, fromY ] = fromIndices;
  let [ toX, toY ] = toIndices;

  let differenceX = toX - fromX;
  let differenceY = toY - fromY;

  let midpointIndices = Math.abs(differenceX) > Math.abs(differenceY)
    ? [ fromX + differenceX, fromY ]
    : [ fromX, fromY + differenceY ];

  return <>
    <Line fromIndices={ fromIndices } toIndices={ midpointIndices } shortenStart />
    <Line fromIndices={ midpointIndices } toIndices={ toIndices } shortenEnd />
    <Triangle fromIndices={ midpointIndices } toIndices={ toIndices } />
  </>;
}

OrthagonalArrow.propTypes = {
  fromIndices: PropTypes.arrayOf(PropTypes.number).isRequired,
  toIndices: PropTypes.arrayOf(PropTypes.number).isRequired
};

/**
 * Renders an arrow on top of a chessboard.
 */
export const ChessboardArrow = observer(({ from, to, type, orientation, className }) => {

  // Convert the squares to SVG coordinate space.
  let fromIndices = convertSquareToIndices(from, orientation);
  let toIndices = convertSquareToIndices(to, orientation);

  return <svg
    className={ classNames(className, "chessboard-arrow") }
    viewBox={ `0 0 ${ BOARD_SIZE } ${ BOARD_SIZE }` }
    data-type={ type }
  >
    {
      isKnightMove(from, to)
        ? <OrthagonalArrow fromIndices={ fromIndices } toIndices={ toIndices } />
        : <StraightArrow fromIndices={ fromIndices } toIndices={ toIndices } />
    }
  </svg>;
});

ChessboardArrow.displayName = "ChessboardArrow";

ChessboardArrow.propTypes = {
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  type: markerPropType.isRequired,
  orientation: playerPropType.isRequired,
  className: PropTypes.string
};
