import { isBlank } from "voca";
import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";

import { Icon } from "../shared/icon";
import { hostname } from "../../utilities/url";

export function PreviewLink({ title, sourceName, url, className }) {
  title = isBlank(title) ? "Example" : title;
  url = isBlank(url) ? null : url;
  sourceName = isBlank(sourceName) ? "Example" : sourceName;
  let domain = hostname(url);
  domain = isBlank(domain) ? "example.com" : domain;

  return <a
    className={ classNames(className, "preview-link") }
    href={ url }
    target="_blank"
    rel="noreferrer noopener"
  >
    <Icon name="linkFilledCircle" filled={ false } className="preview-link__icon" />
    <header className="preview-link__header">
      <h3 className="preview-link__title">{ title }</h3>
      <p className="preview-link__source-name">{ sourceName }</p>
      <p className="preview-link__hostname">{ domain }</p>
    </header>
  </a>;
}

PreviewLink.propTypes = {
  title: PropTypes.string,
  sourceName: PropTypes.string,
  className: PropTypes.string,
  url: PropTypes.string
};
