import { isBlank } from "voca";

const MINIMUM_PASSWORD_LENGTH = 6;

export function passwordStrength(password) {

  // If the password is empty, always return 0
  if (isBlank(password)) {
    return 0;
  }

  // If the password is too short, don't bother calculating it.
  if (password.length < MINIMUM_PASSWORD_LENGTH) {
    return 1;
  }

  // Use zxcvbn to calculate the password's strength.
  return window.zxcvbn(password).score;
}
