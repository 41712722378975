import _ from "lodash";
import voca from "voca";

import { Chess } from "./chess-js";
import { isFenValid } from "./fen";

/**
 * Removes all empty moves from the end of the moves array.
 */
export function removeBlankMoves(moves) {
  let endIndex = _.findLastIndex(moves, move => {
    return !_.isNil(move) && !voca.isBlank(move);
  });

  return _.slice(moves, 0, endIndex + 1);
}

/**
  * Returns the index of the first invalid move. If none of the moves are invalid, this function
  * returns null.
  *
  * TODO: In the future, should this be replaced by PGN notation? That seems like it would be
  * easier.
  */
export function findInvalidMoveIndex(fen, moves) {

  // Don't alter the moves if the FEN is invalid.
  if (!isFenValid(fen)) {
    return null;
  }

  // Iterate through a chess until an invalid move can be found.
  let chess = new Chess(fen);
  let index = _.findIndex(moves, move => {

    // BUG FIX: chess.js shouldn't throw an error here, but it does.
    // https://github.com/jhlywa/chess.js/issues/282
    try {
      return _.isNil(chess.move(move, { sloppy: true }));
    }
    catch {
      return true;
    }
  });
  return index === -1 ? null : index;
}

/**
 * Replaces all of the moves with
 */
export function sanitizeMoves(fen, moves) {

  // Don't alter the moves if the FEN is invalid.
  if (!isFenValid(fen)) {
    return moves;
  }

  let chess = new Chess(fen);
  moves = Array.from(moves);

  // Replace all of the moves until an invalid move is found.
  for (let i = 0; i < moves.length; i++) {
    let result;

    // BUG FIX: chess.js shouldn't throw an error here, but it does.
    // https://github.com/jhlywa/chess.js/issues/282
    try {
      result = chess.move(moves[i], { sloppy: true });
    }
    catch {
      break;
    }

    // If the move was invalid, stop here.
    if (_.isNil(result)) {
      break;
    }

    // Replace the move with its correct SAN
    moves[i] = result.san;
  }

  // Return the modified moves array sans blank lines.
  return removeBlankMoves(moves);
}

/**
 * Adds the given piece to the move as a promotion.
 */
export function addPromotionToMove(move, piece) {
  return _.isString(move)
    ? `${ move }=Q`
    : { ...move, promotion: piece };
}
