import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";

export function VideoContainer({ className, children }) {
  return <figure className={ classNames("video-container", className) }>
    { children }
  </figure>;
}

VideoContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired
};
