import { useState, useLayoutEffect } from "react";
import useResizeObserver from "@react-hook/resize-observer";

/**
 * A hook that returns the size of the element. This implementation is straight out of the
 * resize-observer readme.
 *
 * https://github.com/jaredLunde/react-hook/tree/master/packages/resize-observer
 *
 * @param ref The object returned from useRef.
 * @return Returns the size of the element.
 */
export const useSize = (reference) => {
  const [ size, setSize ] = useState();

  useLayoutEffect(() => {
    setSize(reference.current.getBoundingClientRect());
  }, [ reference ]);

  useResizeObserver(reference, entry => setSize(entry.contentRect));

  return size;
};
