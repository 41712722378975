import { capitalize } from "voca";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";

import {
  BISHOP,
  BLACK,
  KING,
  KNIGHT,
  PAWN,
  PIECES,
  PLAYERS,
  QUEEN,
  ROOK,
  WHITE
} from "../../constants";
import { ChessboardStore } from "../../stores/chessboard-store";
import blackBishop from "../../../images/pieces/black-bishop.svg";
import blackKing from "../../../images/pieces/black-king.svg";
import blackKnight from "../../../images/pieces/black-knight.svg";
import blackPawn from "../../../images/pieces/black-pawn.svg";
import blackQueen from "../../../images/pieces/black-queen.svg";
import blackRook from "../../../images/pieces/black-rook.svg";
import whiteBishop from "../../../images/pieces/white-bishop.svg";
import whiteKing from "../../../images/pieces/white-king.svg";
import whiteKnight from "../../../images/pieces/white-knight.svg";
import whitePawn from "../../../images/pieces/white-pawn.svg";
import whiteQueen from "../../../images/pieces/white-queen.svg";
import whiteRook from "../../../images/pieces/white-rook.svg";

const PIECE_SOURCES = {
  [WHITE]: {
    [KING]: whiteKing,
    [QUEEN]: whiteQueen,
    [ROOK]: whiteRook,
    [BISHOP]: whiteBishop,
    [KNIGHT]: whiteKnight,
    [PAWN]: whitePawn
  },
  [BLACK]: {
    [KING]: blackKing,
    [QUEEN]: blackQueen,
    [ROOK]: blackRook,
    [BISHOP]: blackBishop,
    [KNIGHT]: blackKnight,
    [PAWN]: blackPawn
  }
};

const ChessboardPiece = observer(({ chessboard, player, piece }) => {
  let source = PIECE_SOURCES[player][piece];

  // Access the starting position so the piece will be updated if it changes.
  // eslint-disable-next-line no-unused-expressions
  chessboard.startingPosition;

  // The king should be disabled if the chessboard already includes that piece.
  let disabled = piece === KING && chessboard.hasPiece(player, piece);

  // The data on the data transfer object can't be accessed when dragging and item, so it must be
  // stored inside of the chessboard instead.
  function handleDragStart() {
    chessboard.dragPiece = piece;
    chessboard.dragPlayer = player;
  }

  function handleDragEnd() {
    chessboard.dragPiece = null;
    chessboard.dragPlayer = null;
  }

  return <img
    draggable={ !disabled }
    onDragStart={ handleDragStart }
    onDragEnd={ handleDragEnd }
    className="chessboard-pieces__piece"
    src={ source }
    alt={ `${ capitalize(player) } ${ capitalize(piece) }` }
    disabled={ disabled }
  />;
});

ChessboardPiece.displayName = "ChessboardPiece";

ChessboardPiece.propTypes = {
  chessboard: PropTypes.instanceOf(ChessboardStore).isRequired,
  piece: PropTypes.oneOf(PIECES).isRequired,
  player: PropTypes.oneOf(PLAYERS).isRequired
};

export const ChessboardPieces = observer(({ chessboard }) => {
  return <div className="chessboard-pieces">

    <label className="chessboard-pieces__label">Pieces</label>
    <div className="chessboard-pieces__pieces">
      {
        PLAYERS.map(player => {
          return PIECES.map(piece => {
            return <ChessboardPiece
              key={ `${ player }-${ piece }` }
              chessboard={ chessboard }
              player={ player }
              piece={ piece }
            />;
          });
        })
      }
    </div>
  </div>;
});

ChessboardPieces.displayName = "ChessboardPieces";

ChessboardPieces.propTypes = {
  chessboard: PropTypes.instanceOf(ChessboardStore).isRequired
};
