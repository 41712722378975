import { observer } from "mobx-react";
import React from "react";

import { Button } from "./button";
import { IconModal } from "./icon-modal";
import { useStore } from "../../stores/store-provider";

/**
 * The dialog is a special type of modal that's connected to the DialogStore. It's used to show
 * specific modals to the application.
 *
 * NOTE: The dialog component currently only displays the danger confirm type. If other types of
 * dialogs are added, this will need to be updated.
 */
export const Dialog = observer(() => {
  let dialog = useStore("dialog");

  return <IconModal
    icon="questionFilledDangerCircle"
    title={ dialog.title || "" }
    text={ dialog.message || "" }
    open={ dialog.isOpen }
    onClose={ () => dialog.close() }
    requiresInteraction
  >
    <Button
      icon="times"
      text="No"
      onClick={ () => dialog.close() }
      treatment="secondary"
    />
    <Button
      icon="check"
      text="Yes"
      onClick={ () => dialog.affirm() }
      treatment="danger"
    />
  </IconModal>;
});

Dialog.displayName = "DialogStore";
