import { DragDropContext, Droppable } from "react-beautiful-dnd";
import PropTypes from "prop-types";
import React from "react";

/**
 * This component uses react-beautiful-dnd with a single div to create a droppable area. The goal is
 * to obfuscate some of the complexity in react-beautiful-dnd for the use cases in this project.
 * @param className The class name to append to the droppable div.
 * @param onDragEnd A function that's called when the drag has completed.
 * @param children The children to render inside of the droppable area. This should be an *array* of
 * SimpleDraggable nodes.
 */

export function SimpleDroppable({ className, onDragEnd, children }) {

  return <DragDropContext onDragEnd={ onDragEnd }>
    <Droppable droppableId="exerciseMoves">
      { (provided) => {
        return <div
          ref={ provided.innerRef }
          className={ className }
          { ...provided.droppableProps }
        >
          { children }
          { provided.placeholder }
        </div>;
      } }
    </Droppable>
  </DragDropContext>;
}

SimpleDroppable.propTypes = {
  className: PropTypes.string,
  onDragEnd: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};
