import _ from "lodash";

import { BadRequestError, ValidationError } from "../../utilities/errors";
import { apiClient } from "../../api/api-client";
import { validatePresence } from "../../validators/validate-presence";
import { FormStore } from "./form-store";
import { validatePasswordStrength } from "../../validators/validate-password-strength";

export class ResetPasswordStore extends FormStore {
  static RESOURCE_NAME = "session";
  static ATTRIBUTES = [ "password" ]
  static VALIDATORS = { password: [ validatePresence, validatePasswordStrength ] }

  password = null

  constructor(attributes) {
    super();
    this.initialize(attributes);
  }

  /**
   * Submits the form.
   */
  async submit({ token }) {
    return this.wrapSubmit(
      async () => await apiClient.resetPassword({ password: this.password, token }),
      (error) => {
        let isTokenError = error instanceof ValidationError && !_.isEmpty(error.errors?.token)
          || error instanceof BadRequestError && /token must be provided/i.test(error.message);

        return isTokenError
          ? "token invalid"
          : null;
      }
    );
  }
}
