import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";

import {
  ASSIST_STATE,
  HINT_STATE,
  STATES,
  PERFECT_STATE,
  UNSOLVED_STATE,
  MISTAKE_STATE,
  CONTENT_STATE
} from "../../constants";
import { Icon } from "../shared/icon";

const ICONS = {
  [ASSIST_STATE]: "moveCircle",
  [HINT_STATE]: "lightbulbCircle",
  [PERFECT_STATE]: "checkFilledCircle",
  [UNSOLVED_STATE]: "questionCircle",
  [MISTAKE_STATE]: "timesFilledCircle",
  [CONTENT_STATE]: "playFilledCircle"
};

export function ExerciseIcon({ className, state }) {
  return <Icon
    className={ classNames("exercise-icon", className, `exercise-icon--${ state }`) }
    name={ ICONS[state] }
    filled={ state === HINT_STATE || state === ASSIST_STATE || state === UNSOLVED_STATE }
  />;
}

ExerciseIcon.propTypes = {
  className: PropTypes.string,
  state: PropTypes.oneOf(STATES).isRequired
};
