import { runInAction } from "mobx";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";

import { ChessboardStore } from "../../stores/chessboard-store";
import { PLAYERS } from "../../constants";
import { squareFromEvent } from "../../utilities/squares";

export const ChessboardDroppable = observer(({ chessboard, orientation, children }) => {

  const handleDragEnter = event => {
    if (_.isNil(chessboard.dragPlayer)) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    runInAction(() => {
      chessboard.dragSquare = squareFromEvent(event, ".chessboard-droppable", orientation);
    });
  };

  // This event must be present in order for the drop event to fire, even though it's only
  // preventing the browser's default behavior.
  const handleDragOver = event => {
    if (_.isNil(chessboard.dragPlayer)) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();
  };

  const handleDragLeave = event => {

    if (_.isNil(chessboard.dragPlayer)) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    // If the event's related target is not contained inside of the chessboard, then the item has
    // been drug off of the board, and can be removed from the chessboard.
    if (_.isNil(event?.relatedTarget?.closest(".chessboard-droppable"))) {
      runInAction(() => {
        chessboard.dragSquare = null;
      });
    }
  };

  const handleDrop = event => {
    // Only place a piece if there's a drag square previously set.
    if (_.isNil(chessboard.dragSquare)) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    // Place the piece on the drag square if there is one.
    chessboard.addPieceToStartingPosition(
      chessboard.dragSquare,
      chessboard.dragPlayer,
      chessboard.dragPiece
    );
  };

  return <div
    className="chessboard-droppable"
    onDragEnter={ handleDragEnter }
    onDragOver={ handleDragOver }
    onDragLeave={ handleDragLeave }
    onDrop={ handleDrop }
  >
    { children }
  </div>;
});

ChessboardDroppable.displayName = "ChessboardDroppable";

ChessboardDroppable.propTypes = {
  chessboard: PropTypes.instanceOf(ChessboardStore).isRequired,
  orientation: PropTypes.oneOf(PLAYERS),
  children: PropTypes.node.isRequired
};
