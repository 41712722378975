import { observer } from "mobx-react";
import React, { useState } from "react";

import { AttributeInputWithLabel } from "../shared/attribute-input-with-label";
import { AuthenticationPage } from "../authentication/authentication-page";
import { ForgotPasswordStore } from "../../stores/forms/forgot-password-store";
import { RESET_PASSWORD_SENT_ROUTE } from "../../stores/routes";
import { useStore } from "../../stores/store-provider";

export const ForgotPasswordPage = observer(() => {
  let [ forgotPassword ] = useState(() => new ForgotPasswordStore());
  let router = useStore("router");

  async function handleSubmit(event) {
    event.preventDefault();
    await forgotPassword.submit();

    if (forgotPassword.isValid) {
      router.goTo(RESET_PASSWORD_SENT_ROUTE);
    }
  }

  return <AuthenticationPage
    className="forgot-password-page"
    title="Forgot Password"
    onSubmit={ handleSubmit }
    buttonText="Send Password Reset"
    message={ <>Type in your info and weʼll send you an email to reset your password.</> }
  >

    <AttributeInputWithLabel
      label="Email"
      model={ forgotPassword }
      attribute="email"
      type="email"
    />
  </AuthenticationPage>;
});

ForgotPasswordPage.displayName = "ForgotPasswordPage";
