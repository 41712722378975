import { observer } from "mobx-react";
import React from "react";

import { Button } from "../shared/button";
import { IconModal } from "../shared/icon-modal";
import { ROOT_ROUTE } from "../../stores/routes";
import { RainingChessPieces } from "../authentication/raining-chess-pieces";

export const ResetPasswordSentPage = observer(() => {
  return <>
    <RainingChessPieces />
    <IconModal
      icon="envelopeFilledCircle"
      title="Password Reset Sent"
      text="Your reset password request was submitted! You should receive an email shortly."
      closable={ false }
      open
    >
      <Button
        icon="arrowLeft"
        text="Home"
        routeName={ ROOT_ROUTE }
        treatment="primary"
      />
    </IconModal>
  </>;
});

ResetPasswordSentPage.displayName = "ResetPasswordSentPage";
