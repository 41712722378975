import { makeAutoObservable } from "mobx";
import _ from "lodash";

export class DialogStore {

  constructor() {
    makeAutoObservable(this);
  }

  // Controls whether the dialog is currently open or not.
  title = null
  message = null
  type = null
  _promise = null
  _promiseResolve = null

  /**
   * Returns true if the dialog is currently open.
   */
  get isOpen() {
    return !_.isNil(this._promise);
  }

  /**
   * This is like the browser's built-in confirm method. If the user clicks yes, this resolves the
   * returned promise with true. If the user clicks no, it resolves the returned promise with false.
   */
  async confirmDangerous(title, message) {

    // If a dialog was already open, close it.
    if (this.isOpen) {
      this.close();
    }

    this.title = title;
    this.message = message;
    this.type = "danger";

    this._promise = new Promise(resolve => {
      this._promiseResolve = resolve;
    });

    return this._promise;
  }

  /**
   * Triggers the affirmative state for the current dialog.
   */
  affirm() {
    this._resolvePromise(true);
  }

  /**
   * Triggers the denial state for the current dialog.
   */
  close() {
    this._resolvePromise(false);
  }

  /**
   * This is a convenience method that displays a pretty deletion confirmation for a model.
   */
  async confirmDelete(model) {
    let modelName = model.constructor.RESOURCE_NAME.toLowerCase();

    let message = `Are you sure you want to delete your '${ model.title }' ${ modelName }? `
      + "You can't undo this.";

    return await this.confirmDangerous("Are You Sure?", message);
  }

  /**
   * Resolve the promise for the confirm method.
   */
  _resolvePromise(value) {
    let resolve = this._promiseResolve;

    this._promise = null;
    this._promiseResolve = null;

    if (!_.isNil(resolve)) {
      resolve(value);
    }
  }
}
