import _ from "lodash";
import { format } from "date-fns";
import { localStorage } from "../utilities/local-storage";

import {
  ForbiddenError,
  NotFoundError,
  ValidationError,
  UnauthorizedError,
  InternalServerError,
  BadRequestError
} from "../utilities/errors";

const API_URL = process.env.REACT_APP_API_URL;
const DEFAULT_HEADERS = { "Content-Type": "application/json" };

export const CREATED_LESSONS_FILTER = "created";
export const LIKED_LESSONS_FILTER = "liked";
export const PLAYED_LESSONS_FILTER = "played";
export const POPULAR_LESSONS_FILTER = "popular";

const EXERCISES_PATH = "/exercises";
const LESSONS_PATH = "/lessons";
const PASSWORD_RESET_PATH = "/password-reset";
const SCORE_SUMMARIES_PATH = "/score-summaries";
const SESSION_PATH = "/session";
const USER_PATH = "/user";

// This doesn't *exactly* match a UUID, but it's close enough.
const UUID_REGEX = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

const AUTHENTICATION_REQUIRED = "required";
const AUTHENTICATION_OPTIONAL = "optional";
const AUTHENTICATION_NONE = "none";

const AUTHENTICATION_OPTIONS = [
  AUTHENTICATION_REQUIRED,
  AUTHENTICATION_OPTIONAL,
  AUTHENTICATION_NONE
];

// Hoist the definition of request so apiClient and request can be used circularly.
let request;

/**
 * Ensures the ID is a valid UUID.
 */
function validateId(id) {
  if (!UUID_REGEX.test(id)) {
    throw new NotFoundError(`The ID '${ id }' is not a valid UUID.`);
  }
}

export const apiClient = {

  /**
   * Creates a new session.
   * @param The email of the user that the session should be crated for.
   * @param password The password of the user that the session should be crated for.
   */
  async createSession({ email, password }) {
    let response = await request(
      "POST",
      SESSION_PATH,
      { email, password },
      { authentication: AUTHENTICATION_NONE, renew: false }
    );

    localStorage.setItem("accessToken", response.accessToken);
    localStorage.setItem("renewalToken", response.renewalToken);

    return response;
  },

  /**
   * Refreshes the session.
   */
  async renewSession() {
    // If a session renewal is already in progress, defer to that.
    if (!_.isNil(this._renewSessionPromise)) {
      return this._renewSessionPromise;
    }

    // Store the renewal promise.
    this._renewSessionPromise = request("PUT", SESSION_PATH, null, { renew: false });

    try {

      // Wait for the promise to resolve.
      let response = await this._renewSessionPromise;

      // Store the tokens.
      localStorage.setItem("accessToken", response.accessToken);
      localStorage.setItem("renewalToken", response.renewalToken);

      // Return the renewal
      return response;
    }
    finally {

      // Clear the renew promise regardless of whether is succeeds or fails.
      this._renewSessionPromise = null;
    }
  },

  /**
   * Deletes the session.
   */
  async deleteSession() {
    await request("DELETE", SESSION_PATH, null, { renew: false });

    localStorage.removeItem("accessToken");
    localStorage.removeItem("renewalToken");

    return null;
  },

  /**
   * Returns the currently signed-in user.
   */
  async getUser() {
    return await request("GET", USER_PATH);
  },

  /**
   * Creates a user.
   */
  async createUser({ ...attributes }) {
    let response = await request(
      "POST",
      USER_PATH,
      attributes,
      { authentication: AUTHENTICATION_NONE, renew: false }
    );

    localStorage.setItem("accessToken", response.accessToken);
    localStorage.setItem("renewalToken", response.renewalToken);

    return response;
  },

  /**
   * Triggers the password reset.
   */
  async sendPasswordReset({ ...attributes }) {
    return await request(
      "POST",
      PASSWORD_RESET_PATH,
      attributes,
      { authentication: AUTHENTICATION_NONE, renew: false }
    );
  },

  /**
   * Resets the user's password.
   */
  async resetPassword({ ...attributes }) {
    return await request(
      "PUT",
      PASSWORD_RESET_PATH,
      attributes,
      { authentication: AUTHENTICATION_NONE, renew: false }
    );
  },

  /**
   * Returns the score summaries for the given time zone.
   */
  async getScoreSummaries({ startDate, endDate, timeZone }) {
    let queryParams = new URLSearchParams({
      startDate: format(startDate, "yyyy-MM-dd"),
      endDate: format(endDate, "yyyy-MM-dd"),
      timeZone
    });

    return await request("GET", `${ SCORE_SUMMARIES_PATH }?${ queryParams }`);
  },

  /**
   * Fetches a collection of lessons.
   */
  async getLessons({ filter, page, size }) {
    let queryParams = new URLSearchParams({ filter, page, size });
    return await request(
      "GET",
      `${ LESSONS_PATH }?${ queryParams }`,
      null,
      {
        authentication: filter === POPULAR_LESSONS_FILTER
          ? AUTHENTICATION_OPTIONAL
          : AUTHENTICATION_REQUIRED
      }
    );
  },

  /**
   * Fetches a specific lesson.
   */
  async getLesson({ id }) {
    validateId(id);
    return await request(
      "GET",
      `${ LESSONS_PATH }/${ id }`,
      null,
      { authentication: AUTHENTICATION_OPTIONAL }
    );
  },

  /**
   * Creates a lesson.
   */
  async createLesson({ ...attributes }) {
    return await request("POST", LESSONS_PATH, attributes);
  },

  /**
   * Updates a lesson.
   */
  async updateLesson({ id, ...attributes }) {
    validateId(id);
    return await request("PATCH", `${ LESSONS_PATH }/${ id }`, attributes);
  },

  /**
   * Deletes a lesson.
   */
  async deleteLesson({ id }) {
    validateId(id);
    return await request("DELETE", `${ LESSONS_PATH }/${ id }`, null);
  },

  /**
   * Creates an exercise.
   */
  async createExercise({ ...attributes }) {
    return await request("POST", EXERCISES_PATH, attributes);
  },

  /**
   * Updates an exercise.
   */
  async updateExercise({ id, ...attributes }) {
    validateId(id);
    return await request("PATCH", `${ EXERCISES_PATH }/${ id }`, attributes);
  },

  /**
   * Deletes an exercise.
   */
  async deleteExercise({ id }) {
    validateId(id);
    return await request("DELETE", `${ EXERCISES_PATH }/${ id }`, null);
  },

  /**
   * Upserts a vote.
   */
  async upsertVote({ lessonId, ...attributes }) {
    validateId(lessonId);
    return await request("POST", `${ LESSONS_PATH }/${ lessonId }/vote`, attributes);
  },

  /**
   * Deletes a vote.
   */
  async deleteVote({ lessonId }) {
    validateId(lessonId);
    return await request("DELETE", `${ LESSONS_PATH }/${ lessonId }/vote`);
  },

  /**
   * Creates a score.
   */
  async createScore({ exerciseId, ...attributes }) {
    validateId(exerciseId);
    return await request("POST", `${ EXERCISES_PATH }/${ exerciseId }/score`, attributes);
  }
};

// Returns an object containing the authorization header depending on the authentication option
// provided.
function authorizationHeader(method, path, authentication) {

  // Ensure the authentication option is provided.
  if (!AUTHENTICATION_OPTIONS.includes(authentication)) {
    throw new Error(
      `The authentication option msut be set to one of: ${ AUTHENTICATION_OPTIONS.join(",") }`
    );
  }

  // NOTE: This makes the assumption that the authentication mechanism in the app stores the access
  // token in local storage. While this violates encapsulation, this allows the apiClient to be
  // decoupled from the SessionStore.
  let authorization = method === "PUT" && path === SESSION_PATH
    ? localStorage.getItem("renewalToken")
    : localStorage.getItem("accessToken");

  // If authentication is required, and no token is present, then we know the request is
  // unauthorized.
  if (authentication === AUTHENTICATION_REQUIRED && _.isNil(authorization)) {
    throw new UnauthorizedError();
  }

  // If authorization is not required or if it's optional and there's no token present, then we can
  // safely exclude the header.
  if (authentication === AUTHENTICATION_NONE || _.isNil(authorization)) {
    return {};
  }

  // If we've reached this point, the header should be provided.
  return { Authorization: authorization };
}

/**
 * Makes a request to the API server.
 * @param method The request's method.
 * @param path The path of the request (excluding the rest of the server's URL).
 * @param data The JSON data to pass to the server.
 * @param options.renew If this flag is set to true and the server responds with a 401, this
 * function will attempt to renew the API token. If the renewal is successful, it will then redo the
 * original
 * @param options.authentication Determines how the `Authorization` header is populated. This must
 * be one of `"required"`, `"optional"` or `"none"`.
 */
request = async (method, path, data = null, options = {}) => {
  let { renew, authentication } = _.merge({}, {
    renew: true,
    authentication: AUTHENTICATION_REQUIRED
  }, options);

  // Build the headers.
  let headers = {
    ...DEFAULT_HEADERS,
    ...authorizationHeader(method, path, authentication)
  };

  // Make the request.
  let response = await fetch(`${ API_URL }${ path }`, {
    method,
    ..._.isNil(data) ? {} : { body: JSON.stringify(data) },
    headers
  });

  // Extract the response JSON.
  let responseBody = await response.text();
  let responseJson = _.isEmpty(responseBody) ? null : JSON.parse(responseBody);

  // If the response was successful, parse and return its body.
  if (response.status < 300) {
    return responseJson;
  }

  // If the response is unauthorized, clear the access token.
  if (response.status === 401) {
    localStorage.removeItem("accessToken");
  }

  // If the response was unauthorized, and the renewal flag is set to true, attempt to renew the
  // tokens.
  if (response.status === 401 && renew) {
    await apiClient.renewSession();
    return request(method, path, data, false);
  }

  // If the response was an error, throw the response's error.
  switch (response.status) {
    case 400:
      throw new BadRequestError(responseJson?.error);
    case 401:
      throw new UnauthorizedError(responseJson?.error);
    case 403:
      throw new ForbiddenError(responseJson?.error);
    case 404:
      throw new NotFoundError(responseJson?.error);
    case 422:
      throw new ValidationError(responseJson?.errors);
    default:
      throw new InternalServerError(responseJson?.error);
  }
};
