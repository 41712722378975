import _ from "lodash";

const MESSAGE = "Can't be empty";

/**
 * Validates that the given value is not empty.
 * @return Returns null if the value is valid or an error string if it isn't.
 */
export function validateNotEmpty(value) {
  return _.isEmpty(value) ? MESSAGE : null;
}

/**
 * Returns true if the array of errors contains an empty error.
 * @param errors The *array* of errors to check.
 * @return Returns true if array contains the error.
 */
export function hasEmptyError(errors) {
  return errors.includes(MESSAGE);
}
