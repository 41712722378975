import { observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";

import { DETAILS_STATE, POSITION_STATE } from "../../stores/models/exercise-store";
import {
  EDIT_EXERCISE_ROUTE,
  EDIT_LESSON_ROUTE,
  NEW_EXERCISE_ROUTE,
  NEW_LESSON_ROUTE,
  PRACTICE_LESSON_ROUTE
} from "../../stores/routes";
import { EditExerciseSidebar } from "../sidebar/edit-exercise-sidebar";
import { EditLessonSidebar } from "../sidebar/edit-lesson-sidebar";
import { EditStartingPositionSidebar } from "../sidebar/edit-starting-position-sidebar";
import { PracticeLessonSidebar } from "../sidebar/practice-lesson-sidebar";
import { RouterView } from "../shared/router-view";
import { useStore } from "../../stores/store-provider";

// NOTE: The newExercise and editExercise routes are set so we can use the view map to determine if
// the sidebar has content in other components. These properties will never actually be used.
export const SIDEBAR_CONTENT_VIEW_MAP = {
  [PRACTICE_LESSON_ROUTE]: PracticeLessonSidebar,
  [NEW_LESSON_ROUTE]: EditLessonSidebar,
  [EDIT_LESSON_ROUTE]: EditLessonSidebar,
  [NEW_EXERCISE_ROUTE]: EditExerciseSidebar,
  [EDIT_EXERCISE_ROUTE]: EditStartingPositionSidebar
};

export const SidebarContent = observer(({ className }) => {
  let { routeName, exercise } = useStore("routerParams");

  if (routeName === NEW_EXERCISE_ROUTE || routeName === EDIT_EXERCISE_ROUTE) {
    let editExerciseDetailsClassNames = classNames(
      className,
      { "sidebar__content--left": exercise?.editState !== DETAILS_STATE }
    );

    let editExercisePositionClassNames = classNames(
      className,
      { "sidebar__content--right": exercise?.editState !== POSITION_STATE }
    );

    return <>
      <EditExerciseSidebar className={ editExerciseDetailsClassNames } />
      <EditStartingPositionSidebar className={ editExercisePositionClassNames } />
    </>;
  }

  return <RouterView
    className="sidebar__content"
    viewMap={ SIDEBAR_CONTENT_VIEW_MAP }
  />;
});

SidebarContent.propTypes = {
  className: PropTypes.string
};

SidebarContent.displayName = "SidebarContent";
