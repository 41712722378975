import { action } from "mobx";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";

import { InputWithLabel } from "./input-with-label";
import { validatePresence } from "../../validators/validate-presence";

function isRequired(model, attribute) {

  let validators = _.get(model.constructor.VALIDATORS, attribute, []);
  validators = _.isArray(validators) ? validators : [ validators ];
  return validators.includes(validatePresence);
}

export const AttributeInputWithLabel = observer(({
  className,
  inputClassName,
  label,
  model,
  attribute,
  disabled,
  type
}) => {

  if (!model.constructor.ATTRIBUTES.includes(attribute)) {
    throw new Error(`The attribute '${ attribute }' must be included in the ATTRIBUTES array.`);
  }

  return <InputWithLabel
    className={ className }
    inputClassName={ inputClassName }
    label={ label }
    errors={ model.errors[attribute] }
    value={ _.isNil(model[attribute]) ? "" : model[attribute] }
    onChange={ action(({ target }) => model[attribute] = target.value) }
    alwaysDisplayError={ model.lastSaveFailed || model.submitFailed }
    showRequiredLabel={ isRequired(model, attribute) }
    disabled={ disabled }
    type={ type }
  />;
});

AttributeInputWithLabel.displayName = "AttributeInputWithLabel";

AttributeInputWithLabel.propTypes = {
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  label: PropTypes.string.isRequired,
  attribute: PropTypes.string.isRequired,
  model: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf([ "textarea", "email", "text", "password" ])
};
