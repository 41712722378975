import { observer } from "mobx-react";
import MediaQuery from "react-responsive";
import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";
import classNames from "classnames";

import { Donut } from "./donut";
import { LessonStore } from "../../stores/models/lesson-store";
import { Pluralize } from "../shared/pluralize";
import { useStore } from "../../stores/store-provider";

const TABLET_WIDTH = 640;

const Dasherize = observer(({ lesson, children }) => {
  if (!lesson.isStarted) {
    return "—";
  }

  return children;
});

Dasherize.displayName = "Dasherize";

Dasherize.propTypes = {
  lesson: PropTypes.instanceOf(LessonStore),
  children: PropTypes.node.isRequired
};

export const PracticeScore = observer(({ className }) => {
  let { lesson } = useStore("routerParams");

  if (_.isNil(lesson)) {
    return null;
  }

  return <div className={ classNames("practice-score", className) }>
    <Donut className="practice-score__donut" lesson={ lesson } />

    <div className="practice-score__stat practice-score__stat--complete">
      <p className="practice-score__value">
        <Dasherize lesson={ lesson }>
          { lesson.numberOfCompletedExercises } / { lesson.exercises.length }
        </Dasherize>
      </p>
      <p className="practice-score__label">
        { /* HACK: There's simply not enough room to display the full label on small screens. */ }
        <MediaQuery minWidth={ TABLET_WIDTH }>
          <Pluralize text="Completed Exercises" number={ lesson.numberOfCompletedExercises } />
        </MediaQuery>
        <MediaQuery maxWidth={ TABLET_WIDTH - 1 }>
          <abbr className="practice-score__abbreviation" title="Complete">
            <Pluralize text="Done" number={ lesson.numberOfCompletedExercises } />
          </abbr>
        </MediaQuery>
      </p>
    </div>

    <div className="practice-score__stat practice-score__stat">
      <p className="practice-score__value">
        <Dasherize lesson={ lesson }>
          { lesson.numberOfPerfects }
        </Dasherize>
      </p>
      <p className="practice-score__label">
        <Pluralize text="Perfect" number={ lesson.numberOfPerfects } />
      </p>
    </div>

    <div className="practice-score__stat">
      <p className="practice-score__value">
        <Dasherize lesson={ lesson }>
          { lesson.numberOfHints }
        </Dasherize>
      </p>
      <p className="practice-score__label">
        <Pluralize text="Hint" number={ lesson.numberOfHints } />
      </p>
    </div>

    <div className="practice-score__stat">
      <p className="practice-score__value">
        <Dasherize lesson={ lesson }>
          { lesson.numberOfAssists }
        </Dasherize>
      </p>
      <p className="practice-score__label">
        <Pluralize text="Assist" number={ lesson.numberOfAssists } />
      </p>
    </div>

    <div className="practice-score__stat">
      <p className="practice-score__value">
        <Dasherize lesson={ lesson }>
          { lesson.numberOfMistakes }
        </Dasherize>
      </p>
      <p className="practice-score__label">
        <Pluralize text="Mistake" number={ lesson.numberOfMistakes } />
      </p>
    </div>
  </div>;
});

PracticeScore.displayName = "PracticeScore";

PracticeScore.propTypes = {
  className: PropTypes.string
};
