import { observer } from "mobx-react";
import PropTypes from "prop-types";
import React, { useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import _ from "lodash";
import classNames from "classnames";

function Input({ ...props }) {
  return <input { ...props } />;
}

export const InputWithLabel = observer(({
  value,
  onChange,
  onFocus,
  onBlur,
  disabled,
  label,
  errors,
  alwaysDisplayError,
  className,
  inputClassName,
  showRequiredLabel,
  type,
  minRows
}) => {
  let [ valueHasChanged, setValueHasChanged ] = useState(false);
  let [ hasBlurredSinceError, setHasBlurredSinceError ] = useState(false);

  let hasError = !_.isEmpty(errors);
  let showError = (hasBlurredSinceError && valueHasChanged || alwaysDisplayError) && hasError;

  if (!hasError && hasBlurredSinceError) {
    setHasBlurredSinceError(false);
  }

  // TODO: There's probably a better way to do this.
  let InputComponent = type === "textarea" ? TextareaAutosize : Input;

  return <div
    className={
      classNames(
        className,
        "input-with-label",
        { "input-with-label--showing-error": showError }
      )
    }
  >
    <div className="input-with-label__labels">
      <label
        className={
          classNames(
            "input-with-label__label",
            { "label--disabled": disabled }
          )
        }
      >
        { label }
        {
          showRequiredLabel
            ? <abbr title="Required" className="input-with-label__required">*</abbr>
            : null
        }
      </label>
      <label className="input-with-label__error">{ showError ? errors[0] : null } </label>
    </div>
    <InputComponent
      className={
        classNames(
          inputClassName,
          "input-with-label__input",
          { "input--error": showError }
        )
      }
      type={ type }
      value={ _.isNil(value) ? "" : value }
      onFocus={
        (event) => {
          if (_.isFunction(onFocus)) {
            onFocus(event);
          }
        }
      }
      onBlur={
        (event) => {
          setHasBlurredSinceError(hasError);

          if (_.isFunction(onBlur)) {
            onBlur(event);
          }
        }
      }
      onChange={
        event => {
          if (!valueHasChanged) {
            setValueHasChanged(true);
          }

          onChange(event);
        }
      }
      disabled={ disabled }
      { ...(type === "textarea" ? { minRows } : null) }
      data-gramm={ false }
    />
  </div>;
});

InputWithLabel.displayName = "InputWithLabel";

InputWithLabel.defaultProps = {
  alwaysDisplayError: false,
  showRequiredLabel: false,
  type: "textarea"
};

InputWithLabel.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
  alwaysDisplayError: PropTypes.bool,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  showRequiredLabel: PropTypes.bool,
  type: PropTypes.oneOf([ "textarea", "email", "text", "password" ]),
  minRows: PropTypes.number
};

