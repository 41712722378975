import { observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";

import { Icon } from "../shared/icon";
import { LessonStore } from "../../stores/models/lesson-store";

function iconName(lesson) {

  if (lesson.isSourceUrlYouTube) {
    return "youtubeFilledCircle";
  }

  if (lesson.isSourceUrlTwitch) {
    return "twitchFilledCircle";
  }

  return "linkFilledCircle";
}

export const LessonIcon = observer(({ lesson, className }) => {
  return <Icon
    className={ classNames(className, "lesson-icon") }
    alt={ lesson.title }
    filled={ false }
    name={ iconName(lesson) }
  />;
});

LessonIcon.displayName = "LessonIcon";

LessonIcon.propTypes = {
  lesson: PropTypes.instanceOf(LessonStore).isRequired,
  className: PropTypes.string
};
