import { capitalize } from "voca";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";

import { BISHOP, KNIGHT, QUEEN, ROOK, PLAYERS } from "../../constants";
import { Button } from "../shared/button";
import { convertSquareToIndices } from "../../utilities/squares";

export const ChessboardPromotion = observer(({
  className,
  active,
  onPromotion,
  player,
  orientation,
  square
}) => {

  if (!active) {
    return null;
  }

  let squareIndices = convertSquareToIndices(square, orientation);

  return <div
    data-file-index={ squareIndices[0] }
    data-position={ squareIndices[1] === 0 ? "top" : "bottom" }
    className={ classNames(className, "chessboard-promotion") }
  >
    {
      [ QUEEN, KNIGHT, ROOK, BISHOP ].map(piece => {
        return <Button
          key={ piece }
          className="chessboard-promotion__button"
          iconClassName="chessboard-promotion__piece"
          icon={ `${ player }${ capitalize(piece) }` }
          title="Queen"
          onClick={ () => onPromotion(piece) }
          treatment="clear"
          filled={ false }
        />;
      })
    }
  </div>;
});

ChessboardPromotion.displayName = "ChessboardPromotion";

ChessboardPromotion.propTypes = {
  className: PropTypes.string,
  onPromotion: PropTypes.func,
  player: PropTypes.oneOf(PLAYERS).isRequired,
  orientation: PropTypes.oneOf(PLAYERS).isRequired,
  active: PropTypes.bool.isRequired,
  square: PropTypes.string
};
