import { observer } from "mobx-react";
import React, { useEffect } from "react";
import _ from "lodash";

import { Chessboard } from "../chessboard/chessboard";
import { ChessboardAudio } from "../chessboard/chessboard-audio";
import { useStore } from "../../stores/store-provider";

export const EditExercisePage = observer(() => {
  let { exercise } = useStore("routerParams");

  function handleMove(move) {
    if (exercise.chessboard.editingStartingPosition) {
      return exercise.chessboard.movePieceInStartingPosition(move.from, move.to);
    }

    // Play whatever move is after the *visible* move on the chessboard, not the currently selected
    // move. This is less confusing than // playing the move *before* the current move. WYSIWYG.
    return exercise.updateMove(
      exercise.chessboard.numberOfMoves,
      move
    );
  }

  function handleMoveOutOfBoard({ square }) {
    if (!exercise.chessboard.editingStartingPosition) {
      return;
    }

    exercise.chessboard.removePieceFromStartingPosition(square);
  }

  // When the page loads, move the exercise to the last move.
  useEffect(() => exercise?.goToLastMove(), [ exercise ]);

  if (_.isNil(exercise)) {
    return null;
  }

  return <div className="edit-exercise-page">
    <ChessboardAudio chessboard={ exercise.chessboard } />
    <Chessboard
      className="edit-exercise-page__chessboard"
      chessboard={ exercise.chessboard }
      orientation={ exercise.player }
      onMove={ handleMove }
      onMoveOutOfBoard={ handleMoveOutOfBoard }
      allowMovement
    />
  </div>;
});

EditExercisePage.displayName = "EditExercisePage";
