import { RouterLink } from "mobx-state-router";
import React from "react";

import { POPULAR_LESSONS_ROUTE } from "../../stores/routes";

export function EmptyLikedLessonsMessage() {
  return "You havenʼt liked any lessons yet. Hit that thumbs up button!";
}

export function EmptyPlayedLessonsMessage() {
  return <>
    You havenʼt played any lessons yet. Check out the
    { " " }
    <RouterLink className="welcome-page__empty-link" routeName={ POPULAR_LESSONS_ROUTE }>
      popular lessons
    </RouterLink>
    { " " }
    to find something to try.
  </>;
}

export function EmptyCreatedLessonsMessage() {
  return "You haven't created any lessons yet.";
}

export function EmptyPopularLessonsMessage() {
  return "No lessons have been created yet. Get on it!";
}
