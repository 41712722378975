import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";
import classNames from "classnames";

/**
 * These are called ToggleButtons because that's what Material Design calls them.
 * https://material.io/components/buttons#toggle-button
 */
export function ToggleButton({ className, value, options, labels, onChange }) {

  if (options.length !== labels.length) {
    throw new Error("The labels array must be the same length at the options array");
  }

  let selectedIndex = options.indexOf(value);

  if (selectedIndex === -1) {
    throw new Error(`The value '${ value }' is not in the provided options.`);
  }

  return <div
    className={ classNames(className, "toggle-button") }
    style={
      {
        "--selected-index": selectedIndex,
        "--number-of-options": options.length
      }
    }
  >
    <div className="toggle-button__labels">
      {
        _.zip(options, labels).map(([ option, label ]) => {
          return <label className="toggle-button__label" key={ option }>
            <input
              className="toggle-button__radio"
              type="radio"
              value={ option }
              checked={ value === option }
              onChange={ onChange }
            />
            { label }
          </label>;
        })
      }
    </div>
  </div>;
}

ToggleButton.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  labels: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  onChange: PropTypes.func.isRequired
};
