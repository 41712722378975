/**
 * Converts radians to degrees.
 */
export function radiansToDegrees(radians) {
  return radians * 180 / Math.PI;
}

/**
 * Returns the distance between two points.
 */
export function distance(from, to) {
  return Math.sqrt((from[0] - to[0]) ** 2 + (from[1] - to[1]) ** 2);
}

/**
 * Returns the modulo of the number, correctly handling negative numbers.
 */
export function mod(dividend, divisor) {
  return (dividend % divisor + divisor) % divisor;
}
