import { observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";
import classNames from "classnames";

import { Button } from "../shared/button";
import { EDIT_EXERCISE_ROUTE, EDIT_LESSON_ROUTE, NEW_EXERCISE_ROUTE } from "../../stores/routes";
import { ExerciseStore } from "../../stores/models/exercise-store";
import { Icon } from "../shared/icon";
import { LessonStore } from "../../stores/models/lesson-store";
import { PreviewLesson } from "../lesson/preview-lesson";
import { SimpleDraggable } from "../shared/simple-draggable";
import { SimpleDroppable } from "../shared/simple-droppable";
import { useStore } from "../../stores/store-provider";

const ExerciseRow = observer(({ exercise, index }) => {
  let dialog = useStore("dialog");
  let { lesson } = useStore("routerParams");

  if (_.isNil(lesson)) {
    return null;
  }

  async function deleteExercise() {
    if (!await dialog.confirmDelete(exercise)) {
      return;
    }

    await lesson.destroyExercise(exercise.id);
  }

  // BUG FIX: When exercises are closed, they won't have an ID before they're saved. In that case,
  // we can set the ID to "new" so the list won't produce an error.
  let id = exercise.id || "new";

  return <SimpleDraggable
    className="edit-lesson-page__exercise"
    index={ index }
    id={ id }
  >
    <Icon
      className="edit-lesson-page__exercise-drag-icon"
      name="drag"
      alt="Drag"
    />

    <p className="edit-lesson-page__exercise-title">
      { exercise.title }
    </p>

    { /*
    When an exercise is cloned, it will be inserted into the array before it has an ID. When that
    happens, the button should still be rendered, but shouldn't actually do anything. By the time
    the user is ready to click on the button, the created exercise should have returned from the
    server and the ID should be set.
    */ }
    <Button
      icon="pencil"
      title="Edit"
      routeName={ _.isNil(exercise.id) ? EDIT_LESSON_ROUTE : EDIT_EXERCISE_ROUTE }
      params={ { lessonId: exercise.lessonId, exerciseId: exercise.id } }
      treatment="clear"
    />

    <Button
      icon="sheep"
      title="Clone"
      onClick={ () => lesson.cloneExercise(exercise.id) }
      treatment="clear"
    />

    <Button
      icon="trash"
      title="Delete"
      onClick={ () => deleteExercise(exercise, index) }
      treatment="clear"
    />
  </SimpleDraggable>;
});

ExerciseRow.displayName = "Exercise";

ExerciseRow.propTypes = {
  exercise: PropTypes.instanceOf(ExerciseStore).isRequired,
  index: PropTypes.number.isRequired
};

const EditLessonExercises = observer(({ lesson }) => {

  if (lesson.exercises.length === 0) {
    return null;
  }

  async function handleDragEnd({ source, destination }) {
    if (_.isNil(source) || _.isNil(destination)) {
      return;
    }

    lesson.moveExercise(source.index, destination.index);
  }

  return <SimpleDroppable className="edit-lesson-page__drop-area" onDragEnd={ handleDragEnd }>
    {
      lesson.exercises.map((exercise, index) => {
        return <ExerciseRow key={ exercise.id } exercise={ exercise } index={ index } />;
      })
    }
  </SimpleDroppable>;
});

EditLessonExercises.displayName = "EditLessonExercises";

EditLessonExercises.propTypes = {
  lesson: PropTypes.instanceOf(LessonStore).isRequired
};

const EditLessonMessage = observer(({ lesson }) => {

  if (!lesson.isSaved) {
    return <p className="edit-lesson-page__message">
      There arenʼt any exercises here yet. In order to add some, youʼll first need to save
      your new lesson.
    </p>;
  }

  if (lesson.exercises.length === 0) {
    return <p className="edit-lesson-page__message">
      There arenʼt any exercises here yet. Click the button to add one.
    </p>;
  }

  return null;
});

EditLessonMessage.displayName = "EditLessonMessage";

EditLessonMessage.propTypes = {
  lesson: PropTypes.instanceOf(LessonStore).isRequired
};

const EditLessonActions = observer(({ lesson }) => {
  let router = useStore("router");

  if (!lesson.isSaved) {
    return null;
  }

  return <div className="edit-lesson-page__actions">
    <Button
      className="edit-lesson-page__new-exercise-button"
      icon="plus"
      text="New Exercise"
      onClick={ () => router.goTo(NEW_EXERCISE_ROUTE, { params: { lessonId: lesson.id } }) }
      treatment="primary"
    />
  </div>;
});

EditLessonActions.displayName = "EditLessonActions";

EditLessonActions.propTypes = {
  lesson: PropTypes.instanceOf(LessonStore).isRequired
};

export const EditLessonPage = observer(() => {
  let { lesson } = useStore("routerParams");

  if (_.isNil(lesson)) {
    return null;
  }

  return <div
    className={
      classNames(
        "edit-lesson-page",
        { "edit-lesson-page--no-exercises": lesson.exercises.length === 0 }
      )
    }
  >
    <PreviewLesson lesson={ lesson } className="edit-lesson-page__preview" />
    <EditLessonExercises lesson={ lesson } />
    <EditLessonMessage lesson={ lesson } />
    <EditLessonActions lesson={ lesson } />
  </div>;
});

EditLessonPage.displayName = "EditLessonPage";
