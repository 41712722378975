import { observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";
import classNames from "classnames";

import { AttributeInputWithLabel } from "../shared/attribute-input-with-label";
import { Button } from "../shared/button";
import { EDIT_LESSON_ROUTE } from "../../stores/routes";
import { ExerciseMoves } from "../exercise/exercise-moves";
import { useStore } from "../../stores/store-provider";
import { ExerciseStore } from "../../stores/models/exercise-store";
import { hasEmptyError } from "../../validators/validate-not-empty";

const EditExerciseSidebarErrors = observer(({ exercise }) => {

  // Only display the error if the save fails.
  if (!exercise.lastSaveFailed) {
    return null;
  }

  // Display an empty error is necessary.
  if (hasEmptyError(exercise.errors.moves)) {
    return <p className="edit-exercise-sidebar__error">
      The exercise must have at least one move.
    </p>;
  }

  return null;
});

EditExerciseSidebarErrors.propTypes = {
  exercise: PropTypes.instanceOf(ExerciseStore)
};

EditExerciseSidebarErrors.displayName = "EditExerciseSidebarErrors";

export const EditExerciseSidebar = observer(({ className }) => {
  let router = useStore("router");
  let dialog = useStore("dialog");
  let { exercise } = useStore("routerParams");

  if (_.isNil(exercise)) {
    return null;
  }

  async function handleDeleteClick() {
    if (!await dialog.confirmDelete(exercise)) {
      return;
    }

    exercise.destroy();
    router.goTo(EDIT_LESSON_ROUTE, { params: { lessonId: exercise.lessonId } });
  }

  return <div className={ classNames(className, "edit-exercise-sidebar") }>
    <AttributeInputWithLabel
      label="Title"
      model={ exercise }
      attribute="title"
    />
    <AttributeInputWithLabel
      label="Description"
      model={ exercise }
      attribute="description"
    />

    <div className="edit-exercise-sidebar__actions">

      <Button
        icon="board"
        text="Edit Starting Position"
        onClick={ () => exercise.editPosition() }
        treatment="primary"
      />

      <Button
        icon="trash"
        text="Delete Exercise"
        disabled={ !exercise.isSaved }
        onClick={ handleDeleteClick }
        treatment="danger"
      />
    </div>

    <hr />
    <h3 className="edit-exercise-sidebar__subheading">Moves</h3>

    <EditExerciseSidebarErrors exercise={ exercise } />

    <ExerciseMoves className="edit-exercise-sidebar__moves" exercise={ exercise } />
  </div>;
});

EditExerciseSidebar.displayName = "EditExerciseSidebar";

EditExerciseSidebar.propTypes = {
  className: PropTypes.string
};
