import { makeAutoObservable } from "mobx";
import _ from "lodash";
import { titleCase, kebabCase, replaceAll } from "voca";

import {
  BAD_REQUEST_ERROR,
  FORBIDDEN_ERROR,
  INTERNAL_ERROR,
  INTERNAL_SERVER_ERROR,
  NOT_FOUND_ERROR,
  SUPPORT_EMAIL,
  UNAUTHORIZED_ERROR
} from "../constants";
import {
  BadRequestError,
  ForbiddenError,
  InternalServerError,
  NotFoundError,
  UnauthorizedError
} from "../utilities/errors";

export class ErrorStore {
  error = null
  componentStack = null

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * Returns true if the store has an error.
   */
  get hasError() {
    return !_.isNil(this.error);
  }

  /**
   * Returns the type of the error.
   */
  get type() {
    if (!this.hasError || this.error instanceof NotFoundError) {
      return NOT_FOUND_ERROR;
    }

    if (this.error instanceof BadRequestError) {
      return BAD_REQUEST_ERROR;
    }

    if (this.error instanceof ForbiddenError) {
      return FORBIDDEN_ERROR;
    }

    if (this.error instanceof InternalServerError) {
      return INTERNAL_SERVER_ERROR;
    }

    if (this.error instanceof UnauthorizedError) {
      return UNAUTHORIZED_ERROR;
    }

    return INTERNAL_ERROR;
  }

  /**
   * Returns a human-readable title for the error.
   */
  get title() {
    // TODO: Replace this with the functional pipeline operator.
    return _.flow(
      kebabCase,
      title => replaceAll(title, "-", " "),
      titleCase
    )(this.type);
  }

  /**
   * Returns a URL that emails an error report.
   */
  get reportUrl() {

    let context = {
      "URL": window.location.href,
      "Error Type": this.type,
      "Error Call Stack": this.error?.stack,
      "Component Call Stack": this.componentStack
    };

    // TODO: Replace this with the functional pipeline operator.
    context = _.flow(
      object => _.omitBy(object, _.isNil),
      object => _.map(object, (value, key) => `${ key }\n${ value }`),
      array => array.join("\n\n")
    )(context);

    let email = encodeURIComponent(SUPPORT_EMAIL);
    let subject = encodeURIComponent("Report Issue");

    let body = encodeURIComponent(
      `${ "Please describe what happened when you ran into the error."
        + "\n\n\n"
        + "----------"
        + "\n\n" }${
        context }`
    );

    return `mailto:${ email }?subject=${ subject }&body=${ body }`;
  }

  /**
   * Triggers the error state.
   */
  triggerError(error, componentStack = null) {
    this.error = error;
    this.componentStack = componentStack;
  }

  /**
   * Clears the error state.;
   */
  clear() {
    this.error = null;
    this.componentStack = null;
  }
}
