import { observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";
import classNames from "classnames";

import { Button } from "../shared/button";
import { useStore } from "../../stores/store-provider";

export const PracticeLessonSidebarControls = observer(({ className }) => {
  let { lesson } = useStore("routerParams");

  if (_.isNil(lesson)) {
    return null;
  }

  return <div className={ classNames("practice-lesson-sidebar-controls", className) }>

    { /* NOTE: the value for the `disabled` property is not a typo. */ }
    <Button
      icon="arrowLeft"
      disabled={ !lesson.hasExercises }
      onClick={ () => lesson.previousExercise() }
      title="Previous Exercise"
      treatment="clear"
    />

    <p>{ lesson.currentExerciseIndex + 2 } / { lesson.exercises.length + 1 }</p>

    <Button
      icon="arrowRight"
      disabled={ !lesson.hasExercises }
      onClick={ () => lesson.nextExercise() }
      title="Next Exercise"
      treatment="clear"
    />
  </div>;
});

PracticeLessonSidebarControls.displayName = "PracticeLessonSidebarControls";

PracticeLessonSidebarControls.propTypes = {
  className: PropTypes.string
};
