import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";

import { Button } from "../shared/button";

export function Arrows({
  onLeftArrowClicked,
  onRightArrowClicked,
  leftArrowDisabled,
  rightArrowDisabled
}) {

  if (onLeftArrowClicked && !onRightArrowClicked || !onLeftArrowClicked && onRightArrowClicked) {
    throw new Error("Both onLeftArrowClicked and onRightArrowClicked must be defined.");
  }

  if (!onLeftArrowClicked && !onRightArrowClicked) {
    return null;
  }

  return <div className="controls__arrows">
    <Button
      icon="chevronLeft"
      title="Previous Move"
      disabled={ leftArrowDisabled }
      onClick={ onLeftArrowClicked }
      treatment="clear"
    />
    <Button
      icon="chevronRight"
      title="Next Move"
      disabled={ rightArrowDisabled }
      onClick={ onRightArrowClicked }
      treatment="clear"
    />
  </div>;
}

Arrows.propTypes = {
  onLeftArrowClicked: PropTypes.func,
  onRightArrowClicked: PropTypes.func,
  leftArrowDisabled: PropTypes.bool,
  rightArrowDisabled: PropTypes.bool
};

export function Controls({ className, children, spread, ...arrowProps }) {
  return <div className={ classNames(className, "controls", { "controls--spread": spread }) }>
    { children }

    <Arrows { ...arrowProps } />
  </div>;
}

Controls.defaultProps = {
  leftArrowDisabled: false,
  rightArrowDisabled: false,
  spread: false
};

Controls.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  onLeftArrowClicked: PropTypes.func,
  onRightArrowClicked: PropTypes.func,
  leftArrowDisabled: PropTypes.bool,
  rightArrowDisabled: PropTypes.bool,
  spread: PropTypes.bool
};
