import PropTypes from "prop-types";
import React, { useRef } from "react";
import _ from "lodash";
import classNames from "classnames";

import { useSize } from "../../hooks/use-size";

/**
 * This is a special component that maintains an aspect ratio for its content. It automatically
 * resizes to fill the space it's given, constrained by its parent's `width` and `height`.
 */
export function AspectRatioContainer({ className, children, aspectRatio }) {

  // The ref and state used for the container element's size.
  let containerRef = useRef(null);
  let containerSize = useSize(containerRef);

  // TODO: Maybe someday this will be possible using the aspect-ratio CSS property, but not today.
  let containerWidth = _.min([ containerSize?.width, containerSize?.height * aspectRatio ]);
  let containerHeight = _.isNil(containerWidth) ? null : containerWidth / aspectRatio
  let containerStyles = { width: containerWidth, height: containerHeight };

  return <div className={ classNames("aspect-ratio-container", className) } ref={ containerRef }>
    <div className="aspect-ratio-container__content" style={ containerStyles }>
      { children }
    </div>
  </div>;
}

AspectRatioContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  aspectRatio: PropTypes.number.isRequired
};
