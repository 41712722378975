import { observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";

import { DETAILS_STATE } from "../../stores/models/exercise-store";
import {
  EDIT_EXERCISE_ROUTE,
  EDIT_LESSON_ROUTE,
  NEW_EXERCISE_ROUTE,
  NEW_LESSON_ROUTE,
  PRACTICE_LESSON_ROUTE
} from "../../stores/routes";
import { EditExerciseChildControls } from "../sidebar/edit-exercise-child-controls";
import { EditExerciseSidebarControls } from "../sidebar-controls/edit-exercise-sidebar-controls";
import {
  EditLessonSidebarControls
} from "../sidebar-controls/edit-lesson-sidebar-controls";
import {
  PracticeLessonSidebarControls
} from "../sidebar-controls/practice-lesson-sidebar-controls";
import { RouterView } from "../shared/router-view";
import { useStore } from "../../stores/store-provider";

const ExerciseSidebarControls = observer(({ className }) => {
  let { exercise } = useStore("routerParams");

  if (exercise?.editState === DETAILS_STATE) {
    return <EditExerciseSidebarControls className={ className } />;
  }

  return <EditExerciseChildControls className={ className } />;
});

ExerciseSidebarControls.propTypes = {
  className: PropTypes.string
};

ExerciseSidebarControls.displayName = "ExerciseSidebarControls";

export const SidebarControls = observer(({ className }) => {

  return <RouterView
    className={ className }
    viewMap={
      {
        [PRACTICE_LESSON_ROUTE]: PracticeLessonSidebarControls,
        [NEW_LESSON_ROUTE]: EditLessonSidebarControls,
        [EDIT_LESSON_ROUTE]: EditLessonSidebarControls,
        [NEW_EXERCISE_ROUTE]: ExerciseSidebarControls,
        [EDIT_EXERCISE_ROUTE]: ExerciseSidebarControls
      }
    }
  />;
});

SidebarControls.propTypes = {
  className: PropTypes.string
};

SidebarControls.displayName = "SidebarControls";
