import _ from "lodash";

import {
  BISHOP,
  BLACK,
  KING,
  KINGSIDE,
  KNIGHT,
  PAWN,
  QUEEN,
  QUEENSIDE,
  ROOK,
  STARTING_POSITION,
  WHITE
} from "../constants";
import { Chess, pieceToChessJSPiece } from "./chess-js";
import { indicesToSquare, reverseYIndex } from "./squares";
import { startingPlayer, setFenStartingPlayer, fenCanCastle, setFenCanCastle } from "./fen";

// TODO: Move these constants and helpers into the chess-js.js file.
const CHESS_JS_TYPES = {
  p: PAWN,
  n: KNIGHT,
  b: BISHOP,
  r: ROOK,
  q: QUEEN,
  k: KING
};

const CHESS_JS_COLORS = {
  b: BLACK,
  w: WHITE
};

const CHESS_JS_TYPES_INVERTED = _.invert(CHESS_JS_TYPES);
const CHESS_JS_COLORS_INVERTED = _.invert(CHESS_JS_COLORS);

function convertChessJSColorToPlayer(chessJsColor) {
  return CHESS_JS_COLORS[chessJsColor];
}

function convertChessJSPiece(chessJsType) {
  return CHESS_JS_TYPES[chessJsType];
}

function convertPlayerToChessJSColor(player) {
  return CHESS_JS_COLORS_INVERTED[player];
}

function convertPieceToChessJSType(piece) {
  return CHESS_JS_TYPES_INVERTED[piece];
}

function convertVerboseMove({ color, from, to, san, piece, flags, chess }) {

  return {
    player: convertChessJSColorToPlayer(color),
    from,
    to,
    algebraic: san,
    piece: convertChessJSPiece(piece),
    capture: _.includes(flags, "c") || _.includes(flags, "e"),
    fen: chess.fen(),
    check: chess.in_check(),
    checkmate: chess.in_checkmate()
  };
}

/**
 * This is an adapter for chess.js. It provides a more modern interface, it uses the application's
 * moves, and it adds some additional conveniences to the class.
 */
export class Chessboard {

  /**
   * Contains a verbose history of all of the moves made in the chessboard.
   */
  history = []

  /**
   * Creates a new chessboard starting from the given starting position.
   */
  constructor({ startingPosition } = {}) {
    this.startingPosition = startingPosition || STARTING_POSITION;
  }

  /**
   * Returns the starting position of the chessboard.
   */
  get startingPosition() {
    return _.isNil(this._startingPosition) ? STARTING_POSITION : this._startingPosition;
  }

  /**
   * Sets the starting position of the chessboard.
   */
  set startingPosition(startingPosition) {
    this._startingPosition = startingPosition;
    this.reset();
  }

  /**
   * Returns the FEN of the current position, or the starting position if no moves have been made.
   */
  get fen() {
    return this.history.length === 0 ? this.startingPosition : _.last(this.history).fen;
  }

  /**
   * Returns the first player to play from the original FEN.
   */
  get startingPlayer() {
    return startingPlayer(this.startingPosition);
  }

  /**
   * Sets the starting player.
   */
  set startingPlayer(player) {
    this.startingPosition = setFenStartingPlayer(this.startingPosition, player);
  }

  /**
   * Returns true if the starting player is white.
   */
  get isStartingPlayerWhite() {
    return this.startingPlayer === WHITE;
  }

  /**
   * Returns true if the starting player is black.
   */
  get isStartingPlayerBlack() {
    return this.startingPlayer === BLACK;
  }

  /**
   * Returns true if white can castle kingside.
   */
  get whiteCanCastleKingside() {
    return fenCanCastle(this.startingPosition, WHITE, KINGSIDE);
  }

  /**
   * Sets whether white can castle kingside.
   */
  set whiteCanCastleKingside(canCastle) {
    this.startingPosition = setFenCanCastle(this.startingPosition, WHITE, KINGSIDE, canCastle);
  }

  /**
   * Returns true if white can castle queenside.
   */
  get whiteCanCastleQueenside() {
    return fenCanCastle(this.startingPosition, WHITE, QUEENSIDE);
  }

  /**
   * Sets whether white can castle queenside.
   */
  set whiteCanCastleQueenside(canCastle) {
    this.startingPosition = setFenCanCastle(this.startingPosition, WHITE, QUEENSIDE, canCastle);
  }

  /**
   * Returns true if white can castle kingside.
   */
  get blackCanCastleKingside() {
    return fenCanCastle(this.startingPosition, BLACK, KINGSIDE);
  }

  /**
   * Sets whether black can castle kingside.
   */
  set blackCanCastleKingside(canCastle) {
    this.startingPosition = setFenCanCastle(this.startingPosition, BLACK, KINGSIDE, canCastle);
  }

  /**
   * Returns true if black can castle queenside.
   */
  get blackCanCastleQueenside() {
    return fenCanCastle(this.startingPosition, BLACK, QUEENSIDE);
  }

  /**
   * Sets whether black can castle queenside.
   */
  set blackCanCastleQueenside(canCastle) {
    this.startingPosition = setFenCanCastle(this.startingPosition, BLACK, QUEENSIDE, canCastle);
  }

  /**
   * Returns true if the last move was a capture.
   */
  get isCapture() {
    return !!_.last(this.history)?.capture;
  }

  /**
   * Returns true if the current position is a check.
   */
  get isCheck() {
    return this.chess.in_check();
  }

  /**
   * Returns true if the current position is a checkmate.
   */
  get isCheckmate() {
    return this.chess.in_checkmate();
  }

  /**
   * Returns the player whose turn it is.
   */
  get currentPlayer() {
    return convertChessJSColorToPlayer(this.chess.turn());
  }

  /**
   * Returns an array of all of the pieces on the board.
  */
  get pieces() {
    return _.compact(_.flatMap(this.chess.board(), (row, rankIndex) => {
      return _.map(row, (piece, fileIndex) => {

        if (_.isNil(piece)) {
          return null;
        }

        return {
          piece: convertChessJSPiece(piece.type),
          color: convertChessJSColorToPlayer(piece.color),
          square: indicesToSquare(reverseYIndex([ fileIndex, rankIndex ]))
        };
      });
    }));
  }

  /**
   * Returns an array of the legal moves from the current position.
   */
  legalMoves(square) {
    if (_.isNil(square)) {
      return [];
    }

    return _.map(this.chess.moves({ verbose: true, square }), "to");
  }

  /**
   * Makes a move on the chessboard.
   * @param move The move to make. This can either be a string representing the standard algebraic
   * notation of the move, or an object with `from` and `to` representing the piece to move.
   */
  move(move) {

    // Check the validity of the move.
    if (!_.isString(move) && !_.isObject(move)) {
      throw new Error("The move must be a string or an object.");
    }

    // Check the validity of the move if it's an object and convert the promotion (if necessary).
    if (_.isObject(move)) {

      if (!_.has(move, "to") || !_.has(move, "from")) {
        throw new Error("The move must contain from and to properties if it's an object.");
      }

      if (_.has(move, "promotion")) {
        move = { ...move, promotion: pieceToChessJSPiece(move.promotion) };
      }
    }

    // Make the move. We have to attempt to make the move, because that's how we determine if it is
    // valid.
    let result;

    // BUG FIX: chess.js shouldn't throw an error here, but it does.
    // https://github.com/jhlywa/chess.js/issues/282
    try {
      result = this.chess.move(move, { sloppy: true });
    }
    catch {
      return false;
    }

    // If the move was not legal, Ignore it.
    if (_.isNil(result)) {
      return false;
    }

    // Update the history
    this.history.push(convertVerboseMove({ ...result, chess: this.chess }));

    // Indicate the move was successful
    return true;
  }

  /**
   * Undoes the last move.
   */
  undo() {
    if (this.history.length === 0) {
      throw new Error("Can't undo because no moves have been made.");
    }

    this.chess.undo();
    this.history.pop();
  }

  /**
   * Resets the chessboard to its starting position.
   */
  reset() {
    this.chess = new Chess(this.startingPosition);
    this.history = [];
  }

  /**
   * Adds a piece to the board's starting position.
   */
  addPieceToStartingPosition(square, player, piece) {
    let data = {
      type: convertPieceToChessJSType(piece),
      color: convertPlayerToChessJSColor(player)
    };

    this.chess.put(data, square);
    this.startingPosition = this.chess.fen();
  }

  /**
   * Removes a piece from the board's starting position.
   */
  removePieceFromStartingPosition(square) {
    this.chess.remove(square);
    this.startingPosition = this.chess.fen();
  }

  /**
   * Moves a piece in the starting position.
   */
  movePieceInStartingPosition(from, to) {
    let fromPiece = _.find(this.pieces, ({ square }) => square === from);

    if (_.isNil(fromPiece)) {
      throw new Error(`There is no piece on the square '${ from }'`);
    }

    this.removePieceFromStartingPosition(from);
    this.addPieceToStartingPosition(to, fromPiece.color, fromPiece.piece);
  }

  /**
   * Returns true if the chessboard has the given piece.
   */
  hasPiece(player, piece) {
    let result = _.find(this.pieces, ({ piece: includedPiece, color: includedPlayer }) => {
      return includedPiece === piece && includedPlayer === player;
    });

    return !_.isNil(result);
  }
}
