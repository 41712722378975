import PropTypes from "prop-types";
import React, { useState } from "react";
import classNames from "classnames";

export function Spoiler({ enabled, children }) {
  let [ hidden, setHidden ] = useState(true);

  function handleClick(event) {
    if (!enabled) {
      return;
    }

    // Prevent the event from bubbling down when the spoiler is enabled
    event.preventDefault();
    event.stopPropagation();

    // Toggle the hidden state
    setHidden(oldHidden => !oldHidden);
  }

  return <span
    className={
      classNames(
        "spoiler",
        {
          "spoiler--hidden": enabled && hidden,
          "spoiler--enabled": enabled
        }
      )
    }
    onClick={ handleClick }
  >
    { children }
  </span>;
}

Spoiler.propTypes = {
  enabled: PropTypes.bool.isRequired,
  children: PropTypes.node
};
