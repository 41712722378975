import _ from "lodash";

import { BOARD_SIZE, DARK_SQUARE, FILES, LIGHT_SQUARE, PLAYERS, RANKS, WHITE } from "../constants";

function validateIndices(indices) {
  if (!_.isArray(indices)) {
    throw new Error("The indices must be an array.");
  }

  let [ fileIndex, rankIndex ] = indices;

  if (!_.isNumber(fileIndex) || !_.isNumber(rankIndex)) {
    throw new Error("The board indices must be numbers.");
  }

  if (fileIndex < 0 || fileIndex >= BOARD_SIZE || rankIndex < 0 || rankIndex >= BOARD_SIZE) {
    throw new Error(`The board indices [ ${ fileIndex }, ${ rankIndex } ] are out of bounds.`);
  }
}

/**
 * Converts the rank and file coordinates of a chess square to board indices.
 * @param A string representing the square's coordinates.
 * @return Returns an array of coordinates between 0–7 inclusive.
 */
export function squareToIndices(square) {

  if (!_.isString(square)) {
    throw new Error("The square must be a string.");
  }

  let [ file, rank ] = square;
  let result = [ FILES.indexOf(file), RANKS.indexOf(rank) ];

  if (result.includes(-1)) {
    throw new Error(`The square ${ square } is not valid.`);
  }

  return result;
}

export function indicesToSquare(indices) {
  validateIndices(indices);
  let [ fileIndex, rankIndex ] = indices;
  return `${ FILES[fileIndex] }${ RANKS[rankIndex] }`;
}

/**
 * Reverses the coordinate system for a single board index.
 */
function reverseIndex(index) {
  return BOARD_SIZE - 1 - index;
}

/**
 * Since SVGs are drawn from the top-left and chessboards' coordinates are from the bottom left, we
 * have to reverse the y-coordinate.
 */
export function reverseYIndex(indices) {
  validateIndices(indices);
  let [ fileIndex, rankIndex ] = indices;
  return [ fileIndex, reverseIndex(rankIndex) ];
}

/**
 * If the orientation is from black's perspective, we have to flip the indices.
 */
export function orientIndices(indices, orientation) {
  validateIndices(indices);
  let [ fileIndex, rankIndex ] = indices;

  if (!PLAYERS.includes(orientation)) {
    throw new Error(`The orientation ${ orientation } is not valid.`);
  }

  return orientation === WHITE
    ? [ fileIndex, rankIndex ]
    : [ reverseIndex(fileIndex), reverseIndex(rankIndex) ];
}

/**
 * Returns true if the move was a knight move.
 * @from A string representing the from square.
 * @to A string representing the to Square.
 */
export function isKnightMove(from, to) {
  from = squareToIndices(from);
  to = squareToIndices(to);

  const xDifference = Math.abs(from[0] - to[0]);
  const yDifference = Math.abs(from[1] - to[1]);

  return xDifference === 1 && yDifference === 2 || xDifference === 2 && yDifference === 1;
}

/**
 * Returns the color of the given square (light or dark).
 */
export function squareColor(square) {
  return _.sum(squareToIndices(square)) % 2 === 0 ? DARK_SQUARE : LIGHT_SQUARE;
}

// Given a DOM event, this method returns the indices that were clicked on a chessboard.
// @param event The event object from which the coordinates should be determined.
export function squareFromEvent(event, containerClass, orientation) {

  let element = event?.target?.closest(containerClass);

  if (_.isNil(element)) {
    return null;
  }

  let bounds = element.getBoundingClientRect();

  let x = event.clientX - bounds.left;
  let y = event.clientY - bounds.top;
  let width = bounds.width;
  let height = bounds.height;

  if (!_.isNumber(x) || !_.isNumber(y) || !_.isNumber(width) || !_.isNumber(height)) {
    throw new Error("The parameteters should be numbers.");
  }

  if (!PLAYERS.includes(orientation)) {
    throw new Error(`The provided orientation '${ orientation }' is not valid.`);
  }

  let indices = [
    Math.floor(x / width * BOARD_SIZE),
    Math.floor(y / height * BOARD_SIZE)
  ];

  // If the indices are out of bounds, return null.
  if (indices[0] < 0 || indices[0] >= BOARD_SIZE || indices[1] < 0 || indices[1] >= BOARD_SIZE) {
    return null;
  }

  // Convert and return the indices
  // TODO: Replace this with the functional pipeline operator.
  return _.flow(
    reverseYIndex,
    reversedIndices => orientIndices(reversedIndices, orientation),
    indicesToSquare
  )(indices);
}

// TODO: Replace this with the functional pipeline operator.
// TODO: Add tests for this function.
export const convertSquareToIndices = (square, orientation) => {
  return _.flow(
    squareToIndices,
    reverseYIndex,
    indices => orientIndices(indices, orientation)
  )(square);
};
