import { observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";

import { Chessboard } from "../chessboard/chessboard";
import { ExerciseStore } from "../../stores/models/exercise-store";
import { PracticeText } from "./practice-text";
import { StaticChessboard } from "../chessboard/static-chessboard";

export const PracticeExercise = observer(({ className, exercise, visible }) => {

  function handleMove(move) {
    return exercise.move(move);
  }

  // Trigger the next auto move unless the exercise hasn't been started.
  async function handlePositionUpdated() {
    if (exercise.completedMoveIndex !== -1) {
      await exercise.autoMoveAfterDelay();
    }
  }

  // TODO: For some reason the default props aren't working. It shouldn't be necessary to explicitly
  // pass these values.
  return <div className={ classNames("practice-exercise", className) }>
    <div className="practice-exercise__description-container">
      <PracticeText className="practice-exercise__description" exercise={ exercise } />
    </div>

    {
      visible
        ? <Chessboard
          className="practice-exercise__chessboard-container"
          chessboard={ exercise.chessboard }
          orientation={ exercise.player }
          allowMovement={ exercise.moveIndex === exercise.completedMoveIndex }
          onMove={ handleMove }
          onPositionUpdated={ handlePositionUpdated }
        />
        : <StaticChessboard
          chessboard={ exercise.chessboard }
          orientation={ exercise.player }
          className="practice-exercise__chessboard-container"
        />
    }

  </div>;
});

PracticeExercise.displayName = "PracticeExercise";

PracticeExercise.propTypes = {
  exercise: PropTypes.instanceOf(ExerciseStore),
  className: PropTypes.string,
  visible: PropTypes.bool.isRequired
};
