import _ from "lodash";
import { capitalize } from "voca";

/**
 * In order to validate that an email is not taken, the model needs a `taken<Attribute>s` array
 * property. When the server responds with an error, the taken value should be pushed into this
 * array.
 * @return Returns null if the value is unique or an error string if it isn't.
 */
export function uniqueConstraint(value, attribute, model) {
  let takenProperty = `taken${ capitalize(attribute) }s`;

  if (!_.has(model, takenProperty)) {
    throw new Error(`The model must contain a ${ takenProperty } array property!`);
  }

  return model[takenProperty].includes(value) ? "Already in use" : null;
}
