import _ from "lodash";

const EMAIL_REGEX = /^[^@]+@[^@]+\.[^@.]+$/;

/**
 * Validates that the given value is an email.
 * @return Returns null if the value is valid or an error string if it isn't.
 */
export function validateEmail(value) {
  if (_.isNil(value)) {
    return null;
  }

  return !_.isString(value) || !EMAIL_REGEX.test(value) ? "Not an email" : null;
}
