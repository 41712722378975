import "../stylesheets/index.sass";

import React from "react";
import ReactDOM from "react-dom";

import { Application } from "./components/layout/application";
import { StoreProvider } from "./stores/store-provider";
import { session } from "./stores/stores";

ReactDOM.render(
  <React.StrictMode>
    <StoreProvider>
      <Application />
    </StoreProvider>
  </React.StrictMode>,
  document.getElementById("application")
);

// TODO: Remove this.
window.session = session;
