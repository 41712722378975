import _ from "lodash";

import { ModelStore } from "./model-store";
import { apiClient } from "../../api/api-client";
import { isSameDay } from "date-fns";

export class ScoreSummaryStore extends ModelStore {
  static RESOURCE_NAME = "scoreSummary";

  static ATTRIBUTES = [
    "date",
    "numberOfAssists",
    "numberOfHints",
    "numberOfMistakes",
    "numberOfPerfects"
  ]

  static VALIDATORS = {}

  // Attributes
  _date = null
  numberOfAssists = null
  numberOfHints = null
  numberOfMistakes = null
  numberOfPerfects = null

  constructor(attributes = {}) {
    super();
    this.initialize(attributes);
  }

  /**
   * Retrieves the date.
   */
  get date() {
    if (!_.isString(this._date)) {
      return this._date;
    }

    // Ensure the date is parsed in the *local* time zone.
    let [ , year, month, day ] = this._date.match(/^(\d{4})-(\d{2})-(\d{2})/);
    return new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10));
  }

  /**
   * Sets the date.
   */
  set date(date) {
    this._date = date;
  }

  /**
   * Returns the published at time formatted to the current locale.
   */
  get label() {
    if (_.isNil(this.date)) {
      return null;
    }

    if (isSameDay(this.date, new Date())) {
      return "Today";
    }

    let formatter = new Intl.DateTimeFormat(
      undefined,
      {
        year: "numeric",
        month: "long",
        day: "numeric"
      }
    );

    return formatter.format(this.date);
  }

  /**
   * Returns true if all of the values are 0.
   */
  get isEmpty() {
    return this.numberOfAssists
      + this.numberOfHints
      + this.numberOfMistakes
      + this.numberOfPerfects
      === 0;
  }

  /**
   * Fetches all of the score summaries for the given date range. The times for the dates are
   * discarded, and the dates are inclusive.
   */
  static async fetchAll({ startDate, endDate }) {
    let scoreSummaries = await apiClient.getScoreSummaries({
      startDate,
      endDate,
      // eslint-disable-next-line new-cap
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    });

    return scoreSummaries.map(attributes => new ScoreSummaryStore(attributes));
  }
}
