import React from "react";

import { Button } from "../shared/button";
import { IconModal } from "../shared/icon-modal";
import { browserHistory as history } from "mobx-state-router";

export function MobileLessonCreationPage() {
  return <IconModal
    icon="computerFilledCircle"
    title="Larger Screen Needed"
    text={
      "Right now, this page is only available on larger screens. Try using a tablet or "
      + "desktop to edit your lesson. Sorry for the trouble!"
    }
    closable={ false }
    open
  >
    <Button
      icon="arrowLeft"
      text="Back"
      onClick={ () => history.goBack() }
      treatment="primary"
    />
  </IconModal>;
}
