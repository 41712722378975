import { observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";

import { useStore } from "../../stores/store-provider";

const INITIAL_ROUTE = "__initial__";

/**
 * This component is just like MobX State Router's RouterView component, except the view map's
 * values are functions, not nodes. This allows these components to only be rendered when they're
 * actually used.
 * @param viewMap an object whose keys are the route name and values are components to render.
 * @param className A class name that can be set on the child components.
 * @param strict If the current route is not found in the view map, this will throw an error.
 */
export const RouterView = observer(({ viewMap, className, strict }) => {
  let router = useStore("router");
  let routeName = router.routerState.routeName;

  // Ignore the initial route
  if (routeName === INITIAL_ROUTE) {
    return null;
  }

  // If strict is turned on and the route is not found in the view map, throw an error. Otherwise,
  // don't render anything
  if (!_.has(viewMap, routeName)) {
    if (strict) {
      throw new Error(`The view map doesn't contain the route '${ routeName }'.`);
    }

    return null;
  }

  // Render the component in the view map
  let Component = viewMap[routeName];
  return <Component key={ routeName } className={ className } />;
});

RouterView.propTypes = {
  viewMap: PropTypes.objectOf(PropTypes.elementType.isRequired).isRequired,
  className: PropTypes.string,
  strict: PropTypes.bool
};

RouterView.defaultProps = {
  strict: false
};

RouterView.displayName = "RouterView";
