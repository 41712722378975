import { observer } from "mobx-react";
import React from "react";

import { ErrorModal } from "../shared/error-modal";
import { RainingChessPieces } from "../authentication/raining-chess-pieces";

export const ErrorPage = observer(() => {
  return <div className="error-page">
    <RainingChessPieces className="authentication-page__background" />
    <ErrorModal />
  </div>;
});

ErrorPage.displayName = "ErrorPage";
