import { Howl } from "howler";
import { useEffect, useRef } from "react";

import useInteraction from "./use-interaction";

/**
 * This is a simple hook that plays a sound. It uses the `useInteration` hook to detect when the
 * user interacts with the page, and then it displays a sound.
 *
 * This hook was taken from a comment in an issue in the use-sound GitHub repo:
 *
 * https://github.com/joshwcomeau/use-sound/issues/22#issuecomment-737727148
 */
export function useAudio(soundPath, options = {}) {
  const hasInteracted = useInteraction();
  const audioRef = useRef();

  let { volume } = options;

  useEffect(() => {
    if (!hasInteracted) {
      return;
    }

    let audio = new Howl({ src: soundPath, volume });
    audioRef.current = audio;

    return () => audio.unload();
  }, [ hasInteracted, soundPath, volume ]);

  return () => audioRef.current?.play();
}
