import {
  ASSIST_STATE,
  HINT_STATE,
  PERFECT_STATE,
  UNSOLVED_STATE,
  MISTAKE_STATE
} from "../../constants";
import { ModelStore } from "./model-store";
import { validateInclusion } from "../../validators/validate-inclusion";
import { validatePresence } from "../../validators/validate-presence";
import { UnauthorizedError } from "../../utilities/errors";
import { localStorage } from "../../utilities/local-storage";
import { maybeParseDate } from "../../utilities/date";

const LOCAL_STORAGE_KEY = "scores";

export class ScoreStore extends ModelStore {
  static RESOURCE_NAME = "score";

  static ATTRIBUTES = [
    "id",
    "exerciseId",
    "state",
    "userId",
    "insertedAt"
  ]

  static VALIDATORS = {
    exerciseId: validatePresence,
    state: [
      validatePresence,
      validateInclusion([ PERFECT_STATE, HINT_STATE, ASSIST_STATE, MISTAKE_STATE ])
    ]
  }

  // Attributes
  id = null
  exerciseId = null
  state = UNSOLVED_STATE
  userId = null
  _insertedAt = null

  // Other properties
  _complete = false
  _started = false

  constructor(attributes = {}) {
    super();
    this.initialize(attributes);
  }

  /**
   * Returns the inserted at timestamp.
   */
  get insertedAt() {
    return this._insertedAt;
  }

  /**
   * Sets the inserted at timestamp.
   */
  set insertedAt(insertedAt) {
    this._insertedAt = maybeParseDate(insertedAt);
  }

  /**
   * Returns true if the score is marked as started or if it's saved.
   */
  get isStarted() {
    return this.isSaved || this._started;
  }

  /**
   * Returns true if the score is marked as completed or if it's saved.
   */
  get isCompleted() {
    return this.isSaved || this._complete;
  }

  /**
   * Returns true if the score's state is unsolved.
   */
  get isUnsolved() {
    return this.state === UNSOLVED_STATE;
  }

  /**
   * Returns true if the score's state is perfect.
   */
  get isPerfect() {
    return this.state === PERFECT_STATE;
  }

  /**
   * Returns true if the score's state is hint.
   */
  get isHint() {
    return this.state === HINT_STATE;
  }

  /**
   * Returns true if the score's state is assist.
   */
  get isAssist() {
    return this.state === ASSIST_STATE;
  }

  /**
   * Returns true if the score's state is mistake.
   */
  get isMistake() {
    return this.state === MISTAKE_STATE;
  }

  /**
   * Trigger a hint for the user.
   */
  hint() {
    if (!this.isAssist && !this.isMistake && !this.isPerfect) {
      this.state = HINT_STATE;
    }

    this.start();
  }

  /**
   * Trigger assistance for the user.
   */
  assist() {
    if (!this.isMistake && !this.isPerfect) {
      this.state = ASSIST_STATE;
    }

    this.start();
  }

  /**
   * Trigger a mistake.
   */
  mistake() {
    if (!this.isPerfect) {
      this.state = MISTAKE_STATE;
    }

    this.start();
  }

  /**
   * Starts the score.
   */
  start() {
    this._started = true;
  }

  /**
   * Completes the score.
   */
  complete() {
    if (this.isUnsolved) {
      this.state = PERFECT_STATE;
    }

    this._complete = true;
  }

  /**
   * @inheritDoc
   */
  async save() {
    try {
      return await super.save();
    }
    catch (error) {

      // If the error is not an UnauthorizedError, rethrow it.
      if (!(error instanceof UnauthorizedError)) {
        throw error;
      }

      // Store the score in localStorage.
      let scores = localStorage.getItem(LOCAL_STORAGE_KEY) || [];
      localStorage.setItem(LOCAL_STORAGE_KEY, [
        ...scores,
        { ...this.attributes, insertedAt: new Date() }
      ]);
    }
  }

  /**
   * Saves all of the scores stored in local storage.
   */
  static async saveStored() {
    let scores = localStorage.getItem(LOCAL_STORAGE_KEY) || [];

    await Promise.all(
      scores
        .map(score => new ScoreStore(score))
        .map(score => score.save())
    );

    localStorage.removeItem(LOCAL_STORAGE_KEY);
  }
}
