import { RouterLink } from "mobx-state-router";
import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";
import classNames from "classnames";
import requiredIf from "react-required-if";

import { Icon } from "./icon";

function ButtonContainer({ routeName, href, ...props }) {
  if (!_.isNil(routeName)) {
    return <RouterLink routeName={ routeName } { ...props } />;
  }

  if (!_.isNil(href)) {
    return <a href={ href } { ...props } />;
  }

  return <button type="button" { ...props } />;
}

ButtonContainer.propTypes = {
  routeName: PropTypes.string,
  href: PropTypes.string
};

/**
 * This component is meant as a replacement for React's built-in `<button>` component. It provides
 * several features that make working with buttons easier:
 *
 * * An optional icon that can be added to any button.
 * * The ability to set a button as a `<button>` or `<RouterLink>` element depending on the provided
 *   parameters.
 * * Several button types to use in different situations.
 * * The ability to set classes on child components for additional styling.
 *
 * @param className The class name of the Button container element.
 * @param disabled Whether or not the button should be disabled.
 * @param icon The name of the icon to use in the button. If this is omitted, the button will not
 * have an icon.
 * @param iconClassName The name of the class to apply to the button's icon element.
 * @param filled Determines if the icon should be filled by the current color.
 * @param onClick A function that's fired when the button or link is clicked. This parameter is
 * required if the element is a button, and is optional if it's a link.
 * @param params The params for the `routeName` to be passed to `<RouterLink>`.
 * @param queryParams The query params for the `routeName` to be passed to `<RouterLink>`.
 * @param routeName The name of the route to be passed to the `<RouterLink>`.
 * @param text The text to display inside of the button.
 * @param textClassName The name of the class to apply to the button's text element.
 * @param title The title attribute to display for the button. This is required if the button does
 * not have text.
 */
export function Button({
  className,
  icon,
  iconClassName,
  filled,
  text,
  textClassName,
  treatment,
  children,
  ...buttonContainerProps
}) {

  let buttonIcon = _.isNil(icon)
    ? null
    : <Icon
      className={
        classNames(
          "button__icon",
          iconClassName,
          { "button__icon--filled": filled }
        )
      }
      name={ icon }
      filled={ filled }
    />;

  let buttonText = _.isNil(text)
    ? null
    : <span className={ classNames("button__text", textClassName) }>
      { text }
    </span>;

  return <ButtonContainer
    className={
      classNames(
        "button",
        `button--${ treatment }`,
        {
          "button--has-text": !_.isNil(text),
          "button--has-icon": !_.isNil(icon)
        },
        className
      )
    }
    { ...buttonContainerProps }
  >
    {
      _.isNil(children)
        ? <>{ buttonIcon }{ buttonText }</>
        : children
    }
  </ButtonContainer>;
}

Button.defaultProps = {
  disabled: false,
  filled: true
};

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  filled: PropTypes.bool,
  icon: requiredIf(PropTypes.string, props => _.isNil(props.text) && _.isNil(props.children)),
  iconClassName: PropTypes.string,
  onClick: requiredIf(PropTypes.func, props => _.isNil(props.routeName) && _.isNil(props.href)),
  params: PropTypes.object,
  queryParams: PropTypes.object,
  routeName: requiredIf(PropTypes.string, props => _.isNil(props.onClick) && _.isNil(props.href)),
  href: requiredIf(PropTypes.string, props => _.isNil(props.onClick) && _.isNil(props.routeName)),
  text: requiredIf(PropTypes.node, props => _.isNil(props.icon) && _.isNil(props.children)),
  textClassName: PropTypes.string,
  title: requiredIf(PropTypes.string, props => _.isNil(props.text) && _.isNil(props.children)),
  treatment: PropTypes.oneOf([
    "primary",
    "positive",
    "secondary",
    "danger",
    "clear",
    "highlight",
    "selected"
  ]).isRequired
};
