import { observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";

import { Button } from "../shared/button";
import { Modal } from "../shared/modal";
import { RainingChessPieces } from "./raining-chess-pieces";

export const AuthenticationPage = observer(({
  title,
  children,
  onSubmit,
  buttonText,
  message,
  error
}) => {
  return <>
    <RainingChessPieces className="authentication-page__background" />

    <div className="authentication-page">
      <Modal
        contentClassName="authentication-page__content"
        title={ title }
        open
        closable={ false }
      >
        <form noValidate>
          { message ? <p className="authentication-page__message">{ message }</p> : null }
          { error ? <p className="authentication-page__error">{ error }</p> : null }
          { children }

          <div className="authentication-page__actions">
            <Button treatment="primary" text={ buttonText } onClick={ onSubmit } />
          </div>
        </form>
      </Modal>
    </div>
  </>;
});

AuthenticationPage.displayName = "AuthenticationPage";

AuthenticationPage.propTypes = {
  title: PropTypes.string.isRequired,
  error: PropTypes.node,
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
  message: PropTypes.node
};
