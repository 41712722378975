import { useEffect, useState } from "react";
import _ from "lodash";

// The events that are considered interactions.
const EVENTS = [ "mousedown", "touchstart" ];

// A singleton variable that determines whether the user has interacted with the page or not.
let isReady = false;

// An array of all of the subscribers for the interaction event.
let interactionHandlers = [];

/**
 * Subscribe a function to a global interaction event. The function is fired as soon as the user
 * has interacted with the page for the first time. It also returns an function that unbinds the
 * event, which can be used for cleanup.
 */
function onInteraction(interactionHandler) {

  // If the user has already interacted with the page, then we can call the subscription function
  // immediately.
  if (isReady) {
    interactionHandler();
    return;
  }

  interactionHandlers.push(interactionHandler);

  return () => {
    _.remove(interactionHandlers, (subscribedHandler) => subscribedHandler === interactionHandler);
  };
}

// The interaction event handler
function handleInteraction() {

  // Mark the interaction state globally
  isReady = true;

  // Trigger all of the interaction handlers and unbind them
  interactionHandlers.forEach(interactionHandler => interactionHandler());
  interactionHandlers = [];

  // Unbind the event listeners
  EVENTS.forEach((event) => {
    document.removeEventListener(event, handleInteraction);
  });
}

// Bind to the interaction events
EVENTS.forEach((event) => {
  document.addEventListener(event, handleInteraction);
});

/**
 * This hook waits for *any* user interaction. When the user interacts, it sets the ready state to
 * `true`. It automatically cleans up after itself when the component is unmounted.
 *
 * This hook was taken from a comment in an issue in the use-sound GitHub repo:
 *
 * https://github.com/joshwcomeau/use-sound/issues/22#issuecomment-737727148
 */
export default function useInteraction() {
  const [ ready, setReady ] = useState(isReady);

  useEffect(() => {
    onInteraction(() => {
      setReady(true);
    });
  }, []);

  return ready;
}
