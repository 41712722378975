import ChessJS from "chess.js";
import _ from "lodash";

import { BISHOP, KING, KNIGHT, PAWN, QUEEN, ROOK } from "../constants";

/**
 * BUG FIX: Chess.js changes the way it exports its object based on the environment. This odd
 * behavior causes Jest tests to fail when Webpack compiles the code successfully, and vise versa.
 * Rather than fight this behavior, this is a quick workaround.
 */
export const Chess = _.has(ChessJS, "Chess") ? ChessJS.Chess : ChessJS;

export function pieceToChessJSPiece(piece) {
  return {
    [KING]: "k",
    [QUEEN]: "q",
    [ROOK]: "r",
    [BISHOP]: "b",
    [KNIGHT]: "n",
    [PAWN]: "p"
  }[piece];
}

window.Chess = Chess;
