import { passwordStrength } from "../utilities/password";

const MINIMUM_PASSWORD_STRENGTH = 3;

/**
 * Validates that the given password is strong enough.
 * @return Returns null if the value is valid or an error string if it isn't.
 */
export function validatePasswordStrength(value) {
  return passwordStrength(value) < MINIMUM_PASSWORD_STRENGTH ? "Not strong enough" : null;
}
