import { observer } from "mobx-react";
import { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import { ChessboardStore } from "../../stores/chessboard-store";
import { useAudio } from "../../hooks/use-audio";
import { useReaction } from "../../hooks/use-reaction";
import captureSound from "../../../sounds/capture.wav";
import checkSound from "../../../sounds/check.wav";
import checkmateSound from "../../../sounds/checkmate.wav";
import firstMoveSound from "../../../sounds/first-move.wav";
import secondMoveSound from "../../../sounds/second-move.wav";

export const ChessboardAudio = observer(({ chessboard }) => {
  let previousStartingPositionRef = useRef(chessboard.startingPosition);

  let playCaptureSound = useAudio(captureSound, { volume: 0.3 });
  let playCheckSound = useAudio(checkSound, { volume: 0.3 });
  let playCheckmateSound = useAudio(checkmateSound, { volume: 0.3 });
  let playFirstMoveSound = useAudio(firstMoveSound, { volume: 0.5 });
  let playSecondMoveSound = useAudio(secondMoveSound, { volume: 0.5 });

  // Bind to the current *instance* of the chessboard and update the starting position ref if it
  // changes.
  useEffect(() => {
    previousStartingPositionRef.current = chessboard.startingPosition;
  }, [ chessboard ]);

  // Keep track of the previous value of the chessboard's sound.
  function playSound() {

    // Determine if the starting position has changed
    let previousStartingPosition = previousStartingPositionRef.current;
    let startingPositionChanged = chessboard.startingPosition !== previousStartingPosition;

    // Set the previous starting position to the current position
    previousStartingPositionRef.current = chessboard.startingPosition;

    // If the starting position has changed, always play the first move sound. This is less jarring
    // when updating the starting position in the FEN editor.
    if (!_.isNil(previousStartingPosition) && startingPositionChanged) {
      playFirstMoveSound();
    }
    else if (chessboard.isCheckmate) {
      playCheckmateSound();
    }
    else if (chessboard.isCheck) {
      playCheckSound();
    }
    else if (chessboard.isCapture) {
      playCaptureSound();
    }
    else if (chessboard.isFirstPlayerTurn) {
      playSecondMoveSound();
    }
    else {
      playFirstMoveSound();
    }
  }

  // If the board's FEN has changed, play a sound.
  // BUG FIX: We *must* use the chessboard's move objects. Otherwise, the sound effects won't be
  // triggered if a mistake is played more than once in a row.
  useReaction(() => chessboard.fen, playSound, [ chessboard ]);

  return null;
});

ChessboardAudio.displayName = "ChessboardAudio";

ChessboardAudio.propTypes = {
  chessboard: PropTypes.instanceOf(ChessboardStore).isRequired
};
