import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import requiredIf from "react-required-if";

import { Button } from "./button";
import { Icon } from "./icon";

export function MenuOpenButton({ className, onClick, direction, type }) {
  return <Button
    className={ classNames("menu-open-button", className) }
    onClick={ onClick }
    data-direction={ direction }
    treatment="clear"
  >
    <Icon
      className={ `menu-open-button__${ type }` }
      name={ type === "info" ? "infoCircle" : type }
    />
  </Button>;
}

MenuOpenButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  direction: requiredIf(PropTypes.oneOf([ "up", "down" ]), props => props.type === "carret"),
  type: PropTypes.oneOf([ "caret", "bars", "info" ]).isRequired
};
